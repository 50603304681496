import data from "../lib/data";
import { actions as GeneralActions } from './general-actions'
import { handlePromiseError } from './error-actions'

export const actions = {
  ...GeneralActions, ...{

    SET_TIME_STRING: "SET_TIME_STRING",
    SET_STATS_TYPE: "SET_STATS_TYPE",

    FETCHING_STATS: "FETCHING_STATS",
    FETCHED_STATS: "FETCHED_STATS",
    FETCH_STATS_FAILED: "FETCH_STATS_FAILED"
  }

}

const fetchStats = () => ((dispatch, getState) => {
  let { timeString, statsType } = getState().stats
  dispatch({ type: actions.FETCHING_STATS })
  data.get(`v1/api/stats/${statsType}/${timeString}`)
    .then((response) => {
      dispatch({ type: actions.FETCHED_STATS, timeString, statsType, stats: response.data })
  }).catch((rejection) => {
      dispatch({ type: actions.FETCH_STATS_FAILED, timeString, statsType, rejection })
      handlePromiseError(rejection)
    })
})

export const dispatchToProps = (dispatch) => ({
  setTimestring: (timeString) => { dispatch({ type: actions.SET_TIME_STRING, timeString }) },
  setStatsType: (statsType) => { dispatch({ type: actions.SET_STATS_TYPE, statsType }) },

  fetchStats: () => { dispatch(fetchStats()) }
})
