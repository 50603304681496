import React, { Component } from 'react'
import { ButtonGroup, Button, Badge } from 'react-bootstrap'

class FeatureGroupList extends Component {
  render() {
    return <div className="feature-group-list">
        {this.props.featureGroups.map(group => {
            return <div className="feature-group" key={group.featureGroupId}>
              <h3 className="feature-group-title">
                {group.featureGroupName}
                <ButtonGroup>
                  <Button variant="danger" onClick={() => this.props.confirmDeleteFeatureGroup(group)}>Delete</Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button onClick={() => this.props.editFeatureGroup(group)}>Edit</Button>
                  <Button onClick={() => this.props.editFeature(group, { featureType: "Flag" })}>Add Feature</Button>
                </ButtonGroup>
              </h3>
              {group.features.length ? 
                group.features.map(feature => {
                  return <div key={feature.featureId} className="feature">
                      <h4 className="feature-title">
                        {feature.featureName} <Badge variant="secondary">{feature.featureType}</Badge>
                      </h4>
                      <span className="feature-description">
                        {feature.featureDescription}
                        <ButtonGroup>
                          <Button variant="danger" onClick={() => this.props.confirmDeleteFeature(group, feature)}>Delete</Button>
                        </ButtonGroup>
                        <ButtonGroup>
                          <Button onClick={() => this.props.editFeature(group, feature)}>Edit</Button>
                        </ButtonGroup>
                      </span>
                    </div>;
                }) : <div className="feature-group-empty">Ain't no features here, Dave.</div> }
            </div>
        })}
        <div className="feature-group-list-toolbar"><Button onClick={() => this.props.editFeatureGroup({})}>Add Feature Group</Button></div>
    </div>
  }
}

export default FeatureGroupList
