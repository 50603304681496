import { actions } from './stats-actions'

const defaultStatsState = {
  timeString: "P1D",
  statsType: "resources/all",
  fetchingStats: false, fetchedStats: false, fetchStatsFailed: false, stats: {}
}

function statsReducer(state = defaultStatsState, action) {
  let stats = {}
  switch (action.type) {
    case actions.SET_TIME_STRING:
      return { ...state, timeString: action.timeString }
    case actions.SET_STATS_TYPE:
      return { ...state, statsType: action.statsType }
    case actions.FETCHING_STATS:
      stats = { ...state.stats }
      delete stats[state.statsType]
      return { ...state, fetchingStats: true, fetchedStats: false, fetchStatsFailed: false, stats }
    case actions.FETCH_STATS_FAILED:
      if (action.timeString !== state.timeString || action.statsType !== state.statsType) {
        return state
      }
      return { ...state, fetchingStats: false, fetchedStats: true, fetchStatsFailed: true }
    case actions.FETCHED_STATS:
      if (action.timeString !== state.timeString || action.statsType !== state.statsType) {
        return state
      }
      stats = { ...state.stats }
      stats[action.statsType] = action.stats
      return { ...state, fetchingStats: false, fetchedStats: true, fetchStatsFailed: false, stats }
    default:
      return state
  }
}

export default statsReducer
