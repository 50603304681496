import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import Resources from '../lib/resources'

import TabularData from './tabularData'
import { isEmpty } from '../lib/utils'

import { dispatchToProps } from '../store/invite-actions'


class InviteHistory extends Component {

  componentDidMount() {
    this.ensureInvites(true)
  }

  componentDidUpdate() {
    this.ensureInvites(false)
  }

  ensureInvites(force) {
    if (force === true || (this.props.invite.fetchingInvites === false && this.props.invite.fetchedInvites !== true && this.props.invite.fetchInvitesFailed !== true)) {
      this.props.fetchInvites()
    }
  }

  render() {
    var { invite } = this.props
    var companyInvites = invite.invites
      .filter(item => {
        // user invites don't have company name while all company invites require one
        return isEmpty(item.companyInvite) === false && isEmpty(item.companyInvite.companyName) === false
      })
      .map(item => {
        return { ...item.companyInvite, userInviteId: item.userInviteId }
      })
    
    companyInvites.forEach(company => {
      company.formatedDate = moment(company.createdDate).format('MM/DD/YYYY')
      company.age = moment(company.createdDate).fromNow(true)
      var existing = isEmpty(company.existingCompanyId) !== true
      company.created = existing
      company.status = existing === false ? Resources.Pending : Resources.Accepted

      let inviteId = company.userInviteId
      let status = { id: "", text: "" }

      if (existing === false) {
        if (invite.resendingInvite[inviteId]) {
          status.id = 'resending'
          status.text = Resources.Resending
        } else if (invite.resentInvite[inviteId]) {
          status.id = 'resent'
          status.text = Resources.Resent
        } else if (invite.resendInviteFailed[inviteId]) {
          status.id = 'failed'
          status.text = Resources.Retry
        } else {
          status.id = 'resend'
          status.text = Resources.Resend
        }
      }

      company.action = existing === false
        ? <span>
          <a href="/#/history" onClick={() => { if (status.id === 'resend') { this.props.resendInvite(inviteId) } }}>{status.text}</a>
        </span>
        : <span> </span>
    })
    companyInvites.sort((a, b) => { return moment(a.createdDate).isAfter(moment(b.createdDate)) ? -1 : 1 })
    return <div className="container-fluid">
      <br />
      <br />
      <TabularData
        data={companyInvites}
        format={{
          callBack(row) { },
          mapping: [
            {
              header: Resources.Date,
              width: '20%',
              source: 'formatedDate'
            },
            {
              header: Resources.Company,
              width: '20%',
              source: 'companyName'
            },
            {
              header: Resources.Status,
              width: '20%',
              source: 'status'
            },
            {
              header: Resources.Action,
              width: '20%',
              source: 'action'
            }
          ]
        }}
      />
    </div>
  }
}

const storeToProps = (store) => {
  return {
    user: store.user,
    invite: store.invite
  }
}

export default connect(storeToProps, dispatchToProps)(InviteHistory)
