import React, { Component } from 'react'
import { Table, Form, InputGroup, FormControl } from 'react-bootstrap'

class SubscriptionTypeFeatureList extends Component {
  render() {
    return <div className="subscription-type-feature-list">
      <Table responsive="sm" striped>
        <thead>
          <tr>
            <th className="subscription-type-header">Subscription Type</th>
            {this.props.subscriptionTypes.map(element => {
              return <th className="subscription-type-header" 
                key={element.subscriptionTypeId}>
                  {element.subscriptionTypeName}
                </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {this.props.featureGroups.map(group => {
            return <React.Fragment key={group.featureGroupId}>
              <tr>
                <td className="subscription-type-feature-group-name"
                  colSpan={this.props.subscriptionTypes.length + 1}>
                    {group.featureGroupName}
                </td>
              </tr>
              {group.features.map(feature => {
                return <tr key={feature.featureId}>
                  <td>{feature.featureName}</td>
                  {this.props.subscriptionTypes.map(subscriptionType => {
                    const isChecked = subscriptionType.subscriptionTypeFeatures.some((stf) => feature.featureId === stf.featureId);
                    const isFeatureDataDisabled = !isChecked;
                    const subscriptionTypeFeature = (subscriptionType.subscriptionTypeFeatures.filter((stf) => feature.featureId === stf.featureId)[0] || {});
                    const featureData = subscriptionTypeFeature.featureData;

                    return <td className="subscription-type-feature" key={subscriptionType.subscriptionTypeId}>
                      {feature.featureType === 'String' ? 
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Checkbox checked={isChecked} onChange={(e) => this.props.setFeatureOnSubscriptionType(subscriptionType, feature, e.target.checked)}/>
                          </InputGroup.Prepend>
                          <FormControl
                            disabled={isFeatureDataDisabled}
                            value={featureData}
                            onChange={(e) => this.props.updateFeatureOnSubscriptionType(subscriptionType, feature, {...subscriptionTypeFeature, featureData: e.target.value})}/>
                        </InputGroup> :
                        <Form.Check type='checkbox'
                          checked={isChecked}
                          onChange={(e) => this.props.setFeatureOnSubscriptionType(subscriptionType, feature, e.target.checked)}/>
                        }
                    </td>;
                  })}
                </tr>
              })}
            </React.Fragment>
          })}
        </tbody>
      </Table>
    </div>
  }
}

export default SubscriptionTypeFeatureList
