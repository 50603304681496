
import { actions as GeneralActions } from './general-actions'
import store from './store'

import { updateConfig, isEmpty, getRootPath } from '../lib/utils'

export const actions = {
  ...GeneralActions, ...{

    RAISE_ERROR: 'RAISE_ERROR',
    CLEAR_ERROR: 'CLEAR_ERROR',
    CLEAR_ERRORS: 'CLEAR_ERRORS',

    NOT_EMAIL_ERROR_TYPE: 'NOT_EMAIL_ERROR_TYPE',
    PASSWORD_MISMATCH_ERROR_TYPE: 'PASSWORD_MISMATCH_ERROR_TYPE',
    PASSWORD_TOO_SHORT_ERROR_TYPE: 'PASSWORD_TOO_SHORT_ERROR_TYPE',
    REQUIRED_FIELD_ERROR_TYPE: 'REQUIRED_FIELD_ERROR_TYPE',
    SUBMIT_ADMIN_ERROR_TYPE: 'SUBMIT_ADMIN_ERROR_TYPE',
    SUBMIT_ADMIN_VERIFICATION_ERROR_TYPE: 'SUBMIT_ADMIN_VERIFICATION_ERROR_TYPE',
    SUBMIT_COMPANY_ERROR_TYPE: 'SUBMIT_COMPANY_ERROR_TYPE',
    SUBMIT_COMPANY_VERIFICATION_ERROR_TYPE: 'SUBMIT_COMPANY_VERIFICATION_ERROR_TYPE'
  }
}

export function raiseError(message, type, severity, field) {
  store.dispatch({
    type: actions.RAISE_ERROR,
    error: {
      message: message,
      type: type || 'error',
      field: field || 'unknown',
      severity: severity || 'error'
    }
  })
}

export function handlePromiseError(rejection, message, type, severity) {
  if (window.logToConsole) console.log(rejection.message);
  var response = rejection.response;
  if (window.logToConsole) console.log(response)
  if (!isEmpty(response)) {
    message = { message, data: response.data, status: response.status }
  }
  raiseError(message, type, severity)

  if (rejection.response && rejection.response.status === 401) {
    window.sessionStorage.removeItem("id_token")
    window.sessionStorage.removeItem("logged_in")
    updateConfig({ AccessToken: undefined })
    store.dispatch({ type: actions.CLEAR_DATA })

    window.location = `${getRootPath()}#/login`
  }

  return rejection
}

export const dispatchToProps = (dispatch) => ({
  raiseError: (message, type, severity, field) => { return dispatch(raiseError(message, type, severity, field)) },
  clearError: (type, field) => { return dispatch({ type: actions.CLEAR_ERROR, errorType: type, field: field }) },
  clearErrors: () => { return dispatch({ type: actions.CLEAR_ERRORS }) }
})
