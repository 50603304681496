import data from "../lib/data";
import { actions as GeneralActions } from './general-actions'
import { handlePromiseError } from './error-actions'

export const actions = {
  ...GeneralActions, ...{

    FETCHING_FEATURE_GROUPS: "FETCHING_FEATURE_GROUPS",
    FETCHED_FEATURE_GROUPS: "FETCHED_FEATURE_GROUPS",
    FETCH_FEATURE_GROUPS_FAILED: "FETCH_FEATURE_GROUPS_FAILED",

    UPDATING_FEATURE_GROUP: "UPDATING_FEATURE_GROUP",
    UPDATED_FEATURE_GROUP: "UPDATED_FEATURE_GROUP",
    UPDATE_FEATURE_GROUP_FAILED: "UPDATE_FEATURE_GROUP_FAILED"
  }

};

const fetchFeatureGroups = () => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_FEATURE_GROUPS });
  data.get(`v2/api/feature/group`).then((response) => {
    dispatch({ type: actions.FETCHED_FEATURE_GROUPS, ...response.data });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_FEATURE_GROUPS_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const updateFeatureGroup = (activeFeatureGroup) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, activeFeatureGroup });
  data.post(`v2/api/feature/group/${activeFeatureGroup.featureGroupId}`, {
    ...activeFeatureGroup
  }).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data, activeFeatureGroup: {} });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const createFeatureGroup = (activeFeatureGroup) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, activeFeatureGroup });
  data.put(`v2/api/feature/group`, {
    ...activeFeatureGroup
  }).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data, activeFeatureGroup: {} });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const deleteFeatureGroup = (activeFeatureGroup) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, activeFeatureGroup });
  data.delete(`v2/api/feature/group/${activeFeatureGroup.featureGroupId}`).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data, activeFeatureGroup: {} });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const updateFeature = (activeFeatureGroup, activeFeature) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, activeFeatureGroup, activeFeature });
  data.post(`v2/api/feature/group/${activeFeatureGroup.featureGroupId}/${activeFeature.featureId}`, {
    ...activeFeature
  }).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data, activeFeatureGroup: {}, activeFeature: {} });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const createFeature = (activeFeatureGroup, activeFeature) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, activeFeatureGroup, activeFeature });
  data.put(`v2/api/feature/group/${activeFeatureGroup.featureGroupId}`, {
    ...activeFeature
  }).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data, activeFeatureGroup: {}, activeFeature: {} });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const deleteFeature = (activeFeatureGroup, activeFeature) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, activeFeatureGroup, activeFeature });
  data.delete(`v2/api/feature/group/${activeFeatureGroup.featureGroupId}/${activeFeature.featureId}`).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data, activeFeatureGroup: {} });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const setFeatureOnSubscriptionType = (subscriptionType, feature, postData) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, subscriptionType, feature });
  data.post(`v2/api/feature/subscriptiontype/${subscriptionType.subscriptionTypeId}/${feature.featureId}`, postData).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const deleteSubscriptionTypeFeature = (subscriptionType, feature) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, subscriptionType, feature });
  data.delete(`v2/api/feature/subscriptiontype/${subscriptionType.subscriptionTypeId}/${feature.featureId}`).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const setFeatureOnAccount = (account, feature, postData) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, account, feature });
  data.post(`v2/api/feature/account/${account.accountId}/${feature.featureId}`, postData).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const deleteAccountFeature = (account, feature) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.UPDATING_FEATURE_GROUP, account, feature });
  data.delete(`v2/api/feature/account/${account.accountId}/${feature.featureId}`).then((response) => {
    dispatch({ type: actions.UPDATED_FEATURE_GROUP, ...response.data });
  }).catch((rejection) => {
    dispatch({ type: actions.UPDATE_FEATURE_GROUP_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

export const dispatchToProps = (dispatch) => ({
    fetchFeatureGroups: () => { dispatch(fetchFeatureGroups()); },
    updateFeatureGroup: (activeFeatureGroup) => { dispatch(updateFeatureGroup(activeFeatureGroup)); },
    createFeatureGroup: (activeFeatureGroup) => { dispatch(createFeatureGroup(activeFeatureGroup)); },
    deleteFeatureGroup: (activeFeatureGroup) => { dispatch(deleteFeatureGroup(activeFeatureGroup)); },
    createFeature: (activeFeatureGroup, activeFeature) => { dispatch(createFeature(activeFeatureGroup, activeFeature)); },
    updateFeature: (activeFeatureGroup, activeFeature) => { dispatch(updateFeature(activeFeatureGroup, activeFeature)); },
    deleteFeature: (activeFeatureGroup, activeFeature) => { dispatch(deleteFeature(activeFeatureGroup, activeFeature)); },
    setFeatureOnSubscriptionType: (subscriptionType, feature, postData = {}) => { dispatch(setFeatureOnSubscriptionType(subscriptionType, feature, postData)); },
    deleteSubscriptionTypeFeature: (subscriptionType, feature) => { dispatch(deleteSubscriptionTypeFeature(subscriptionType, feature)); },
    setFeatureOnAccount: (account, feature, postData = {}) => { dispatch(setFeatureOnAccount(account, feature, postData)); },
    deleteAccountFeature: (account, feature) => { dispatch(deleteAccountFeature(account, feature)); }
});
