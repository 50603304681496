import React, { Component } from 'react'
import { connect } from 'react-redux'

import ModalDialog from '../components/modalDialog'

import Resources from '../lib/resources'
import { isEmpty, getConfig } from '../lib/utils'
import { getDisplayPerspective, Perspectives } from '../lib/perspectives'

import { dispatchToProps as iaDP } from '../store/invite-actions'
import { dispatchToProps as erDP } from '../store/error-actions'
import HtmlEditor from './htmlEditor';

const dispatchToProps = dispatch => ({
  ...iaDP(dispatch),
  ...erDP(dispatch)
})


class InviteCompany extends Component {
  defaultState = {
    stage: 1,
    accountInfo: {
      firstName: "",
      lastName: "",
      email: "",
      defaultPerspective: Perspectives.AccountsPayable
    },
    companyInfo: {
      companyName: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      website: "",
      apEmail: "", apPhone: "",
      arEmail: "", arPhone: "",
      entityType: "", TIN: ""
    },
    subject: Resources.DefaultInvitationSubject,
    htmlMessage: Resources.DefaultInvitationMessage,
    textMessage: "",
    isPreconfirmed: false
  }
  state = this.cleanState()

  cleanState() {
    return JSON.parse(JSON.stringify(this.defaultState))
  }

  resetState() {
    this.setState(this.cleanState())
  }

  componentWillMount() {
    this.resetState()
    this.props.clearInvite()
  }

  componentDidUpdate() {
    if (this.props.invite.sentInvite && this.state.stage !== 3) {
      this.setState({ stage: 3 })
    }
  }

  getPropertyItem(name, oName, pName, key, noborder, labelWidth, inputWidth) {
    var value = this.state[oName][pName]
    var lw = labelWidth || 3
    var iw = inputWidth || 9
    return <span key={key}>
      <span className={`inline-block-right col${lw === 0 ? "" : `-${lw}`}`} dangerouslySetInnerHTML={{ __html: name }} />
      <input className={`form-control d-inline-block pr-2 pl-2 plain thin col${iw === 0 ? "" : `-${iw}`}${isEmpty(noborder) === false ? ` ${noborder}` : ""}`} defaultValue={value} onChange={(e) => this.updatePropertyItem(e, oName, pName)} />
    </span>
  }

  updatePropertyItem(e, oName, pName) {
    var ns = { ...this.state }
    var u = { ...this.state[oName] }
    ns[oName] = u
    u[pName] = e.target.value
    this.setState(ns)
  }

  updatePerspective(defaultPerspective) {
    this.setState({ accountInfo: { ...this.state.accountInfo, defaultPerspective } })
  }

  render() {
    var { error } = this.props
    var dialog = null
    if (error.hasError) {
      var errorContent = <div>
        {error.errors.map((err, index) => {
          return <div key={`err${index}`}>
            {(err.message || {}).message}<br />
            {err.message !== undefined ? <div dangerouslySetInnerHTML={{ __html: JSON.stringify((err.message || {}).data, null, 2).split("\n").join("<br />") }} /> : ""}
          </div>
        })}
      </div>
      dialog = <ModalDialog
        isOpen={true}
        title={"ERROR!"}
        content={errorContent}
        footer={<button onClick={() => this.props.clearErrors()}>{Resources.Ok}</button>}
      />
    }

    var view = null
    switch (this.state.stage) {
      case 1:
        view = this.renderStage1()
        break;
      case 2:
        view = this.renderStage2()
        break;
      case 3:
        view = this.renderStage3()
        break;
      default:
        view = null
    }
    return <div>
      {dialog}
      {view}
    </div>
  }

  renderStage1() {
    return <div className="col">
      {/* <h2 className="mt-3 pl-3">{Resources.InviteCompany}</h2> */}
      <hr />
      <div>
        <div className="col-1"></div><div className="col-11">
          <h3>{Resources.AccountInformation}</h3>
        </div>
      </div>
      <hr />

      <div>
        <div className="col-1"></div><div className="col-11">
          <div className="row">
            <div className="col-6">
              {this.getPropertyItem(Resources.FirstName, "accountInfo", "firstName", "fn")}
              {this.getPropertyItem(Resources.LastName, "accountInfo", "lastName", "ln", "notop")}
              {this.getPropertyItem(Resources.Email, "accountInfo", "email", "em", "notop")}
              {
                <div>
                  <span className={`inline-block-right col-3`}>{Resources.DefaultPerspective}</span>
                  {/* <input className={`form-control d-inline-block pr-2 pl-2 plain thin col${iw === 0 ? "" : `-${iw}`}${isEmpty(noborder) === false ? ` ${noborder}` : ""}`} defaultValue={value} onChange={(e) => this.updatePropertyItem(e, oName, pName)} /> */}
                  <span className="dropdown form-control d-inline-block pr-2 pl-2 plain thin col-4" style={{ borderRadius: "0px", borderTopStyle: "none" }}>
                    <span className={this.props.hideToggle === true ? "" : "dropdown-toggle"} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{getDisplayPerspective(this.state.accountInfo.defaultPerspective)}</span>
                    <span className="dropdown-menu">
                      <div key={`ap`} className="dropdown-item" style={{ cursor: "default" }} onClick={(e) => { this.updatePerspective(Perspectives.AccountsPayable) }}>{getDisplayPerspective("ap")}</div>
                      <div key={`ar`} className="dropdown-item" style={{ cursor: "default" }} onClick={(e) => { this.updatePerspective(Perspectives.AccountsReceivable) }}>{getDisplayPerspective("ar")}</div>
                    </span>
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div className="col-1"></div><div className="col-11">
          <h3>{Resources.CompanyInformation}</h3>
        </div>
      </div>

      <hr />

      <div>
        <div className="col-1"></div><div className="col-11">
          <div className="row">
            <div className="col-6">
              {this.getPropertyItem(`${Resources.CompanyName}:`, "companyInfo", "companyName", "cname")}
              {this.getPropertyItem(Resources.CompanyInfoUrl, "companyInfo", "website", "url", "notop")}
            </div>
            <div className="col-6">
              {this.getPropertyItem(Resources.CompanyInfoAddress, "companyInfo", "address", "addr")}
              {this.getPropertyItem(Resources.CompanyInfoCity, "companyInfo", "city", "city", "notop")}
              <div>
                {this.getPropertyItem(Resources.CompanyInfoState, "companyInfo", "state", "state", "notop nobottom", 3, 2)}
                {this.getPropertyItem(Resources.CompanyInfoZip, "companyInfo", "zip", "zip", "notop nobottom", 3, 4)}
              </div>
              {this.getPropertyItem(Resources.CompanyInfoCountry, "companyInfo", "country", "country")}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
        </div>
        <div className="col-6">
        </div>
      </div>



      <hr />

      <div>
        <div className="col-1"></div><div className="col-11">
          <div className="row">
            <div className="col-6">
              {this.getPropertyItem(Resources.APEmail, "companyInfo", "apEmail", "smap")}
              {this.getPropertyItem(Resources.AREmail, "companyInfo", "arEmail", "smar", "notop")}
            </div>
            <div className="col-6">
              {this.getPropertyItem(Resources.APPhone, "companyInfo", "apPhone", "cnap")}
              {this.getPropertyItem(Resources.ARPhone, "companyInfo", "arPhone", "cnar", "notop")}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div className="col-1"></div><div className="col-11">
          <div className="row">
            <div className="col-6">
              {this.getPropertyItem(Resources.EntityType, "companyInfo", "entityType", "et")}
            </div>
            <div className="col-6">
              {this.getPropertyItem(Resources.CompanyInfoTIN, "companyInfo", "TIN", "tin")}
            </div>
          </div>

        </div>
      </div>
      <hr />

      <div>
        <div className="col-1"></div><div className="col-11">
          <div className="row">
            <div className="col-6">
              <div className="inline-block-right col-3">{Resources.CompanyInfoW9}</div><br />
              <div className="inline-block-right col-3">{Resources.CompanyInfoW8}</div>
            </div>
            <div className="col-6">
              <input type="checkbox" checked={this.state.isPreconfirmed} onClick={() => this.setState({ isPreconfirmed: !this.state.isPreconfirmed })}/>&nbsp;&nbsp;&nbsp;Automatically create company when user signs in.
              <span className="btn btn-default float-right" onClick={() => this.setState({ stage: 2 })}>{Resources.Next}</span>
            </div>
          </div>
        </div>
      </div>
      <hr />

    </div>

  }

  renderStage2() {
    var sending = this.props.invite.sendingInvite

    return <div className="col">
      <h2 className="mt-3 pl-3">{Resources.EmailInvitationHeader}</h2>
      <hr />
      <div>
        <div className="col-1"></div><div className="col-11">
          <label>{Resources.Subject}:</label>
          <input className={`form-control d-inline-block pr-2 pl-2 plain thin`}
            disabled={sending}
            defaultValue={this.state.subject} onChange={(e) => this.setState({ subject: e.target.value })} />
          <br /><br />
          <label>{Resources.Message}:</label>
          <HtmlEditor className="invite" htmlValue={this.state.htmlMessage} onChanged={(htmlMessage, textMessage) => { this.setState({ htmlMessage, textMessage }) }} />
          {/* <textarea className={`form-control d-inline-block pr-2 pl-2 plain thin`}
            disabled={sending}
            style={{ height: "350px" }} defaultValue={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} /> */}
        </div>
      </div>
      <hr />
      <div>
        <div className="col-1"></div><div className="col-11">
          <div className="row">
            <div className="col">
              {sending &&
                <span className="float-left">
                  Sending Invite... <span className={Resources.IconSpinner} />
                </span>
              }
              <span className="float-right">
                <span className="btn btn-default mr-3" disabled={sending} onClick={() => this.setState({ stage: 1 })}>{Resources.Previous}</span>
                <span className="btn btn-default" disabled={sending} onClick={() => {
                  var ac = this.state.accountInfo
                  var ci = this.state.companyInfo
                  var inviteInfo = {
                    fullName: `${ac.firstName} ${ac.lastName}`.trim(),
                    email: ac.email,
                    defaultPerspective: ac.defaultPerspective,
                    companyName: ci.companyName,
                    countryId: ci.country,
                    regionId: ci.state,
                    city: ci.city,
                    line1: ci.address,
                    line2: "",
                    line3: "",
                    postalCode: ci.zip,
                    website: ci.website,
                    apEmail: ci.apEmail,
                    arEmail: ci.arEmail,
                    apPhone: ci.apPhone,
                    arPhone: ci.arPhone,
                    entityType: ci.entityType,
                    tin: ci.TIN,
                    customSubject: this.state.subject,
                    customMessage: this.state.htmlMessage,
                    customText: this.state.textMessage,
                    isPreconfirmed: this.state.isPreconfirmed
                  }
                  this.props.sendInvite(inviteInfo)
                }}>{Resources.Next}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  }

  renderStage3() {
    return <div className="col">
      <h2 className="mt-3 pl-3">{Resources.InvitationSentConfirmation(this.state.companyInfo.companyName, getConfig().emailDomain)}</h2>
      <hr />
      <div>
        <div className="col-1"></div><div className="col-11">
          <div className="row">
            <div className="col">
              <span className="float-right">
                <span className="btn btn-default" onClick={() => {
                  window.location = `/#/history`
                }}>{Resources.Ok}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  }
}

const storeToProps = (store) => {
  return {
    user: store.user,
    invite: store.invite,
    error: store.error
  }
}

export default connect(storeToProps, dispatchToProps)(InviteCompany)
