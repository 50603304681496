import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import DashboardValue from "./dashboardValue";

class CustomerHealthHeading extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  renderConnectorIcon(visibilityContext, visibilityCriteria, title, className, statusContext) {
    let show = false;
    let valid = false;

    if (typeof(visibilityContext) === "object")
    {
      show = Object.keys(visibilityContext)
        .map(o => visibilityContext[o].connector.connectorName)
        .filter(o => o.toLowerCase() === visibilityCriteria).length === 1;

      valid = Object.keys(statusContext)
        .map(o => (statusContext[o] || []).length)
        .reduce((a, o) => a + o, 0) === 0
    } else {
      show = visibilityContext === visibilityCriteria;
      valid = statusContext;
    }

    if (show) {
      return <span
              title={title}
              className={
                className +
                (valid
                  ? " valid"
                  : " invalid")
              }
            />;
    } else {
      return "";
    }
  }

  render() {
    let { customer, toggle, toggleText } = this.props;

    return (
      <Row noGutters>
        <Col xs={12} sm={4}>
          <div className="customers-panel-customer-name">{customer.companyName}</div>
          <div className="customers-panel-heading">
            <a onClick={toggle}>{toggleText}</a>
          </div>
        </Col>
        <Col xs={6} sm={1}>
          <div className="customers-panel-heading">Incoming</div>
          <div className="customers-panel-value">
            {this.renderConnectorIcon(customer.connectors, "gmail", "Gmail", "fab fa-google", customer.gmailPerspectives)}
            {this.renderConnectorIcon(customer.connectors, "email", "IMAP", "fas fa-envelope", customer.imapPerspectives)}
            <span title="Forwarding" className="fas fa-arrow-right valid" />
          </div>
        </Col>
        <Col xs={6} sm={1}>
          <div className="customers-panel-heading">Outgoing</div>
          <div className="customers-panel-value">
            {this.renderConnectorIcon(customer.outboundEmail, "Lockstep", "Lockstep", "outgoing-value", customer.outboundEmailVerified)}
            {this.renderConnectorIcon(customer.outboundEmail, "DKIM/SPF", "DKIM/SPF", "fas fa-lock outgoing-value", customer.outboundEmailVerified)}
            {this.renderConnectorIcon(customer.outboundEmail, "SMTP", "SMTP", "fas fa-envelope outgoing-value", customer.outboundEmailVerified)}
          </div>
        </Col>
        <Col xs={12} sm={2}>
          <div className="customers-panel-heading">Connectors</div>
          <div className="customers-panel-value">
            {Object.keys(customer.connectors)
              .map(o => customer.connectors[o].connector.description || customer.connectors[o].connector.connectorName)
              .filter(o => o.toLowerCase() !== "gmail connector" && o.toLowerCase() !== "email")
              .join(", ")}
          </div>
        </Col>
        <Col xs={3} sm={1}>
          <div className="customers-panel-heading">This Week</div>
          <div className="customers-panel-value">
            <DashboardValue
              title="Inbound Email"
              iconClass="fas fa-download"
              value={customer.inboundLastWeek}
              comparisonValue={customer.inboundWeeklyAverage}
            />
          </div>
        </Col>
        <Col xs={3} sm={1}>
          <div className="customers-panel-heading">&nbsp;</div>
          <div className="customers-panel-value">
            <DashboardValue
              title="Outbound Email"
              iconClass="fas fa-upload"
              value={customer.outboundLastWeek}
              comparisonValue={customer.outboundWeeklyAverage}
            />
          </div>
        </Col>
        <Col xs={3} sm={1}>
          <div className="customers-panel-heading">Active Users</div>
          <div className="customers-panel-value">
            <DashboardValue
              title="Daily Active Users"
              iconClass="fas fa-user"
              value={customer.dailyActiveUsers}
              comparisonValue={customer.twoDayActiveUsers}
              reduction={(a, o) => Math.max(a, o)}
            />
          </div>
        </Col>
        <Col xs={3} sm={1}>
          <div className="customers-panel-heading">&nbsp;</div>
          <div className="customers-panel-value">
            <DashboardValue
              title="Monthly Active Users"
              iconClass="fas fa-user-clock"
              value={customer.monthlyActiveUsers}
              comparisonValue={customer.twoMonthActiveUsers}
              reduction={(a, o) => Math.max(a, o)}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default CustomerHealthHeading;
