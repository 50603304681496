import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, NavLink, Redirect, Switch } from 'react-router-dom'
import { Modal, Button, Form } from 'react-bootstrap'

import { dispatchToProps } from '../store/featureGroups-actions'
import Resources from '../lib/resources'
import FeatureGroupList from './featureGroupList'
import SubscriptionTypeFeatureList from './subscriptionTypeFeatureList'
import AccountList from './accountList'


class FeatureGroups extends Component {
  constructor(props, context) {
    super(props, context);

    this.editFeatureGroup = this.editFeatureGroup.bind(this);
    this.handleCloseFeatureGroup = this.handleCloseFeatureGroup.bind(this);
    this.saveFeatureGroup = this.saveFeatureGroup.bind(this);
    this.confirmDeleteFeatureGroup = this.confirmDeleteFeatureGroup.bind(this);
    this.handleCloseDeleteFeatureGroup = this.handleCloseDeleteFeatureGroup.bind(this);
    this.deleteFeatureGroup = this.deleteFeatureGroup.bind(this);
    this.editFeature = this.editFeature.bind(this);
    this.saveFeature = this.saveFeature.bind(this);
    this.handleCloseFeature = this.handleCloseFeature.bind(this);
    this.confirmDeleteFeature = this.confirmDeleteFeature.bind(this);
    this.handleCloseDeleteFeature = this.handleCloseDeleteFeature.bind(this);
    this.deleteFeature = this.deleteFeature.bind(this);
    this.setFeatureOnSubscriptionType = this.setFeatureOnSubscriptionType.bind(this);
    this.updateFeatureOnSubscriptionType = this.updateFeatureOnSubscriptionType.bind(this);
    this.setFeatureOnAccount = this.setFeatureOnAccount.bind(this);
    this.updateFeatureOnAccount = this.updateFeatureOnAccount.bind(this);

    this.state = {
      isEditingFeatureGroup: false,
      isDeletingFeatureGroup: false,
      isEditingFeature: false,
      isDeletingFeature: false,
      activeFeatureGroup: {},
      activeFeature: {}
    };
  }

  componentDidMount() {
    this.ensureFeatureGroups(true)
  }

  componentDidUpdate() {
    this.ensureFeatureGroups(false)
  }

  ensureFeatureGroups(force) {
    if (force === true || (this.props.featureGroups.fetchingInvites === false && this.props.featureGroups.fetchedInvites !== true && this.props.featureGroups.fetchInvitesFailed !== true)) {
      this.props.fetchFeatureGroups()
    }
  }

  editFeatureGroup(featureGroup) {
    this.setState({ isEditingFeatureGroup: true, activeFeatureGroup: featureGroup });
  }

  editFeature(featureGroup, feature) {
    this.setState({ isEditingFeature: true, activeFeatureGroup: featureGroup, activeFeature: feature });
  }

  handleCloseFeatureGroup() {
    this.setState({ isEditingFeatureGroup: false });
  }

  handleCloseDeleteFeatureGroup() {
    this.setState({ isDeletingFeatureGroup: false });
  }

  handleCloseFeature() {
    this.setState({ isEditingFeature: false });
  }

  handleCloseDeleteFeature() {
    this.setState({ isDeletingFeature: false });
  }

  saveFeatureGroup() {
    if (this.state.activeFeatureGroup.featureGroupId !== undefined)
    {
      // update
      this.props.updateFeatureGroup(this.state.activeFeatureGroup);
      this.setState({ isEditingFeatureGroup: false });
    }
    else
    {
      // create
      this.props.createFeatureGroup(this.state.activeFeatureGroup);
      this.setState({ isEditingFeatureGroup: false });
    }
  }

  saveFeature() {
    if (this.state.activeFeature.featureId !== undefined)
    {
      // update
      this.props.updateFeature(this.state.activeFeatureGroup, this.state.activeFeature);
      this.setState({ isEditingFeature: false });
    }
    else
    {
      // create
      this.props.createFeature(this.state.activeFeatureGroup, this.state.activeFeature);
      this.setState({ isEditingFeature: false });
    }
  }

  confirmDeleteFeatureGroup(featureGroup) {
    this.setState({ isDeletingFeatureGroup: true, activeFeatureGroup: featureGroup });
  }

  confirmDeleteFeature(featureGroup, feature) {
    this.setState({ isDeletingFeature: true, activeFeatureGroup: featureGroup, activeFeature: feature });
  }

  deleteFeatureGroup() {
    this.props.deleteFeature(this.state.activeFeatureGroup);
    this.setState({ isDeletingFeatureGroup: false });
  }

  deleteFeature() {
    this.props.deleteFeature(this.state.activeFeatureGroup, this.state.activeFeature);
    this.setState({ isDeletingFeature: false });
  }

  setFeatureOnSubscriptionType(subscriptionType, feature, toggle) {
    if (toggle) {
      this.props.setFeatureOnSubscriptionType(subscriptionType, feature);
    }
    else
    {
      this.props.deleteSubscriptionTypeFeature(subscriptionType, feature);
    }
  }

  updateFeatureOnSubscriptionType(subscriptionType, feature, postData) {
    this.props.setFeatureOnSubscriptionType(subscriptionType, feature, postData);
  }

  setFeatureOnAccount(account, feature, toggle) {
    if (toggle) {
      this.props.setFeatureOnAccount(account, feature);
    }
    else
    {
      this.props.deleteAccountFeature(account, feature);
    }
  }

  updateFeatureOnAccount(account, feature, postData) {
    this.props.setFeatureOnAccount(account, feature, postData);
  }

  renderEditFeatureGroupModal() {
    return <Modal show={this.state.isEditingFeatureGroup} onHide={this.handleCloseFeatureGroup}>
      <Modal.Header closeButton>
        <Modal.Title>{this.state.activeFeatureGroup.featureGroupId === undefined ? "New" : "Edit"} Feature Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Feature Group Name</Form.Label>
          <Form.Control
            value={this.state.activeFeatureGroup.featureGroupName}
            onChange={(e) => {this.setState({ activeFeatureGroup: { ...this.state.activeFeatureGroup, featureGroupName: e.target.value } })}}
            placeholder="Group Name"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            value={this.state.activeFeatureGroup.featureGroupDescription}
            onChange={(e) => {this.setState({ activeFeatureGroup: { ...this.state.activeFeatureGroup, featureGroupDescription: e.target.value } })}}
            placeholder="Description"/>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseFeatureGroup}>
          Close
        </Button>
        <Button variant="primary" onClick={this.saveFeatureGroup}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  renderDeleteFeatureGroupModal() {
    return <Modal show={this.state.isDeletingFeatureGroup} onHide={this.handleCloseDeleteFeatureGroup}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Feature Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete {this.state.activeFeatureGroup.featureGroupName}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseDeleteFeatureGroup}>
          Cancel
        </Button>
        <Button variant="danger" onClick={this.deleteFeatureGroup}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  renderEditFeatureModal() {
    return <Modal show={this.state.isEditingFeature} onHide={this.handleCloseFeature}>
      <Modal.Header closeButton>
        <Modal.Title>{this.state.activeFeatureGroup.featureGroupName} &gt; {this.state.activeFeature.featureId === undefined ? "New" : "Edit"} Feature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Feature Name</Form.Label>
          <Form.Control
            value={this.state.activeFeature.featureName}
            onChange={(e) => {this.setState({ activeFeature: { ...this.state.activeFeature, featureName: e.target.value } })}}
            placeholder="Group Name"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            value={this.state.activeFeature.featureDescription}
            onChange={(e) => {this.setState({ activeFeature: { ...this.state.activeFeature, featureDescription: e.target.value } })}}
            placeholder="Description"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            value={this.state.activeFeature.featureType}
            onChange={(e) => {this.setState({ activeFeature: { ...this.state.activeFeature, featureType: e.target.value } })}} >
            <option value="Flag">Flag</option>
            <option value="String">String</option>
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseFeature}>
          Cancel
        </Button>
        <Button variant="primary" onClick={this.saveFeature}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  renderDeleteFeatureModal() {
    return <Modal show={this.state.isDeletingFeature} onHide={this.handleCloseDeleteFeature}>
      <Modal.Header closeButton>
        <Modal.Title>{this.state.activeFeatureGroup.featureGroupName} &gt; Delete Feature</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete {this.state.activeFeature.featureName}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseDeleteFeature}>
          Cancel
        </Button>
        <Button variant="danger" onClick={this.deleteFeature}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  }

  render() {
    const GroupList = (props) => { return <FeatureGroupList {...props} {...this.props.featureGroups} editFeatureGroup={this.editFeatureGroup} confirmDeleteFeatureGroup={this.confirmDeleteFeatureGroup} editFeature={this.editFeature} confirmDeleteFeature={this.confirmDeleteFeature} /> };
    const SubscriptionTypeList = (props) => { return <SubscriptionTypeFeatureList {...props} {...this.props.featureGroups} setFeatureOnSubscriptionType={this.setFeatureOnSubscriptionType} updateFeatureOnSubscriptionType={this.updateFeatureOnSubscriptionType} /> };
    const AccountFeatureList = (props) => { return <AccountList {...props} {...this.props.featureGroups}  setFeatureOnAccount={this.setFeatureOnAccount} updateFeatureOnAccount={this.updateFeatureOnAccount} /> }
    return <div className="feature-groups">
      <div className="panel-title">
        <h3>{Resources.Features}</h3>
      </div>
      <div className='panel-tabs'>
        <NavLink to="/features/groups">{Resources.FeatureGroups}</NavLink>
        <NavLink to="/features/subscriptions">{Resources.SubscriptionTypes}</NavLink>
        <NavLink to="/features/accounts">{Resources.Accounts}</NavLink>
      </div>
      {this.renderEditFeatureGroupModal()}
      {this.renderDeleteFeatureGroupModal()}
      {this.renderEditFeatureModal()}
      {this.renderDeleteFeatureModal()}
      <Switch>
        <Redirect exact from="/features" to="/features/groups" render={GroupList} />
        <Route path="/features/groups" render={GroupList}/>
        <Route path="/features/subscriptions" render={SubscriptionTypeList}/>
        <Route path="/features/accounts" render={AccountFeatureList}/>
      </Switch>
    </div>
  }
}

const storeToProps = (store) => {
  return {
    user: store.user,
    featureGroups: store.featureGroups
  }
}

export default connect(storeToProps, dispatchToProps)(FeatureGroups)
