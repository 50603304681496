import React, { Component } from 'react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import '../css/quill.css'

export default class HtmlEditor extends Component {
  constructor(props) {
    super(props)

    this.state = { ...this.defaultState }

    this.onChanged = this.onChanged.bind(this)
  }

  defaultState = {
    value: "",
    quillConfig: {
      theme: "snow",
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, false] }],
          ['bold', 'italic', 'underline'],
          ['strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image']
        ],
      },
      readOnly: false
    }
  }

  componentDidMount() {
    let { htmlValue } = this.props
    this.setState({ htmlValue })
  }

  getText() {
    var editor = this.quillRef.getEditor()
    var uEditor = this.quillRef.makeUnprivilegedEditor(editor)
    return uEditor.getText()
  }

  onChanged(htmlValue) {
    this.setState( { htmlValue })
    let onChanged = this.props.onChanged
    if (onChanged) onChanged(htmlValue, this.getText())
  }

  render() {
    return <div className={this.props.className || ""}>
      <ReactQuill ref={(el) => { this.quillRef = el }} {...this.state.quillConfig} value={this.state.htmlValue} onChange={this.onChanged} />
    </div>
  }
}