import data from "../lib/data";
import { actions as GeneralActions } from './general-actions'
import { handlePromiseError } from './error-actions'

export const actions = {
  ...GeneralActions, ...{
    FETCHING_HEALTH: "FETCHING_HEALTH",
    FETCHED_HEALTH: "FETCHED_HEALTH",
    FETCH_HEALTH_FAILED: "FETCH_HEALTH_FAILED"
  }
};

const fetchHealth = () => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_HEALTH });
  data.get(`v1/api/connector/email/config/health`).then((response) => {
    var health = response.data;
    health.sort((a, b) => {
      var x = a.companyName.toLowerCase();
      var y = b.companyName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
    dispatch({ type: actions.FETCHED_HEALTH, health });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_HEALTH_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

export const dispatchToProps = (dispatch) => ({
    fetchHealth: () => { dispatch(fetchHealth()); }
});
