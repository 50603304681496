import { actions } from "./serviceEmailTemplates-actions";

const defaultServiceEmailTemplatesState = {
  fetchingServiceEmailTemplates: false,
  fetchedServiceEmailTemplates: false,
  fetchServiceEmailTemplatesFailed: false,
  serviceEmailTemplates: [],
};

function serviceEmailTemplatesReducer(state = defaultServiceEmailTemplatesState, action) {
  switch (action.type) {
    case actions.FETCHING_SERVICE_EMAIL_TEMPLATES:
      return {
        ...state,
        ...action,
        fetchingServiceEmailTemplates: true,
        fetchedServiceEmailTemplates: false,
        fetchServiceEmailTemplatesFailed: false,
      };
    case actions.FETCH_SERVICE_EMAIL_TEMPLATES_FAILED:
      return {
        ...state,
        ...action,
        fetchingServiceEmailTemplates: false,
        fetchedServiceEmailTemplates: true,
        fetchServiceEmailTemplatesFailed: true,
      };
    case actions.FETCHED_SERVICE_EMAIL_TEMPLATES:
      return {
        ...state,
        ...action,
        fetchingServiceEmailTemplates: false,
        fetchedServiceEmailTemplates: true,
        fetchServiceEmailTemplatesFailed: false,
      };
    default:
      return state;
  }
}

export default serviceEmailTemplatesReducer;
