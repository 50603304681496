import { actions } from "./accounts-actions";

const defaultAccountsState = {
  fetchingAccounts: false,
  fetchedAccounts: false,
  fetchAccountsFailed: false,
  accounts: [],

  fetchingUnprocessedEntities: false,
  fetchedUnprocessedEntities: false,
  failedFetchUnprocessedEntities: false,
  unprocessedList: {},
};

function accountsReducer(state = defaultAccountsState, action) {
  switch (action.type) {
    case actions.FETCHING_ACCOUNTS:
      return { ...state, ...action, fetchingAccounts: true, fetchedAccounts: false, fetchAccountsFailed: false };
    case actions.FETCH_ACCOUNTS_FAILED:
      return { ...state, ...action, fetchingAccounts: false, fetchedAccounts: true, fetchAccountsFailed: true };
    case actions.FETCHED_ACCOUNTS:
      return { ...state, ...action, fetchingAccounts: false, fetchedAccounts: true, fetchAccountsFailed: false };
    case actions.FETCHING_UNPROCESSED_ENTITIES:
      return {
        ...state,
        fetchingUnprocessedEntities: true,
        fetchedUnprocessedEntities: false,
        failedFetchUnprocessedEntities: false,
      };
    case actions.FETCHED_UNPROCESSED_ENTITIES:
      return {
        ...state,
        fetchingUnprocessedEntities: false,
        fetchedUnprocessedEntities: true,
        failedFetchUnprocessedEntities: false,
        unprocessedList: action.unprocessedList,
      };
    case actions.FAILED_FETCH_UNPROCESSED_ENTITIES:
      return {
        ...state,
        fetchingUnprocessedEntities: false,
        fetchedUnprocessedEntities: false,
        failedFetchUnprocessedEntities: true,
      };
    default:
      return state;
  }
}

export default accountsReducer;
