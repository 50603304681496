import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";

import Resources from "../lib/resources";
import Dropzone from "react-dropzone";

import { dispatchToProps as iDP } from "../store/internationalization-actions";

const dispatchToProps = dispatch => ({
  ...iDP(dispatch)
});

class Internationalization extends Component {
  constructor(props, context) {
    super(props, context);

    this.renderImportResourceFileModal = this.renderImportResourceFileModal.bind(this);
    this.handleCloseImportResourceFileModal = this.handleCloseImportResourceFileModal.bind(this);

    this.state = {
      selectedLanguage: "en",
      importingResourceFile: false
    };
  }

  componentDidMount() {
    this.ensureInternationalizations(true);
  }

  componentDidUpdate() {
    this.ensureInternationalizations(false);
  }

  ensureInternationalizations(force) {
    if (
      force === true ||
      (this.props.internationalizations.fetchingInternationalizations === false &&
        this.props.internationalizations.fetchedInternationalizations !== true &&
        this.props.internationalizations.fetchInterntionalizations !== true)
    ) {
      this.props.fetchInternationalizations();
    }
  }

  renderImportResourceFileModal() {
    return (
      <Modal show={this.state.importingResourceFile } onHide={this.handleCloseImportResourceFileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Import Resource File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropzone
            onDrop={acceptedFiles =>
              {
                this.props.uploadInternationalizationResourceFile(
                  acceptedFiles
                );
              }
            }
          >
            {({ getRootProps, getInputProps }) => {
              return (this.props.internationalizations.fetchingInternationalizations ?
                <section>
                  <div {...getRootProps()}>
                    <p className="centered">
                      <br />
                      <span className="big-icon fas fa-globe inactive" />
                      <br />
                      <br />
                      <span className="inactive">
                        Processing resource files...
                      </span>
                    </p>
                    <input {...getInputProps()} />
                  </div>
                </section> :
                <section>
                  <div {...getRootProps()}>
                    <p className="centered">
                      <br />
                      <span className="big-icon fas fa-globe inactive" />
                      <br />
                      <br />
                      <span className="inactive">
                        Drag files into here to upload resource files.
                      </span>
                    </p>
                    <input {...getInputProps()} />
                  </div>
                </section>
              );
            }}
          </Dropzone>
        </Modal.Body>
      </Modal>
    );
  }

  handleCloseImportResourceFileModal() {
    this.setState({ importingResourceFile: false });
  }
  
  render() {
    let internationalization = (this.props.internationalizations || { internationalizations: { en: {languageId: "en", value: [] }}}).internationalizations || { en: { languageId: "en", value: [] } };
    console.log(internationalization);
    return (
      <div>
        <div className="panel-title">
          <Row>
            <Col xs={8}>
              <h3>
                {Resources.Internationalization}
                <select onChange={(e) => this.setState({ selectedLanguage: e.target.value })}>
                  {Object.keys(internationalization).map(i =>
                    {
                      return (<option key={internationalization[i].languageId} value={internationalization[i].languageId}>{internationalization[i].languageId}</option>);
                    })}
                </select>
              </h3>
            </Col>
            <Col xs={3}>
              <div className="list-add" onClick={() => this.setState({ importingResourceFile: true })}>Import resource file</div>
            </Col>
          </Row>
        </div>
        {this.renderImportResourceFileModal()}
        <Row className="list-panel">
          <Col xs={12}>
            <Row>
              <Col xs={5} className="list-panel-title">Name</Col>
              <Col xs={5} className="list-panel-title">Value</Col>
              <Col xs={2} className="list-panel-title">Actions</Col>
            </Row>
            {(internationalization[this.state.selectedLanguage] || { value: [] }).value.map(i => {
              return (<Row noGutters key={i.name} className="list-panel-item">
                <Col xs={5}>{i.name}</Col>
                <Col xs={5}><input type="text" onChange={(e) => this.setState({updateName: i.name, updateValue: e.target.value})} value={this.state.updateName == i.name ? this.state.updateValue : i.value}/></Col>
                <Col xs={2}>
                  <a className="list-delete" onClick={() => this.props.deleteInternationalizationName(this.state.selectedLanguage, i.name)}>Delete</a>

                  {(i.name == this.state.updateName ?
                    <a className="list-add" onClick={() => { this.props.createOrUpdateInternationalizationName(this.state.selectedLanguage, this.state.updateName, this.state.updateValue); this.setState({ updateName: null, updateValue: null }); }}>Save</a> :
                    <></>
                  )}
                </Col>
              </Row>);
            })}
            <Row noGutters className="list-panel-item">
              <Col xs={5}><input type="text" onChange={(e) => this.setState({addName: e.target.value})} value={this.state.addName}/></Col>
              <Col xs={5}><input type="text" onChange={(e) => this.setState({addValue: e.target.value})} value={this.state.addValue}/></Col>
              <Col xs={2}>
                {(this.state.addName && this.state.addName != "" ?
                  <a className="list-add" onClick={() => { this.props.createOrUpdateInternationalizationName(this.state.selectedLanguage, this.state.addName, this.state.addValue); this.setState({ addName: "", addValue: "" }); }}>Add</a> :
                  <></>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    internationalizations: store.internationalizations
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(Internationalization);
