import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Form, Button, ProgressBar } from "react-bootstrap";
import { HubConnectionBuilder } from '@microsoft/signalr';

import { getConfig } from '../lib/utils';

import Resources from "../lib/resources";

const dispatchToProps = dispatch => ({
});

class Migration extends Component {
  constructor(props, context) {
    super(props, context);
    this.signal = new HubConnectionBuilder()
      .withUrl(`${getConfig().apiEndpoint}/admin`, { accessTokenFactory: () => getConfig().AccessToken })
      .build();
    this.signal.serverTimeoutInMilliseconds = 1000 * 60 * 60;
    const setAccountListLoadProgress = (accountListLoadProgress) => this.setState({accountListLoadProgress});
    const setAccounts = (accounts) => this.setState({accounts: accounts.sort((a, b) => a.Value.accountName.toLowerCase() > b.Value.accountName.toLowerCase() ? 1 : -1)});
    const setAccountDatabases = (accountDatabases) => this.setState({accountDatabases: accountDatabases.sort()});
    this.signal.onclose(error => {
      console.log(error);
      this.setState({connected: false});
      this.start();
    });
    this.signal.on("LoadingAccounts", (accountListLoadProgress) => setAccountListLoadProgress(accountListLoadProgress));
    this.signal.on("AccountList", (accounts) => setAccounts(accounts));
    this.signal.on("AccountDatabases", (accountDatabases) => setAccountDatabases(accountDatabases));
    this.start();

    this.state = {
      accountListLoadProgress: 0,
      accounts: [],
      connected: false,
      loadingMessage: 'Assembling the Star Destroyers...',
      sortBasis: "accountName",
      sortDirection: "down",
      selectedAccount: null,
      selectedDatabase: null
    };
  }

  async start() {
    try {
        await this.signal.start();
        console.log("connected");
        await this.signal.invoke("GetAccounts");
        this.setState({connected: true});
    } catch (err) {
        console.log(err);
        setTimeout(() => this.start(), 5000);
    }
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    
  }

  toggleDirection(propertyName)
  {
    let sortDirection = this.state.sortBasis !== propertyName ? "down" : this.state.sortDirection === "down" ? "up" : "down";
    this.setState({sortDirection, sortBasis: propertyName});
  }

  render() {
    return (
      <div className="migration">
        <div className="migration-panel-title">
          <h3>{Resources.Migration}</h3>
        </div>
        <div className="migration-panel">
          {(this.state.connected && !this.state.accounts.find(a => a.Value.migrationStatus == "In Progress")) ?
            <div className="migration-list">
              <Row className="migration-list-heading" noGutters={true}>
                <Col xs={6} onClick={() => this.toggleDirection("accountName")}>Account <span className={"fas" + (this.state.sortBasis === "accountName" ? (this.state.sortDirection === "down" ? " fa-chevron-down" : " fa-chevron-up") : "")}/></Col>
                <Col xs={4} onClick={() => this.toggleDirection("migrationProgress")}>Migration Progress <span className={"fas" + (this.state.sortBasis === "migrationProgress" ? (this.state.sortDirection === "down" ? " fa-chevron-down" : " fa-chevron-up") : "")}/></Col>
                <Col xs={2} onClick={() => this.toggleDirection("accountType")}>Account Type <span className={"fas" + (this.state.sortBasis === "accountType" ? (this.state.sortDirection === "down" ? " fa-chevron-down" : " fa-chevron-up") : "")}/></Col>
              </Row>
              <div className="migration-list-items">
                {this.state.accounts.slice().filter(a => a.Value.accountingGroupTypes != null && a.Value.accountingGroupTypes.length == 1 && a.Value.accountingGroupTypes[0] == 1).sort((a,b) => (typeof(a.Value[this.state.sortBasis]) === "string" ? a.Value[this.state.sortBasis].toLowerCase() > b.Value[this.state.sortBasis].toLowerCase() : a.Value[this.state.sortBasis] > b.Value[this.state.sortBasis]) ? (this.state.sortDirection === "down" ? 1 : -1) : (this.state.sortDirection === "down" ? -1 : 1)).map(a => 
                  <div key={a.Key}>
                    <Row onClick={() => this.setState({selectedAccount: a.Key})} className={this.state.selectedAccount === a.Key ? "migration-list-item-selected" : ""} noGutters={true}>
                      <Col xs={6}>
                        <div className="migration-panel-account-name">
                          {a.Value.accountName} ({a.Key})<span className="migration-panel-account-status">{a.Value.migrationStatus}</span>
                        </div>
                      </Col>
                      <Col className="progress-col" xs={4}><ProgressBar striped animated={a.Value.migrationProgress > 0 && a.Value.migrationProgress < 100} variant="info" label={a.Value.migrationProgress + "%"} now={a.Value.migrationProgress}/></Col>
                      <Col xs={2}>{a.Value.accountType}</Col>
                    </Row>
                    {this.state.selectedAccount === a.Key ?
                      <div className="migration-list-item-info">
                        <Row noGutters>
                          <Col>
                            <Row noGutters>
                              <Col xs={10}><Form.Control as="select" onChange={(e) => this.setState({destinationAccount: e.target.value})}><option disabled selected></option>{this.state.accounts.filter(a => a.Value.accountType != "Unclaimed" && a.Value.accountingGroupTypes != null && a.Value.accountingGroupTypes.length == 1 && a.Value.accountingGroupTypes[0] == 2).map(a => <option key={a.Key} value={a.Key}>{a.Value.accountName} ({a.Key})</option>)}</Form.Control></Col>
                              <Col xs={2}><Button disabled={!this.state.destinationAccount ? true : false} variant="danger" onClick={() => this.signal.invoke("MergeAccount", this.state.selectedAccount, this.state.destinationAccount)}>Merge Account</Button></Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>: <></>}
                  </div>
                  )}
              </div>
            </div> :
            <>
              {!this.state.connected ?
                <div className="migration-loading-message">{this.state.loadingMessage}</div> :
                <>
                  <div className="migration-loading-message">{this.state.accounts.filter(a => a.Value.migrationStatus == "In Progress").map(a => a.Value.migrationStatus)[0]}</div>
                  <div className="migration-progress-bar">{this.state.accounts.filter(a => a.Value.migrationStatus == "In Progress").map(a => <ProgressBar striped animated={a.Value.migrationProgress > 0 && a.Value.migrationProgress < 100} variant="info" label={a.Value.migrationProgress + "%"} now={a.Value.migrationProgress}/>)[0]}</div>
                </>
              }
              <div className="migration-loading">
                <div className="logo-spinner">
                  <img src="/img/lslogo.png"/>
                </div>
                {[...Array(Math.floor((Math.random() * 1000) + 100)).keys()].map(i => {
                  return <div className="loading-float-bar" key={i} style={{
                    position: 'relative',
                    left: (Math.floor(-Math.random() * 300 - 50) + "%"),
                    top: (Math.floor(Math.random() * 130 - 15) + "%"),
                    width: ((Math.random() * 20 + 8) + "rem"),
                    height: ((Math.random() * 8 + 2) + "rem"),
                    borderRadius: '1rem',
                    transform: 'rotate(-30deg)',
                    backgroundColor: (["rgba(0, 186, 212, 0.2)","rgba(238,57,139,0.2)","rgba(89,85,165,0.2)","rgba(249,164,86,0.2)"][Math.floor(Math.random() * 4)]),
                    animationName: 'slide',
                    animationDuration: '20s',
                    animationIterationCount: 'infinite',
                    animationTimingFunction: 'linear'
                  }}/>
                })}
              </div>
            </>}
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    bulkSenders: store.bulkSenders
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(Migration);
