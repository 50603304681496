import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

export default class ModalDialog extends Component {
    render() {
      return <Modal className={this.props.className}
        isOpen={this.props.isOpen}>
        {this.props.title !== null ?
        <Modal.Header className={this.props.headerClassName}>
          {this.props.title}
        </Modal.Header>
         : null } 
        <Modal.Body className={this.props.bodyClassName}>
          {this.props.content}
        </Modal.Body>
        {this.props.footer !== null ?  
        <Modal.Footer className={this.props.footerClassName}>
          {this.props.footer}
        </Modal.Footer> : null }
      </Modal>
    }
  }