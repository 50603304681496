import React, { Component } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import LoginForm from "./loginForm";
import Resources from "../lib/resources";

export default class Login extends Component {
  render() {
    const loginFooter = (login) => {
      return (
        <div id="login-login-footer">
          <button id="login-login-btn">{Resources.Login}</button>
        </div>
      );
    };

    return (
      <Row id="login-container" noGutters={true}>
        <Col sm={12} md={3} lg={2} xl={2}>
          <img id="login-logo" src="/img/lockstep.png" title={Resources.Lockstep} alt={Resources.Lockstep} />
        </Col>
        <Col id="login-subcontainer" sm={12} md={9} lg={10} xl={10}>
          <Row noGutters={true}>
            <Col sm={0} md={3} lg={4} xl={4}></Col>
            <Col sm={12} md={6} lg={4} xl={4}>
              <Alert variant="danger">
                This portal is for authorized users only. Unauthorized attempts to upload or otherwise alter data,
                programming language, or any other part of Lockstep's systems are strictly prohibited and are subject to
                civil action and/or criminal prosecution.
              </Alert>
              <h3>{Resources.LoginTitle}</h3>
              <div id="login-right-container">
                <LoginForm loginFooter={loginFooter} />
              </div>
            </Col>
            <Col sm={0} md={3} lg={4} xl={4}></Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
