import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class AccountPaymentMethods extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    let { account, toggle, toggleText, addPaymentMethod } = this.props;

    return (
      <Row noGutters>
        <Col xs={12} sm={6}>
          <div className="customers-panel-customer-name">{account.accountName}</div>
          <div className="customers-panel-heading">
            <a onClick={toggle}>{toggleText}</a>
          </div>
        </Col>
        {(addPaymentMethod != null ?
            (<Col xs={12} sm={6}>
                <div className="customers-panel-heading customers-panel-actions">&nbsp;</div>
                <div className="customers-panel-value customers-panel-actions">
                    <a onClick={addPaymentMethod}><span className="fas fa-plus-square"/> Add Payment Method</a>
                </div>
            </Col>) :
            (<Col xs={12} sm={6}></Col>)
        )}
      </Row>
    );
  }
}

export default AccountPaymentMethods;
