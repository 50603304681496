import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";
import Resources from "../lib/resources";
import AccountPaymentMethods from "./accountPaymentMethods";
import SwitchButton from "./controls/switchButton";
import MainLoader from "./library/mainLoader";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as aDP } from "../store/accounts-actions";
import { dispatchToProps as pmDP } from "../store/paymentMethods-actions";
import QueryString from "qs";
import data from "../lib/data";
import { decryptKey, encryptKey, isEmpty } from "../lib/utils";

const dispatchToProps = (dispatch) => ({
  ...uaDP(dispatch),
  ...aDP(dispatch),
  ...pmDP(dispatch),
});

let intervalId;

const maxTenCharRegex = new RegExp(/^.{1,10}$/);
class PaymentMethods extends Component {
  constructor(props, context) {
    super(props, context);

    this.renderEditPaymentMethodModal = this.renderEditPaymentMethodModal.bind(this);
    this.editPaymentMethod = this.editPaymentMethod.bind(this);
    this.savePaymentMethod = this.savePaymentMethod.bind(this);
    this.handleClosePaymentMethod = this.handleClosePaymentMethod.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this._noRowsRenderer = this._noRowsRenderer.bind(this);

    this.state = {
      searchQuery: "",
      totalAccounts: 0,
      accountsArray: [],
      sortAccount: '',
      activePaymentMethod: !window.localStorage.getItem("activePaymentMethod")
        ? {}
        : JSON.parse(window.localStorage.getItem("activePaymentMethod")),
      showAccount: !window.localStorage.getItem("showAccount")
        ? null
        : JSON.parse(window.localStorage.getItem("showAccount")),
      affinipay:
        (window.location + "").split("?", 2)[1] === undefined
          ? null
          : JSON.parse(QueryString.parse((window.location + "").split("?", 2)[1]).affinipayResponse),
          paymentmethodIdentifierValidatorMessage: ''
     };
  }

  componentDidMount() {
    this.ensureAccounts(true);
    if (this.state.showAccount != null) {
      this.setAccount(this.state.showAccount);
      if (this.state.activePaymentMethod != null) {
        this.editPaymentMethod(this.state.activePaymentMethod);
      }
    }
  }

  componentDidUpdate() {
    this.ensureAccounts(false);
    if (this.state.showAccount) {
      this.updateRowHeight();
    }
  }

  ensureAccounts(force) {
    if (
      force === true ||
      (this.props.accounts.fetchingAccounts === false &&
        this.props.accounts.fetchedAccounts !== true &&
        this.props.accounts.fetchAccountsFailed !== true)
    ) {
      this.props.fetchAccounts();
      intervalId = setInterval(() => {
        this.setState({ accountsLoaded: false, accountsArray: (this.props.accounts || { accounts: [] })?.accounts?.filter(account => account.accountType === Resources.ATC), totalAccounts: (this.props.accounts || { accounts: [] }).accounts.filter(h => h.accountType === Resources.ATC).length })
        if (this.state.totalAccounts > 0) {
          this.setState({ accountsLoaded: true });
          clearInterval(intervalId);
        }
      }, 600);
    }
    if (this.state.showAccount && !this.stateisEditingPaymentMethod && !this.props.paymentMethods.fetchingPaymentMethods
      && this.props.paymentMethods.fetchedPaymentMethods && this.props.paymentMethods.fetchPaymentMethodsFailed
    ) {
      this.setState({ showAccount: null }, () => this.updateRowHeight());
    }
  }

  list = React.createRef()

  _noRowsRenderer() {
    return <div className="customers-panel-collapsed" >
      <div className="customers-panel-customer-name text-center" style={{ color: "grey" }}>
        {this.state.searchQuery && this.state.totalAccounts === 0 ? <>{Resources.NoAccountFound}</> : <>{`${Resources.LoadingAccountsPleaseWait} ...`} </>}
      </div>
    </div>;
  }

  cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 100
  });

  updateRowHeight = () => {
    if (!this.list) {
      return;
    }
    this.cache.clearAll();
    this.list.current.recomputeRowHeights();
  }

  filteredAccounts = () => {
    let accountsFilteredArray = (this.props.accounts || { accounts: [] }).accounts.filter(account => account.accountType === Resources.ATC && (this.state.searchQuery === "" || account.accountName.toLowerCase().includes(this.state.searchQuery.toLowerCase())));
    if (this.state.searchQuery === "" || accountsFilteredArray) {
      this.setState({ accountsArray: accountsFilteredArray, totalAccounts: accountsFilteredArray.length });
    }
  }

  sortAccounts = (sortBy) => {
    let tempAccountArray = [];
    if (this.state.searchQuery) {
      tempAccountArray = this.state.accountsArray
    }
    else {
      tempAccountArray = (this.props.accounts || { accounts: [] }).accounts.filter(account => account.accountType === Resources.ATC)
    }
    if (sortBy === "asc") {
      this.setState({ sortAccount: "asc", accountsArray: tempAccountArray.sort((a, b) => (a.accountName > b.accountName) ? 1 : ((b.accountName > a.accountName) ? -1 : 0)) })
    }
    else if (sortBy === "desc") {
      this.setState({ sortAccount: "desc", accountsArray: tempAccountArray.sort((a, b) => (a.accountName < b.accountName) ? 1 : ((b.accountName < a.accountName) ? -1 : 0)) })
    }
    else {
      this.setState({ sortAccount: "" })
    }
  }

  getRowHeight = (args) => {
    const rowHeight = this.cache.rowHeight(args);
    return rowHeight + 20;
  };

  setAccount(accountId) {
    this.props.fetchPaymentMethods(accountId);
    this.setState({ showAccount: accountId });
  }

  handleClosePaymentMethod() {
    this.setState({ isEditingPaymentMethod: false, affinipay: null, paymentmethodIdentifierValidatorMessage: '' });
    window.localStorage.removeItem("activePaymentMethod");
    window.localStorage.removeItem("showAccount");
  }

  async editPaymentMethod(paymentMethod) {
    if (
      paymentMethod.paymentProviderName === Resources.WorldPay ||
      paymentMethod.paymentProviderName === Resources.CardConnect ||
      paymentMethod.paymentProviderName === Resources.FortisPay ||
      paymentMethod.paymentProviderName === Resources.Repay
    ) {
      let JSONpaymentMethod = JSON.parse(paymentMethod.additionalParameterJson);
      if (JSONpaymentMethod === null) {
        JSONpaymentMethod = JSON.parse(JSON.stringify({}));
      }
      JSONpaymentMethod.IsSurchargeApplicable = JSONpaymentMethod.IsSurchargeApplicable ? true : false;
      const encryptedValue = JSONpaymentMethod.EncryptionKeyCC || '';
      const decryptedValue = encryptedValue ? await decryptKey(this.state.showAccount, 'EncryptionKey', encryptedValue) : '';
      JSONpaymentMethod.EncryptionKeyCC = decryptedValue || JSONpaymentMethod.EncryptionKeyCC;
      paymentMethod.additionalParameterJson = JSON.stringify(JSONpaymentMethod);
      paymentMethod.AddnParamJson = paymentMethod.additionalParameterJson;
    }
    if (paymentMethod?.paymentProviderName === Resources.AuthorizeNet) {
      paymentMethod.paymentProviderName = Resources.AuthorizeNet;
    }
    this.setState({ isEditingPaymentMethod: true, activePaymentMethod: paymentMethod });
  }

  async savePaymentMethod() {
    const modal = document.getElementsByClassName('fade modal show')[0];
    let activePaymentMethod = { ...this.state.activePaymentMethod },
      toJsonAdditionalParameterJson = JSON.parse(activePaymentMethod.additionalParameterJson);
    if (this.state.activePaymentMethod?.paymentProviderName === Resources.FortisPay) {
      const value = toJsonAdditionalParameterJson['EncryptionKeyCC'],
        encryptedKey = value ? await encryptKey(this.state.showAccount, 'EncryptionKey', value) : '';
      toJsonAdditionalParameterJson['EncryptionKeyCC'] = encryptedKey;
      activePaymentMethod.additionalParameterJson = JSON.stringify(toJsonAdditionalParameterJson);
      activePaymentMethod.AddnParamJson = JSON.stringify(toJsonAdditionalParameterJson);
      this.setState({ activePaymentMethod });
    }
    if (!this.state.activePaymentMethod.identifier) {
      this.setState({
        paymentmethodIdentifierValidatorMessage: Resources.PaymentMethodNameIsRequired
      });
      modal.scrollTo(0, 0);
    }
   else {
      if (
        this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay &&
        isEmpty(this.state.activePaymentMethod.merchantAppId)
      ) {
        data.post(`v2/api/payments/providers/affinipay/onboardinginvitation/for/${this.state.showAccount}`);
      } else {
        this.props.updatePaymentMethod(this.state.showAccount, this.state.activePaymentMethod, false);
      }
      this.setState({ isEditingPaymentMethod: false, affinipay: null });
      window.localStorage.removeItem("activePaymentMethod");
      window.localStorage.removeItem("showAccount");
    }
  }

  toggleActiveStatus(paymentMethod) {
    paymentMethod.isActive = !paymentMethod.isActive;
    this.props.updatePaymentMethod(this.state.showAccount, paymentMethod, true);
  }

  toggleIsSurchargeApplicable() {
    const activePaymentMethod = { ...this.state.activePaymentMethod }
    const toJsonAdditionalParameterJson = JSON.parse(activePaymentMethod.additionalParameterJson);
    let target = {
      value: !toJsonAdditionalParameterJson.IsSurchargeApplicable
    }
    this.setAdditionalInfo('IsSurchargeApplicable', target);
  }

  setAdditionalInfo(field, target) {
    this.setState(prevState => {
      const activePaymentMethod = { ...this.state.activePaymentMethod }
      const toJsonAdditionalParameterJson = JSON.parse(activePaymentMethod.additionalParameterJson);
      toJsonAdditionalParameterJson[field] = target.value;
      activePaymentMethod.additionalParameterJson = JSON.stringify(toJsonAdditionalParameterJson);
      activePaymentMethod.AddnParamJson = JSON.stringify(toJsonAdditionalParameterJson);
      return { activePaymentMethod }
    });
  }

  renderEditPaymentMethodModal() {
    return (
      <Modal show={this.state.isEditingPaymentMethod} onHide={this.handleClosePaymentMethod}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.activePaymentMethod.paymentMethodId === undefined ? Resources.New : Resources.Edit}{" "}
            {Resources.PaymentMethod}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(() => {
            if (
              !this.state.affinipay &&
              this.state.activePaymentMethod.beginOAuthIntegration === true &&
              this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay
            ) {
              if (this.props.paymentMethods.oAuthIntegrationLoading === true) {
                return <MainLoader />;
              } else {
                window.location = this.props.paymentMethods.oAuthIntegrationUrl;
              }
            } else {
              return (
                <>
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                      (!isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                        this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.PaymentMethodName}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.identifier}
                            onChange={(e) => {
                              this.setState({ paymentmethodIdentifierValidatorMessage: '' })
                              if (!e.target.value || maxTenCharRegex.test(e.target.value)) {
                                this.setState({ paymentmethodIdentifierValidatorMessage: '' })
                              }
                              else {
                                this.setState({ paymentmethodIdentifierValidatorMessage: `${Resources.MinAndMaxCharAllowed(1, 10)}.` })
                              }
                              this.setState({
                                activePaymentMethod: { ...this.state.activePaymentMethod, identifier: e.target.value }
                              });
                            }}
                            placeholder={Resources.SimpleMethodNameNoSpaces}
                          />
                          <Form.Text className="text-muted text-error">{this.state.paymentmethodIdentifierValidatorMessage}</Form.Text>
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                      (!isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                        this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.Description}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.description}
                            onChange={(e) => {
                              this.setState({
                                activePaymentMethod: { ...this.state.activePaymentMethod, description: e.target.value },
                              });
                            }}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                      (!isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                        this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.CompanyID}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.companyId}
                            onChange={(e) => {
                              this.setState({
                                activePaymentMethod: { ...this.state.activePaymentMethod, companyId: e.target.value },
                              });
                            }}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                      (!isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                        this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.CustClassID}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.custClassId || ""}
                            onChange={(e) => {
                              this.setState({
                                activePaymentMethod: { ...this.state.activePaymentMethod, custClassId: e.target.value },
                              });
                            }}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                      (!isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                        this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.CurrencyID}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.currencyId}
                            onChange={(e) => {
                              this.setState({
                                activePaymentMethod: { ...this.state.activePaymentMethod, currencyId: e.target.value },
                              });
                            }}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                      (!isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                        this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.MaximumPaymentAmount}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.maxPaymentAmount}
                            onChange={(e) => {
                              this.setState({
                                activePaymentMethod: {
                                  ...this.state.activePaymentMethod,
                                  maxPaymentAmount: e.target.value,
                                },
                              });
                            }}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  <Form.Group>
                    <Form.Label>{Resources.Provider}</Form.Label>
                    <Form.Control
                      as="select"
                      disabled={
                        !isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                          this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay
                          ? true
                          : false
                      }
                      value={this.state.activePaymentMethod.paymentProviderName}
                      onChange={(e) => {
                        this.setState({
                          activePaymentMethod: {
                            ...this.state.activePaymentMethod,
                            paymentProviderName: e.target.value,
                            paymentmethodIdentifierValidatorMessage: '',
                          },
                        });
                      }}
                    >
                      <option>{Resources.SoluPay}</option>
                      <option>{Resources.SagePayment}</option>
                      <option>{Resources.Repay}</option>
                      <option>{Resources.AffiniPay}</option>
                      <option>{Resources.WorldPay}</option>
                      <option>{Resources.CardConnect}</option>
                      <option>{Resources.CardKnox}</option>
                      <option>{Resources.FortisPay}</option>
                      <option value={Resources.AuthorizeNet}>{Resources.AuthorizeDotNet}</option>
                    </Form.Control>
                  </Form.Group>
                  {(() => {
                    return (
                      <>
                        {this.state.activePaymentMethod.paymentProviderName === Resources.WorldPay ||
                          this.state.activePaymentMethod.paymentProviderName === Resources.CardConnect ||
                          this.state.activePaymentMethod.paymentProviderName === Resources.FortisPay ||
                          this.state.activePaymentMethod.paymentProviderName === Resources.Repay ||
                          this.state.activePaymentMethod.paymentProviderName === Resources.AuthorizeNet
                          ?
                          <Form.Group>
                            <Form.Label style={{ display: 'flex' }}>{Resources.IsSurchargeApplicable}<SwitchButton marginLeft='5px' value={(this.state.activePaymentMethod.additionalParameterJson && JSON.parse(this.state.activePaymentMethod.additionalParameterJson).IsSurchargeApplicable)} onClick={() => this.toggleIsSurchargeApplicable()} />
                            </Form.Label>
                            {(this.state.activePaymentMethod.additionalParameterJson && JSON.parse(this.state.activePaymentMethod.additionalParameterJson).IsSurchargeApplicable) ?
                              <>
                                <Form.Group className="mt-2">
                                  <Form.Label>{Resources.InterpaymentUri}</Form.Label>
                                  <Form.Control value={(this.state.activePaymentMethod.additionalParameterJson && JSON.parse(this.state.activePaymentMethod.additionalParameterJson).SurchargeUri) || ""}
                                    onChange={({ target }) => this.setAdditionalInfo('SurchargeUri', target)}
                                  />
                                </Form.Group>
                                <Form.Group className="mt-2">
                                  <Form.Label>{Resources.InterpaymentAccessToken}</Form.Label>
                                  <Form.Control value={(this.state.activePaymentMethod.additionalParameterJson && JSON.parse(this.state.activePaymentMethod.additionalParameterJson).SurchargeAccessToken) || ""}
                                    onChange={({ target }) => this.setAdditionalInfo('SurchargeAccessToken', target)}
                                  />
                                </Form.Group>
                              </>
                              : <></>
                            }
                          </Form.Group>
                          : <></>
                        }
                      </>
                    )
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay &&
                      isEmpty(this.state.activePaymentMethod.merchantAppId)
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.AccountKey}</Form.Label>
                          <Form.Control value={this.state.showAccount} readOnly={true} />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay &&
                      isEmpty(this.state.activePaymentMethod.merchantAppId)
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.Status}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.description}
                            readOnly={true}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay) {
                      return (
                        <Form.Group>
                          <Form.Label>{this.state.activePaymentMethod.paymentProviderName === Resources.WorldPay ? Resources.InterfaceUrl1 : this.state.activePaymentMethod.paymentProviderName === Resources.CardConnect ? Resources.InterfaceUrlACHOrCredit : (this.state.activePaymentMethod.paymentProviderName === Resources.CardKnox || this.state.activePaymentMethod.paymentProviderName === Resources.FortisPay || this.state.activePaymentMethod.paymentProviderName === Resources.AuthorizeNet) ? Resources.SandboxProdUrl : Resources.BaseUrl}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.paymentProviderBaseUrl}
                            onChange={(e) => {
                              this.setState({
                                activePaymentMethod: {
                                  ...this.state.activePaymentMethod,
                                  paymentProviderBaseUrl: e.target.value,
                                },
                              });
                            }}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName !== Resources.SagePayment &&
                      this.state.activePaymentMethod.paymentProviderName !== Resources.AuthorizeNet &&
                      (this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                        (isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                          this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)) && this.state.activePaymentMethod.paymentProviderName !== Resources.CardConnect
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>
                            {this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay
                              ? Resources.OnboardingUrl
                              : this.state.activePaymentMethod.paymentProviderName === Resources.WorldPay
                                ? Resources.InterfaceUrl2
                                : (this.state.activePaymentMethod.paymentProviderName === Resources.CardKnox || this.state.activePaymentMethod.paymentProviderName === Resources.FortisPay)
                                  ? Resources.Version
                                  : Resources.SecondaryUrl}
                          </Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.paymentProviderSecondaryUrl}
                            onChange={(e) => {
                              this.setState({
                                activePaymentMethod: {
                                  ...this.state.activePaymentMethod,
                                  paymentProviderSecondaryUrl: e.target.value,
                                },
                              });
                            }}
                            readOnly={this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay ? true : false}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (this.state.activePaymentMethod?.paymentProviderName === Resources.FortisPay) {
                      return (
                        <Form.Group>
                          <Form.Label>{Resources.ProductTransIdACH}</Form.Label>
                          <Form.Control value={(this.state.activePaymentMethod.additionalParameterJson && JSON.parse(this.state.activePaymentMethod.additionalParameterJson).ACHProductTransactionId) || ""}
                            onChange={({ target }) => this.setAdditionalInfo('ACHProductTransactionId', target)}
                          />
                        </Form.Group>
                      )
                    }
                  })()}
                  {(() => {
                    if (
                      this.state.activePaymentMethod.paymentProviderName !== Resources.AuthorizeNet &&
                      (this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                        (!isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                          this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)) && this.state.activePaymentMethod.paymentProviderName !== Resources.CardKnox
                    ) {
                      return (
                        <Form.Group>
                          <Form.Label>{this.state.activePaymentMethod.paymentProviderName === Resources.WorldPay ? Resources.AcceptorID : this.state.activePaymentMethod.paymentProviderName === Resources.FortisPay ? Resources.LocationID : Resources.MerchantAppID}</Form.Label>
                          <Form.Control
                            value={this.state.activePaymentMethod.merchantAppId}
                            onChange={(e) => {
                              this.setState({
                                activePaymentMethod: {
                                  ...this.state.activePaymentMethod,
                                  merchantAppId: e.target.value,
                                },
                              });
                            }}
                          />
                        </Form.Group>
                      );
                    }
                  })()}
                  {(() => {
                    if (this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay) {
                      if (this.state.affinipay !== null) {
                        return (
                          <>
                            <Form.Group>
                              <Form.Label>{Resources.AccountSelection}</Form.Label>
                              <Form.Control
                                as="select"
                                value={this.state.activePaymentMethod.merchantLoginId}
                                onChange={(e) => {
                                  this.setState({
                                    activePaymentMethod: {
                                      ...this.state.activePaymentMethod,
                                      merchantLoginId: e.target.value,
                                      merchantLoginKey: this.state.affinipay.test_accounts
                                        .concat(this.state.affinipay.live_accounts)
                                        .filter((a) => a.id === e.target.value)
                                        .map((a) => a.public_key + ":" + a.secret_key)[0],
                                    },
                                  });
                                }}
                              >
                                {this.state.affinipay.test_accounts
                                  .concat(this.state.affinipay.live_accounts)
                                  .map((a) => {
                                    return (
                                      <option key={a.id} value={a.id}>
                                        {a.name}
                                      </option>
                                    );
                                  })}
                              </Form.Control>
                            </Form.Group>
                          </>
                        );
                      }
                    } else {
                      return (
                        <>
                          <Form.Group>
                            <Form.Label>
                              {this.state.activePaymentMethod.paymentProviderName && this.state.activePaymentMethod.paymentProviderName === Resources.Repay
                                ? Resources.ApiKey
                                : this.state.activePaymentMethod.paymentProviderName === Resources.WorldPay
                                  ? Resources.AccountID
                                  : this.state.activePaymentMethod.paymentProviderName === Resources.CardKnox
                                    ? Resources.TransactionKey
                                    : this.state.activePaymentMethod.paymentProviderName === Resources.FortisPay
                                      ? Resources.UserID
                                      : this.state.activePaymentMethod.paymentProviderName === Resources.AuthorizeNet
                                        ? Resources.ApiId
                                        : Resources.MerchantLoginId
                              }
                            </Form.Label>
                            <Form.Control
                              value={this.state.activePaymentMethod.merchantLoginId}
                              onChange={(e) => {
                                this.setState({
                                  activePaymentMethod: {
                                    ...this.state.activePaymentMethod,
                                    merchantLoginId: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Form.Group>
                          {(() => {
                            if(this.state.activePaymentMethod.paymentProviderName === Resources.CardKnox || this.state.activePaymentMethod.paymentProviderName === Resources.FortisPay || this.state.activePaymentMethod.paymentProviderName === Resources.AuthorizeNet || this.state.activePaymentMethod.paymentProviderName === Resources.WorldPay || this.state.activePaymentMethod.paymentProviderName === Resources.CardConnect || this.state.activePaymentMethod.paymentProviderName === Resources.SagePayment){
                              return ( 
                              <Form.Group>
                                <Form.Label>{this.state.activePaymentMethod.paymentProviderName === Resources.CardKnox ? Resources.IFieldsKey : this.state.activePaymentMethod.paymentProviderName === Resources.FortisPay ? Resources.UserApiKey : this.state.activePaymentMethod.paymentProviderName === Resources.AuthorizeNet ? Resources.TransactionKey :this.state.activePaymentMethod.paymentProviderName === Resources.WorldPay ? Resources.AccountToken: Resources.MerchantLoginKey}</Form.Label>
                                  <Form.Control
                                    value={this.state.activePaymentMethod.merchantLoginKey}
                                    onChange={(e) => {
                                      this.setState({
                                        activePaymentMethod: {
                                          ...this.state.activePaymentMethod,
                                          merchantLoginKey: e.target.value,
                                        },
                                      });
                                    }}
                                  />
                              </Form.Group>
                              )
                            }
                            if (this.state.activePaymentMethod.paymentProviderName && this.state.activePaymentMethod.paymentProviderName === Resources.WorldPay) {
                              return (
                                <>
                                  <Form.Group>
                                    <Form.Label>
                                      {Resources.TerminalID}
                                    </Form.Label>
                                    <Form.Control
                                      value={(this.state.activePaymentMethod.additionalParameterJson && JSON.parse(this.state.activePaymentMethod.additionalParameterJson).TerminalId) || ""}
                                      onChange={({ target }) => this.setAdditionalInfo('TerminalId', target)}
                                    />
                                  </Form.Group>
                                </>
                              )
                            }
                            else if (this.state.activePaymentMethod.paymentProviderName && this.state.activePaymentMethod.paymentProviderName === Resources.Repay) {
                              return (
                                <>
                                  <Form.Group>
                                    <Form.Label>{Resources.SummaryCommodityCode}</Form.Label>
                                    <Form.Control
                                      value={
                                        ((this.state.activePaymentMethod.merchantLoginKey || "").split(":") || [""])[0]
                                      }
                                      onChange={(e) => {
                                        this.setState({
                                          activePaymentMethod: {
                                            ...this.state.activePaymentMethod,
                                            merchantLoginKey:
                                              e.target.value +
                                              ":" +
                                              (((this.state.activePaymentMethod.merchantLoginKey || "").split(":") || [
                                                "",
                                                "",
                                              ])[1] || ""),
                                          },
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Label>{Resources.ItemCommodityCode}</Form.Label>
                                    <Form.Control
                                      value={
                                        ((this.state.activePaymentMethod.merchantLoginKey || "").split(":") || [
                                          "",
                                          "",
                                        ])[1] || ""
                                      }
                                      onChange={(e) => {
                                        this.setState({
                                          activePaymentMethod: {
                                            ...this.state.activePaymentMethod,
                                            merchantLoginKey:
                                              ((this.state.activePaymentMethod.merchantLoginKey || "").split(":") || [
                                                "",
                                              ])[0] +
                                              ":" +
                                              e.target.value,
                                          },
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                </>
                              );
                            } 
                          })()}
                          {(() => {
                            if (this.state.activePaymentMethod.paymentProviderName && this.state.activePaymentMethod.paymentProviderName === Resources.FortisPay) {
                              return (
                                <>
                                  <Form.Group>
                                    <Form.Label>{Resources.EncryptionKey}</Form.Label>
                                    <Form.Control value={(this.state.activePaymentMethod.additionalParameterJson && JSON.parse(this.state.activePaymentMethod.additionalParameterJson).EncryptionKeyCC) || ""}
                                      onChange={({ target }) => this.setAdditionalInfo('EncryptionKeyCC', target)}
                                    />
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Label>{Resources.HostedPageIdCC}</Form.Label>
                                    <Form.Control value={(this.state.activePaymentMethod.additionalParameterJson && JSON.parse(this.state.activePaymentMethod.additionalParameterJson).HostedPaymentPageIdCC) || ""}
                                      onChange={({ target }) => this.setAdditionalInfo('HostedPaymentPageIdCC', target)}
                                    />
                                  </Form.Group>
                                </>
                              )
                            }
                          })()}
                        </>
                      );
                    }
                  })()}
                </>
              );
            }
          })()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClosePaymentMethod}>
            {Resources.Close}
          </Button>
          {(() => {
            if (this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay) {
              if (isEmpty(this.state.activePaymentMethod.merchantAppId)) {
                return (
                  <Button variant="primary" onClick={this.savePaymentMethod}>
                    {Resources.Invite}
                  </Button>
                );
              } else {
                if (
                  this.state.activePaymentMethod.paymentProviderName !== Resources.AffiniPay ||
                  (!isEmpty(this.state.activePaymentMethod.merchantAppId) &&
                    this.state.activePaymentMethod.paymentProviderName === Resources.AffiniPay)
                ) {
                  return (
                    <Button variant="primary" onClick={this.savePaymentMethod}>
                      {Resources.SaveChanges}
                    </Button>
                  );
                } else {
                  return (
                    <Button variant="primary" onClick={this.savePaymentMethod}>
                      {Resources.Resend}
                    </Button>
                  );
                }
              }
            } else {
              return (
                <Button variant="primary" onClick={this.savePaymentMethod}>
                  {Resources.SaveChanges}
                </Button>
              );
            }
          })()}
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <div className="panel-title" style={{ background: "white", position: "sticky", top: 0, zIndex: 11 }}>
          <h3>{Resources.PaymentMethods}</h3>
          <div
            className="customers-panel-title customers-panel-title-with-search">
            <div className="customers-panel-header">
              {Resources.Accounts} ({this.state.totalAccounts})&nbsp;&nbsp; <span style={{ color: this.state.sortAccount === "asc" ? 'blue' : 'grey' }} onClick={() => this.sortAccounts('asc')}>&uarr;</span> <span onClick={() => this.sortAccounts('desc')} style={{ color: this.state.sortAccount === "desc" ? 'blue' : 'grey' }}>&darr;</span>
            </div>
            <input
              type="text"
              className="search-box"
              placeholder={Resources.SearchYourAccountName}
              value={this.state.searchQuery}
              onChange={(e) => {
                this.setState({ searchQuery: e.target.value })
                setTimeout(() => {
                  this.filteredAccounts();
                  this.sortAccounts(this.state.sortAccount)
                }, 0);
              }} />
          </div>
        </div>
        {this.renderEditPaymentMethodModal()}
        <div className="customers-panel">
          <div style={{ width: "100%", height: "100vh" }}>
            <AutoSizer rowCount={this.state.totalAccounts}>
              {({ width, height }) =>
                <List
                  ref={this.list}
                  noRowsRenderer={this._noRowsRenderer}
                  rowCount={this.state.totalAccounts}
                  rowHeight={this.getRowHeight}
                  deferredMeasurementCache={this.cache}
                  width={width}
                  height={height}
                  rowRenderer={({ key, index, style, parent }) => {
                    const Accounts = this.state.accountsArray[index];
                    if (this.state.showAccount && this.state.showAccount === Accounts?.accountId) {
                      return (
                        <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
                          <div className="customers-panel-expanded" style={style}>
                            <AccountPaymentMethods
                              account={Accounts}
                              toggleText={Resources.Hide}
                              toggle={() => this.setState({ showAccount: null }, () => this.updateRowHeight())}
                              addPaymentMethod={() => this.editPaymentMethod({ paymentProviderName: 'SoluPay', additionalParameterJson: JSON.stringify({}), AddnParamJson: JSON.stringify({}) })}
                            />
                            <div className="customers-panel-expanded-rows">
                              {(this.props.paymentMethods || { paymentMethods: [] }).paymentMethods.map((i) => {
                                return (
                                  <Row className="customers-panel-expanded-row" key={i.merchantAccountId} noGutters>
                                    <Col xs={12} sm={3}>
                                      <div className="customers-panel-heading">{Resources.PaymentConfiguration}</div>
                                      <div className="customers-panel-value">
                                        {i.identifier} ({i.description})
                                      </div>
                                    </Col>
                                    <Col xs={0} sm={2}>
                                      <div className="customers-panel-heading">{Resources.Company}</div>
                                      <div className="customers-panel-value">{i.companyId}</div>
                                    </Col>
                                    <Col xs={6} sm={2}>
                                      <div className="customers-panel-heading">{Resources.CustomerClass}</div>
                                      <div className="customers-panel-value">{i.custClassId}</div>
                                    </Col>
                                    <Col xs={6} sm={1}>
                                      <div className="customers-panel-heading">{Resources.Currency}</div>
                                      <div className="customers-panel-value">{i.currencyId}</div>
                                    </Col>
                                    <Col xs={3} sm={1}>
                                      <div className="customers-panel-heading">{Resources.MaxAmt}</div>
                                      <div className="customers-panel-value customers-panel-value-mono">
                                        {i.maxPaymentAmount}
                                      </div>
                                    </Col>
                                    <Col xs={3} sm={1}>
                                      <div className="customers-panel-heading">{Resources.Provider}</div>
                                      <div className="customers-panel-value">{i.paymentProviderName}</div>
                                    </Col>
                                    <Col xs={3} sm={1}>
                                      <div className="customers-panel-heading">{Resources.Active}</div>
                                      <div className="customers-panel-value customers-panel-actions">
                                        <SwitchButton value={i.isActive} onClick={() => this.toggleActiveStatus(i)} />
                                      </div>
                                    </Col>
                                    <Col xs={3} sm={1}>
                                      <div className="customers-panel-heading customers-panel-actions">{Resources.Actions}</div>
                                      <div className="customers-panel-value customers-panel-actions">
                                        <span className="fas fa-edit" onClick={() => this.editPaymentMethod(i)} />
                                        <span className="fas fa-trash" />
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </div>
                          </div>
                        </CellMeasurer>
                      );
                    } else {
                      return (
                        <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
                          <div className="customers-panel-collapsed" style={style}>
                            <AccountPaymentMethods
                              account={Accounts}
                              toggleText={Resources.ShowPaymentMethods}
                              toggle={() => this.setAccount(Accounts.accountId)}
                            />
                          </div>
                        </CellMeasurer>
                      );
                    }
                  }
                  }
                />
              }
            </AutoSizer>
          </div>
        </div>
      </div>
    );
  }
}

const storeToProps = (store) => {
  return {
    user: store.user,
    accounts: store.accounts,
    paymentMethods: store.paymentMethods,
  };
};

export default connect(storeToProps, dispatchToProps)(PaymentMethods);
