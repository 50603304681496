import { actions } from './resource-actions'

const defaultResourcesState = {
  fetchingResources: false,
  fetchedResources: false,
  fetchResourcesFailed: false,
  resources: []
}

function resourceReducer(state = defaultResourcesState, action) {
  switch (action.type) {
    case actions.FETCHING_RESOURCES:
      return { ...state, ...action, fetchingResources: true, fetchedResources: false, fetchResourcesFailed: false }
    case actions.FETCH_RESOURCES_FAILED:
      return { ...state, ...action, fetchingResources: false, fetchedResources: true, fetchResourcesFailed: true }
    case actions.FETCHED_RESOURCES:
      return { ...state, ...action, fetchingResources: false, fetchedResources: true, fetchResourcesFailed: false }
    default:
      return state
  }
}

export default resourceReducer
