import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { Route, withRouter, Switch, Redirect, Link } from "react-router-dom";
import { Row, Col, NavItem } from "react-bootstrap";

import HealthDashboard from "./healthDashboard";
import PaymentMethods from "./paymentMethods";
import BulkSenders from "./bulkSenders";
import InviteCompany from "./inviteCompany";
import SignupControl from "./signupControl";
import InviteHistory from "./inviteHistory";
import ManageUsers from "./manageUsers";
import UsageStatistics from "./usageStatistics";
import FeatureGroups from "./featureGroups";
import ServiceEmailTemplates from "./serviceEmailTemplates";
import ConnectorManagement from "./connectorManagement";
import Internationalization from "./internationalization";
import UnprocessedEntities from "./unprocessedEntities";

import { isEmpty } from "../lib/utils";

import { dispatchToProps } from "../store/user-actions";

class InviteManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "self",
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="tab-container">
          <Link
            onClick={() => this.setState({ activeTab: "self" })}
            className={"tab-header" + (this.state.activeTab === "self" ? "__active" : "")}
            to="/invite/self"
          >
            {Resources.SignupControl}
          </Link>
          <Link
            onClick={() => this.setState({ activeTab: "company" })}
            className={"tab-header" + (this.state.activeTab === "company" ? "__active" : "")}
            to="/invite/company"
          >
            {Resources.InviteCompany}
          </Link>
        </div>
        <Switch>
          <Route path="/invite/company" component={InviteCompany} />
          <Route path="/invite/self" component={SignupControl} />
          <Redirect to="/invite/self" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(InviteManagement);
