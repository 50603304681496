import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap'
import { dispatchToProps } from '../store/user-actions'
import { getConfig } from '../lib/utils'
import Resources from '../lib/resources'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    var config = getConfig()
    this.state = {
      usernameInput: config.userName || "",
      passwordInput: config.password || ""
    }
  }

  onInput = (e) =>
    this.setState({
      [e.target.id]: e.target.value
    })

  submitOnEnter = (e) => {
    if (e.key === "Enter") {
      this.submitLogin(e)
    }
  }

  submitLogin = (e) => {
    e.preventDefault()
    this.props.login(this.state.usernameInput, this.state.passwordInput)
  }

  render() {
    let { isLoggingIn, isLoggedIn } = this.props.user
    if (isLoggedIn === true) window.location = "/#/health"
    return ((isLoggingIn) ? <div className={`w-100 mt-5 text-center fa-8x fas fa-spinner`} /> :
      <Form
        className=""
        onSubmit={this.submitLogin}
        onKeyPress={this.submitOnEnter}
      >
        {(this.props.user.didLoginFail && <span className="warning-color-font">The username or password you entered is incorrect.</span>)}
        <FormGroup>
          <div className="text-center">
            {this.props.user.isLoginErrorVisable ? (
              <span className="text-danger">{Resources.UserLoginFailure}</span>
            ) : null}
          </div>
          <FormLabel for="username">{Resources.Username}</FormLabel>
          <FormControl
            type="username"
            name="username"
            value={this.state.usernameInput}
            onChange={this.onInput}
            id="usernameInput"
            placeholder="Username"
          />
        </FormGroup>
        <FormGroup>
          <FormLabel for="password">{Resources.Password}</FormLabel>
          <FormControl
            style={{ backgroundColor: "#FFFFFF" }}
            type="password"
            name="password"
            value={this.state.passwordInput}
            onChange={this.onInput}
            id="passwordInput"
            placeholder="password"
          />
        </FormGroup>
        <FormGroup className="text-center">
          {this.props.loginFooter === null ? (
            <div>
              <Button
                className="mr-5 w-25"
                onClick={() =>
                  this.props.login(
                    this.state.usernameInput,
                    this.state.passwordInput
                  )
                }
              >
                {Resources.SignIn}
              </Button>
              <Button className="ml-5 w-25" onClick={this.props.hideLoginModal}>
                {Resources.Cancel}
              </Button>
            </div>
          ) : (
              this.props.loginFooter(this.submitLogin)
            )}
        </FormGroup>
      </Form>
    )
  }
}

const storeToProps = store => {
  return {
    user: store.user
  }
}

export default connect(storeToProps, dispatchToProps)(LoginForm)
