import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Form, Button, Alert } from "react-bootstrap";
import { Route, Switch, NavLink } from "react-router-dom";

import ConnectorManager from "./connectorManager";
import SwitchButton from "./controls/switchButton";

import { dispatchToProps as cDP } from "../store/connector-actions";
import { dispatchToProps as rDP } from "../store/resource-actions";
import { dispatchToProps as hDP } from "../store/health-actions";

const dispatchToProps = dispatch => ({
  ...cDP(dispatch),
  ...rDP(dispatch),
  ...hDP(dispatch)
});

class ConnectorManagement extends Component {
  constructor(props, context) {
    super(props, context);

    this.renderConnectorList = this.renderConnectorList.bind(this);
    this.renderAddConnectorModal = this.renderAddConnectorModal.bind(this);
    this.handleCloseAddConnector = this.handleCloseAddConnector.bind(this);
    this.addConnector = this.addConnector.bind(this);
    this.uploadButton = this.uploadButton.bind(this);
    this.uploadIcon = this.uploadIcon.bind(this);
    this.updateConnectorDescription = this.updateConnectorDescription.bind(this);
    this.updateConnectorVersion = this.updateConnectorVersion.bind(this);
    this.addResource = this.addResource.bind(this);
    this.toggleSelectedResourceAction = this.toggleSelectedResourceAction.bind(this);
    this.toggleSelectedDefaultPermission = this.toggleSelectedDefaultPermission.bind(this);
    this.addLedgerHook = this.addLedgerHook.bind(this);
    this.updateLedgerHook = this.updateLedgerHook.bind(this);
    this.deleteLedgerHook = this.deleteLedgerHook.bind(this);
    this.addMapping = this.addMapping.bind(this);
    this.updateMapping = this.updateMapping.bind(this);

    this.state = {
      addingConnector: false,
      connector: {
        connectorName: ""
      }
    };
  }

  componentDidMount() {
    this.ensureConnectors(true);
  }

  componentDidUpdate() {
    this.ensureConnectors(false);
  }

  ensureConnectors(force) {
    if (
      force === true ||
      (this.props.connectors.fetchingConnectors === false &&
        this.props.connectors.fetchedConnectors !== true &&
        this.props.connectors.fetchConnectorsFailed !== true)
    ) {
      this.props.fetchConnectors();
    }

    if (
      force === true ||
      (this.props.resources.fetchingResources === false &&
        this.props.resources.fetchedResources !== true &&
        this.props.resources.fetchResourcesFailed !== true)
    ) {
      this.props.fetchResources();
    }

    if (
      force === true ||
      (this.props.health.fetchingHealth === false &&
        this.props.health.fetchedHealth !== true &&
        this.props.health.fetchHealthFailed !== true)
    ) {
      this.props.fetchHealth();
    }
  }

  addConnector() {
    this.props.addConnector(this.state.connector);
    this.setState({ addingConnector: false });
  }

  handleCloseAddConnector() {
    this.setState({
      addingConnector: false,
      connector: {
        connectorName: ""
      }
    });
  }

  setFlags(connectorId, flags) {
    this.props.setFlags(connectorId, flags);
  }

  uploadIcon(connectorId, file) {
    this.props.uploadIcon(connectorId, file);
  }

  uploadButton(connectorId, file) {
    this.props.uploadButton(connectorId, file);
  }

  updateConnectorDescription(connectorId, description) {
    this.props.updateConnectorDescription(connectorId, description);
  }

  updateConnectorVersion(connectorVersion) {
    this.props.updateConnectorVersion(connectorVersion);
  }

  addResource(resource) {
    this.props.addResource(resource);
  }

  toggleSelectedResourceAction(actions, resourceId, actionId) {
    if (actions.filter(ra => ra.actionId === actionId).length !== 0) {
      this.props.removeResourceAction(resourceId, actionId);
    } else {
      this.props.addActionToResource(resourceId, actionId);
    }
  }

  toggleSelectedDefaultPermission(resourceId, actionId, connectorId) {
    this.props.toggleSelectedDefaultPermission(resourceId, actionId, connectorId);
  }

  addLedgerHook(ledgerHook) {
    this.props.addLedgerHook(ledgerHook);
  }

  updateLedgerHook(ledgerHook) {
    this.props.updateLedgerHook(ledgerHook);
  }

  deleteLedgerHook(ledgerHook) {
    this.props.deleteLedgerHook(ledgerHook);
  }

  addMapping(mapping) {
    this.props.addMapping(mapping);
  }

  updateMapping(mapping) {
    this.props.updateMapping(mapping);
  }

  renderAddConnectorModal() {
    return (
      <Modal show={this.state.addingConnector} onHide={this.handleCloseAddConnector} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Connector</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.connector.connectorName ? (
            <Alert variant="success">
              👍 Looks good so far. Your connector identifiers will look like the following:
              <br />
              <br />
              <center>
                <pre>{this.state.connector.connectorName}:&lt;userCompanyId&gt;:&lt;identifier string&gt;</pre>
              </center>
            </Alert>
          ) : (
            <Alert variant="primary">
              👋 Hi there! Before we get started, make sure you have a company created for the connector.{" "}
              <NavLink to="/invite">Click here to create an invite for your new connector company.</NavLink>
            </Alert>
          )}
          <Form.Group>
            <Form.Label>Connector Name</Form.Label>
            <Form.Control
              value={this.state.connector.connectorName}
              onChange={e => {
                this.setState({ connector: { ...this.state.connector, connectorName: e.target.value } });
              }}
              placeholder="ConnectorName (no spaces, just alphanumeric)"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Company</Form.Label>
            <Form.Control
              as="select"
              value={this.state.connector.connectorId}
              onChange={e => {
                this.setState({ connector: { ...this.state.connector, companyId: e.target.value } });
              }}
            >
              {this.props.health.health.map(h => (
                <option key={h.companyId} value={h.companyId}>
                  {h.companyName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Display Name</Form.Label>
            <Form.Control
              value={this.state.connector.description}
              onChange={e => {
                this.setState({ connector: { ...this.state.connector, description: e.target.value } });
              }}
              placeholder="This is the public display name of the connector"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Version Match Regex</Form.Label>
            <Form.Control
              value={this.state.connector.connectorVersionMatch}
              onChange={e => {
                this.setState({ connector: { ...this.state.connector, connectorVersionMatch: e.target.value } });
              }}
              placeholder="Use a regular expression here, like .* or v1\.2\..*"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>OAuth Authorization Endpoint URL</Form.Label>
            <Form.Control
              value={this.state.connector.integrationEndpointUrl}
              onChange={e => {
                this.setState({ connector: { ...this.state.connector, integrationEndpointUrl: e.target.value } });
              }}
              placeholder="This is optional. If you need to redirect to your connector, put that URL here."
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseAddConnector}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.addConnector}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderConnectorList(connectors) {
    return (
      <React.Fragment>
        <div className="list-panel-title">
          Connectors
          <a className="list-add" onClick={() => this.setState({ addingConnector: true })}>
            <span class="fas fa-plus" /> Add Connector
          </a>
        </div>
        {this.renderAddConnectorModal()}
        {connectors.map(c => (
          <Row key={c.connectorId} className="list-panel-item" noGutters>
            <Col xs={4} sm={1} className="panel-connector-icon">
              {c.iconUrl ? <img src={c.iconUrl} className="connector-icon" /> : <span>No Icon</span>}
            </Col>
            <Col xs={12} sm={6} className="panel-connector-name">
              {c.description}
              <span className="panel-connector-unique-name">({c.connectorName})</span>
              <Row noGutters>
                <Col xs={12}>
                  <div className="panel-item-label">Connector Id</div>
                </Col>
                <Col xs={12}>
                  <pre className="panel-item-id">{c.connectorId}</pre>
                </Col>
              </Row>
            </Col>
            <Col xs={6} sm={2} className="panel-connector-toggle-internal">
              <div className="panel-item-heading">Internal</div>
              <SwitchButton
                value={c.isInternal}
                onClick={() => this.setFlags(c.connectorId, { isHidden: c.isHidden, isInternal: !c.isInternal })}
              />
            </Col>
            <Col xs={6} sm={2} className="panel-connector-toggle-internal">
              <div className="panel-item-heading">Hidden</div>
              <SwitchButton
                value={c.isHidden}
                onClick={() => this.setFlags(c.connectorId, { isHidden: !c.isHidden, isInternal: c.isInternal })}
              />
            </Col>
            <Col xs={12} sm={1}>
              <NavLink to={"/connectors/" + c.connectorId}>
                <span className="list-panel-button fas fa-arrow-right" />
              </NavLink>
            </Col>
          </Row>
        ))}
      </React.Fragment>
    );
  }

  render() {
    const connectors = this.props.connectors.connectors;
    const resources = this.props.resources.resources;
    const health = this.props.health.health;

    return (
      <div>
        <div className="panel-title">
          <h3>Connector Management</h3>
        </div>
        <div className="list-panel">
          {connectors.length !== 0 && resources.length !== 0 && health.length !== 0 ? (
            <Switch>
              <Route path="/connectors" exact render={() => this.renderConnectorList(connectors)} />
              <Route
                path="/connectors/:id"
                render={({ match }) => (
                  <ConnectorManager
                    connectors={connectors}
                    resources={resources}
                    companies={health}
                    uploadIcon={this.uploadIcon}
                    uploadButton={this.uploadButton}
                    updateConnectorDescription={this.updateConnectorDescription}
                    updateConnectorVersion={this.updateConnectorVersion}
                    addResource={this.addResource}
                    toggleSelectedResourceAction={this.toggleSelectedResourceAction}
                    toggleSelectedDefaultPermission={this.toggleSelectedDefaultPermission}
                    addLedgerHook={this.addLedgerHook}
                    updateLedgerHook={this.updateLedgerHook}
                    deleteLedgerHook={this.deleteLedgerHook}
                    addMapping={this.addMapping}
                    updateMapping={this.updateMapping}
                    match={match}
                  />
                )}
              />
            </Switch>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    connectors: store.connectors,
    resources: store.resources,
    health: store.health
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ConnectorManagement);
