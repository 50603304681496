import React, { Component } from 'react'
import { connect } from 'react-redux'

import TabularData from './tabularData'

import Resources from '../lib/resources'

import { dispatchToProps as uaDP } from '../store/user-actions'
import { dispatchToProps as erDP } from '../store/error-actions'
import moment from 'moment'

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...erDP(dispatch)
})


class ManageUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: ""
    }

    this.invokeSearch = this.invokeSearch.bind(this)
  }

  handleChange = e => {
    this.setState({
      filter: e.target.value
    })
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.invokeSearch()
    }
  }

  invokeSearch() {
    this.props.fetchUsers(this.state.filter)
  }

  loginAs(user) {
    this.props.impersonateUser(user.userId)
      .then(newAuth => {
        this.props.clearSession()
      })
  }

  render() {
    let users = []
    this.props.userStore.users
      .filter(u => {
        return (u.fullName || "").toLowerCase().includes(this.state.filter.toLowerCase()) ||
          (u.userName || "").toLowerCase().includes(this.state.filter.toLowerCase())
      })
      .forEach(u => {
        let user = { ...u }
        user.actions = <button onClick={() => { this.loginAs(user); }}>{Resources.LoginAs}</button>
        user.lastSeen = user.lastLoginTime != null ? moment(user.lastLoginTime).fromNow() : "Unknown";
        users.push(user)
      })
    return <div className="container-fluid">
      <hr />
      <div>
        <div className="col-1"></div><div className="col-11">
          <h3>{Resources.ManageUsers}</h3>
        </div>
      </div>
      <hr />

      <input className="w-20"
        placeholder={Resources.Search}
        value={this.state.filter}
        onChange={(e) => { this.handleChange(e) }}
        onKeyPress={this.onKeyPress} />
      <span onClick={this.invokeSearch} className="fas fa-search"></span>

      <hr />

      <TabularData
        data={users}
        format={{
          callBack(row) { },
          mapping: [
            {
              header: Resources.Username,
              width: '35%',
              source: 'userName'
            },
            {
              header: Resources.Name,
              width: '25%',
              source: 'fullName'
            },
            {
              header: Resources.Action,
              width: '20%',
              source: 'actions'
            },
            {
              header: "Last Signed In",
              width: '15%',
              source: 'lastSeen'
            }
          ]
        }}
      />

    </div>
  }

}

const storeToProps = (store) => {
  return {
    userStore: store.user,
    errorStore: store.error
  }
}

export default connect(storeToProps, dispatchToProps)(ManageUsers)
