import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

export const actions = {
  ...GeneralActions,
  ...{
    FETCHING_CONNECTORS: "FETCHING_CONNECTORS",
    FETCHED_CONNECTORS: "FETCHED_CONNECTORS",
    FETCH_CONNECTORS_FAILED: "FETCH_CONNECTORS_FAILED"
  }
};

const fetchConnectors = () => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_CONNECTORS });
  data
    .get(`v1/api/connector`)
    .then(response => {
      var connectors = response.data;
      Promise.all(
        connectors
          .flatMap(c => c.connectorVersions.map(cv => cv.connectorVersionId))
          .map(cvId =>
            data.get(`v1/api/connector/mapping/${cvId}`).then(mappingResponse => {
              return { mappings: mappingResponse.data, connectorVersionId: cvId };
            })
          )
      ).then(mappings => {
        connectors.sort((a, b) => {
          var x = a.connectorName.toLowerCase();
          var y = b.connectorName.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        dispatch({
          type: actions.FETCHED_CONNECTORS,
          connectors: connectors.map(c => {
            return {
              ...c,
              mappings: mappings
                .filter(
                  m => c.connectorVersions.filter(cv => cv.connectorVersionId === m.connectorVersionId).length > 0
                )
                .flatMap(m => m.mappings)
            };
          })
        });
      });
    })
    .catch(rejection => {
      console.log(rejection);
      dispatch({ type: actions.FETCH_CONNECTORS_FAILED, ...state, rejection });
      handlePromiseError(rejection);
    });
});

const addConnector = connector => ((dispatch, getState) => {
  data.put(`v1/api/connector`, connector).then(response => {
    data.put(`v1/api/connector/version`, { ...connector, connectorId: response.data.connectorId }).then(versionResponse => {
      dispatch(fetchConnectors());
    })
  });
});

const setFlags = (connectorId, flags) => ((dispatch, getState) => {
  data.post(`v1/api/connector/${connectorId}/flags`, flags).then(response => {
    dispatch(fetchConnectors());
  })
});

const uploadIcon = (connectorId, file) => ((dispatch, getState) => {
  var formData = new FormData();

  file.forEach(f => {
    formData.set("file", f);
  });

  data.post(`v1/api/connector/${connectorId}/icon`, formData).then(response => {
    dispatch(fetchConnectors());
  })
});

const uploadButton = (connectorId, file) => ((dispatch, getState) => {
  var formData = new FormData();
  
  file.forEach(f => {
    formData.set("file", f);
  });

  data.post(`v1/api/connector/${connectorId}/button`, formData).then(response => {
    dispatch(fetchConnectors());
  })
});

const updateConnectorDescription = (connectorId, description) => ((dispatch, getState) => {
  data.post(`v1/api/connector/${connectorId}`, JSON.stringify(description), { headers: { "Content-Type": "application/json" } }).then(response => {
    dispatch(fetchConnectors());
  })
});

const updateConnectorVersion = (connectorVersion) => ((dispatch, getState) => {
  data.post(`v1/api/connector/version/${connectorVersion.connectorVersionId}`, connectorVersion).then(response => {
    dispatch(fetchConnectors());
  });
});

const addResource = (resource) => ((dispatch, getState) => {
  data.put(`v1/api/resource`, resource).then(response => {
    Promise.all(resource.actions.map(a => addActionToResource(response.resourceId, a))).then(_ => {
      dispatch(fetchConnectors());
    });
  });
});

const addActionToResource = (resourceId, actionId) => ((dispatch, getState) => {
  data.put(`v1/api/resource/${resourceId}/actions`, JSON.stringify(actionId), { headers: { "Content-Type": "application/json" } }).then(response => {
  });
});

const removeResourceAction = (resourceId, actionId) => ((dispatch, getState) => {
  data.delete(`v1/api/resource/${resourceId}/actions/${actionId}`).then(response => {
    dispatch(fetchConnectors());
  });
});

const toggleSelectedDefaultPermission = (resourceId, actionId, connectorId) => ((dispatch, getState) => {
  data.post(`v1/api/resource/${resourceId}/actions/${actionId}/toggleDefault/${connectorId}`).then(response => {
    dispatch(fetchConnectors());
  });
});

const addLedgerHook = (ledgerHook) => ((dispatch, getState) => {
  data.put(`v2/api/ledger/hooks/${ledgerHook.connectorVersionId}/${ledgerHook.resourceId}/${ledgerHook.actionId}`, ledgerHook).then(response => {
    dispatch(fetchConnectors());
  });
});

const updateLedgerHook = (ledgerHook) => ((dispatch, getState) => {
  data.post(`v2/api/ledger/hooks/${ledgerHook.connectorVersionId}/${ledgerHook.resourceId}/${ledgerHook.actionId}`, ledgerHook).then(response => {
    dispatch(fetchConnectors());
  });
});

const deleteLedgerHook = (ledgerHook) => ((dispatch, getState) => {
  data.delete(`v2/api/ledger/hooks/${ledgerHook.connectorVersionId}/${ledgerHook.resourceId}/${ledgerHook.actionId}`).then(response => {
    dispatch(fetchConnectors());
  });
});

const addMapping = (mapping) => ((dispatch, getState) => {
  data.put(`v1/api/connector/mapping/${mapping.connectorVersionId}/${mapping.resourceId}/${mapping.toResourceId}`, mapping).then(response => {
    dispatch(fetchConnectors());
  });
});

const updateMapping = (mapping) => ((dispatch, getState) => {
  data.put(`v1/api/connector/mapping/${mapping.connectorVersionId}/${mapping.resourceId}/${mapping.toResourceId}`, mapping).then(response => {
    dispatch(fetchConnectors());
  });
});

export const dispatchToProps = dispatch => ({
  fetchConnectors: () => {
    dispatch(fetchConnectors());
  },
  addConnector: connector => {
    dispatch(addConnector(connector));
  },
  setFlags: (connectorId, flags) => {
    dispatch(setFlags(connectorId, flags));
  },
  uploadIcon: (connectorId, file) => {
    dispatch(uploadIcon(connectorId, file));
  },
  uploadButton: (connectorId, file) => {
    dispatch(uploadButton(connectorId, file));
  },
  updateConnectorDescription: (connectorId, description) => {
    dispatch(updateConnectorDescription(connectorId, description));
  },
  updateConnectorVersion: (connectorVersion) => {
    dispatch(updateConnectorVersion(connectorVersion));
  },
  addResource: (resource) => {
    dispatch(addResource(resource));
  },
  addActionToResource: (resourceId, actionId) => {
    dispatch(addActionToResource(resourceId, actionId));
  },
  removeResourceAction: (resourceId, actionId) => {
    dispatch(removeResourceAction(resourceId, actionId));
  },
  toggleSelectedDefaultPermission: (resourceId, actionId, connectorId) => {
    dispatch(toggleSelectedDefaultPermission(resourceId, actionId, connectorId));
  },
  addLedgerHook: (ledgerHook) => {
    dispatch(addLedgerHook(ledgerHook));
  },
  updateLedgerHook: (ledgerHook) => {
    dispatch(updateLedgerHook(ledgerHook));
  },
  deleteLedgerHook: (ledgerHook) => {
    dispatch(deleteLedgerHook(ledgerHook));
  },
  addMapping: (mapping) => {
    dispatch(addMapping(mapping));
  },
  updateMapping: (mapping) => {
    dispatch(updateMapping(mapping));
  }
});
