import React, { Component } from "react";
import { connect } from "react-redux";
import { dispatchToProps } from "../store/accounts-actions";
import TextInput from "./library/textInput";
import TabularData from "./tabularData";
import Resources from "../lib/resources";

class UnprocessedEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountKey: "",
      response: {},
      error: false,
    };
  }

  render() {
    return (
      <div className="p-4">
        <div className="unprocessed-input-container">
          <TextInput
            className="unprocessed-text-input"
            labelInline={false}
            label={Resources.AccountKey}
            textValue={this.state.accountKey}
            inputOnChange={(e) => {
              let value = e.target.value;
              this.setState({ accountKey: value });
            }}
          />
          <button
            onClick={() => {
              this.props.fetchUnprocessedEntities(this.state.accountKey).then((response) => {
                if (response !== undefined) {
                  this.setState({ response: response, error: false });
                } else {
                  this.setState({ error: true });
                }
              });
            }}
          >
            {Resources.Submit}
          </button>
        </div>
        {this.state.error ? (
          <div className="unnasigned-entities-error">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <span>Invalid account key</span>
          </div>
        ) : null}
        <TabularData
          data={this.state.response.value || []}
          format={{
            callBack(row) {},
            mapping: [
              {
                header: Resources.EntityName,
                width: "20%",
                source: "entityName",
              },
              {
                header: Resources.EntityType,
                width: "20%",
                source: "entityTypeId",
              },
              {
                header: Resources.EntityKeyInt,
                width: "20%",
                source: "entityKeyInt",
              },
              {
                header: Resources.DateInserted,
                width: "20%",
                source: "insertDate",
              },
              {
                header: Resources.LastUpdated,
                width: "20%",
                source: "updateDate",
              },
            ],
          }}
        />
      </div>
    );
  }
}

const storeToProps = (store) => {
  return {
    accountStore: store.accounts,
  };
};

export default connect(storeToProps, dispatchToProps)(UnprocessedEntities);
