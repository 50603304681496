import { actions } from "./invite-actions";

const defaultInviteState = {
  sendingInvite: false,
  sentInvite: false,
  sendInviteFailed: false,

  resendingInvite: {},
  resentInvite: {},
  resendInviteFailed: {},

  fetchingInvites: false,
  fetchedInvites: false,
  fetchInvitesFailed: false,
  invites: [],

  gettingSelfServiceStatus: false,
  gotSelfServiceStatus: false,
  failedGetSelfServiceStatus: false,
  selfServiceStatus: {},

  updatingSelfServiceStatus: false,
  updatedSelfServiceStatus: false,
  failedUpdateSelfServiceStatus: false,
};

const inviteReducer = (state = defaultInviteState, action) => {
  switch (action.type) {
    case actions.GET_INVITES:
      return { ...state, ...{ fetchingInvites: true, fetchedInvites: false, fetchInvitesFailed: false } };
    case actions.GET_INVITES_FAILED:
      return { ...state, ...{ fetchingInvites: false, fetchedInvites: false, fetchInvitesFailed: true } };
    case actions.GOT_INVITES:
      return {
        ...state,
        ...{ fetchingInvites: false, fetchedInvites: true, fetchInvitesFailed: false, invites: action.invites },
      };

    case actions.SEND_INVITE:
      return { ...state, ...{ sendingInvite: true, sentInvite: false, sendInviteFailed: false } };
    case actions.SEND_INVITE_FAILED:
      return { ...state, ...{ sendingInvite: false, sentInvite: false, sendInviteFailed: true } };
    case actions.SENT_INVITE:
      return { ...state, ...{ sendingInvite: false, sentInvite: true, sendInviteFailed: false } };

    case actions.RESEND_INVITE:
      return updateResendStatus(state, action.inviteId, true, false, false);
    case actions.RESEND_INVITE_FAILED:
      return updateResendStatus(state, action.inviteId, false, false, true);
    case actions.RESENT_INVITE:
      return updateResendStatus(state, action.inviteId, false, true, false);

    case actions.CLEAR_INVITE:
      return { ...state, ...{ sendingInvite: false, sentInvite: false, sendInviteFailed: false } };
    case actions.CLEAR_DATA:
      return defaultInviteState;

    case actions.GETTING_SELF_SERVICE_STATUS:
      return {
        ...state,
        gettingSelfServiceStatus: true,
        gotSelfServiceStatus: false,
        failedGetSelfServiceStatus: false,
      };
    case actions.GOT_SELF_SERVICE_STATUS:
      return {
        ...state,
        gettingSelfServiceStatus: false,
        gotSelfServiceStatus: true,
        failedGetSelfServiceStatus: false,
        selfServiceStatus: action.selfServiceStatus,
      };
    case actions.FAILED_GET_SELF_SERVICE_STATUS:
      return {
        ...state,
        gettingSelfServiceStatus: false,
        gotSelfServiceStatus: false,
        failedGetSelfServiceStatus: true,
      };
    case actions.UPDATING_SELF_SERVICE_STATUS:
      return {
        ...state,
        updatingSelfServiceStatus: true,
        updatedSelfServiceStatus: false,
        failedUpdateSelfServiceStatus: false,
      };
    case actions.UPDATED_SELF_SERVICE_STATUS:
      return {
        ...state,
        gettingSelfSupdatingSelfServiceStatuserviceStatus: false,
        updatedSelfServiceStatus: true,
        failedUpdateSelfServiceStatus: false,
        selfServiceStatus: action.selfServiceStatus,
      };
    case actions.FAILED_UPDATE_SELF_SERVICE_STATUS:
      return {
        ...state,
        updatingSelfServiceStatus: false,
        updatedSelfServiceStatus: false,
        failedUpdateSelfServiceStatus: true,
      };
    default:
      return state;
  }
};

function updateResendStatus(state, inviteId, resending, resent, failed) {
  let resendingInvite = { ...state.resendingInvite };
  let resentInvite = { ...state.resentInvite };
  let resendInviteFailed = { ...state.resendInviteFailed };

  resendingInvite[inviteId] = resending;
  resentInvite[inviteId] = resent;
  resendInviteFailed[inviteId] = failed;

  return { ...state, resendingInvite, resentInvite, resendInviteFailed };
}

export default inviteReducer;
