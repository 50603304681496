import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import "moment/min/locales.min";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";

import "./css/index.scss";

import store from "./store/store";
import Layout from "./components/layout";

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Layout />
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
