import { actions } from './internationalization-actions'

const defaultInternationalizationsState = {
  fetchingInternationalizations: false,
  fetchedInternationalizations: false,
  fetchInternationalizationsFailed: false,
  internationalizations: { en: { languageId: "en", value: [] } }
}

function internationalizationsReducer(state = defaultInternationalizationsState, action) {
  switch (action.type) {
    case actions.FETCHING_INTERNATIONALIZATIONS:
      return { ...state, ...action, fetchingInternationalizations: true, fetchedInternationalizations: false, fetchInternationalizationsFailed: false }
    case actions.FETCH_INTERNATIONALIZATIONS_FAILED:
      return { ...state, ...action, fetchingInternationalizations: false, fetchedInternationalizations: true, fetchInternationalizationsFailed: true }
    case actions.FETCHED_INTERNATIONALIZATIONS:
      return { ...state, ...action, fetchingInternationalizations: false, fetchedInternationalizations: true, fetchInternationalizationsFailed: false }
    default:
      return state
  }
}

export default internationalizationsReducer
