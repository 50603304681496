import { actions } from './health-actions'

const defaultHealthState = {
  fetchingHealth: false,
  fetchedHealth: false,
  fetchHealthFailed: false,
  health: []
}

function healthReducer(state = defaultHealthState, action) {
  switch (action.type) {
    case actions.FETCHING_HEALTH:
      return { ...state, ...action, fetchingHealth: true, fetchedHealth: false, fetchHealthFailed: false }
    case actions.FETCH_HEALTH_FAILED:
      return { ...state, ...action, fetchingHealth: false, fetchedHealth: true, fetchHealthFailed: true }
    case actions.FETCHED_HEALTH:
      return { ...state, ...action, fetchingHealth: false, fetchedHealth: true, fetchHealthFailed: false }
    default:
      return state
  }
}

export default healthReducer
