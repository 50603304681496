import util from "util";
import data from "./data";
import Resources from "./resources";
const onLocalHost = window.location.hostname === "localhost";

var config = require("../config/config.json");
if (!onLocalHost) {
  config.authEndpoint = window.location.origin.replace("hal", "auth"); // https://auth.hitch.network
  config.apiEndpoint = window.location.origin.replace("hal", "api");
  config.emailDomain = window.location.hostname.replace("hal.", "");
}

export function isLocalHost() {
  return onLocalHost;
}

export function replaceAt(arr, index, value) {
  return [...arr.slice(0, index), value, ...arr.slice(index + 1)];
}

export function removeAt(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function getConfig() {
  return { ...config };
}
export function isAdmin() {
  return config.isAdminMode;
}
export function toggleAdmin() {
  if (isLocalHost()) config.isAdminMode = !config.isAdminMode;
}
export function updateConfig(item) {
  config = { ...config, ...item };
}

export function isEmpty(item) {
  if (util.isNullOrUndefined(item)) return true;
  var type = typeof item;
  if (type === "object" && util.isArray(item)) type = "array";
  switch (type) {
    case "array":
      return item.length === 0;
    case "string":
      return item.trim().length === 0;
    case "object":
      for (var prop in item) {
        if (item.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(item) === JSON.stringify({});
    default:
      return false;
  }
}

export function getDisplaySize(sizeInBytes) {
  var size = sizeInBytes + 0;
  var kbSize = sizeInBytes / 1024;
  if (kbSize < 1) {
    return `${size}bytes`;
  }
  var mbSize = kbSize / 1024;
  if (mbSize < 1) {
    return `${kbSize.toFixed(0)}KB`;
  }
  var gbSize = mbSize / 1024;
  if (gbSize < 1) {
    return `${mbSize.toFixed(1)}MB`;
  }
  return `${gbSize.toFixed(2)}GB`;
}

function tryRemoveLeading(text, leading, insensitive) {
  var reText = text;
  var lcText = insensitive === true ? text.toLocaleLowerCase() : text;
  var replace = insensitive === true ? leading.toLocaleLowerCase() : leading;
  if (lcText.startsWith(replace)) return reText.substring(replace.length).trim();
  return reText;
}

export function getConversationSubject(subject) {
  var displaySubject = isEmpty(subject) ? Resources.NoSubject : subject.trim();
  var re = tryRemoveLeading(displaySubject, Resources.EmailRe, true);
  if (re !== displaySubject) return re;
  re = tryRemoveLeading(displaySubject, Resources.EmailFw, true);
  if (re !== displaySubject) return re;
  return displaySubject;
}

export function getRootPath() {
  return `${window.location.origin}${window.location.pathname || ""}`;
}

export function compareStrings(a, b) {
  return (a || "").toLowerCase().localeCompare((b || "").toLowerCase());
}

export function findIndex(array, match) {
  if (isEmpty(array)) {
    return -1;
  }
  let found = -1;
  for (let index = 0; index < array.length; index++) {
    let item = array[index];
    if (match(item)) {
      found = index;
      break;
    }
  }
  return found;
}

export function includes(item, value) {
  if (item !== 0 && !item) {
    return false;
  }
  return item.indexOf(value) >= 0;
}
export function uniqueId() {
  return Math.random().toString(36).substr(2, 16);
}

  
export async function encryptKey(groupKey, Key, value) {
  return data.post(`v2/api/for/${groupKey}/encyrpt/info`, [{ 'Key': Key, 'Value': value }])
    .then(res => {
      return res.data[0].value;
    })
    .catch(e => console.log('error occured', e));
}

export async function decryptKey(groupKey, Key, value) {
  return data.post(`v2/api/for/${groupKey}/decyrpt/info`, [{ 'Key': Key, 'Value': value }])
    .then(res => {
      return res.data[0].value;
    })
    .catch(e => {return});
}