import React, { Component } from 'react'
import { Table, InputGroup, FormControl, Form } from 'react-bootstrap'

class AccountList extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      accountFilter: ""
    }
  }

  render() {
    return <div className="account-list">
      <Table responsive="sm" striped>
        <thead>
          <tr>
            <th>
              <FormControl
                value={this.state.accountFilter}
                onChange={(e) => this.setState({accountFilter: e.target.value})}
                placeholder="Search accounts"/>
            </th>
            {this.props.featureGroups.map(group => {
              return group.features.map(feature => {
                return <th key={feature.featureId}>{feature.featureName}</th>
              })
            })}
          </tr>
        </thead>
        <tbody>
        {this.props.accountFeatures.filter(account => account.accountName.toLowerCase().includes(this.state.accountFilter)).sort((a,b) => a.accountName.toLowerCase() > b.accountName.toLowerCase() ? 1 : 0).map(account => {
            return <tr className="account-features" key={account.accountId}>
              <td className="account-title">
                {account.accountName}
              </td>
              {this.props.featureGroups.map(group => {
                return group.features.map(feature => {
                  const isChecked = account.accountFeatures.some((stf) => feature.featureId === stf.featureId);
                  const isFeatureDataDisabled = !isChecked;
                  const accountFeature = (account.accountFeatures.filter((stf) => feature.featureId === stf.featureId)[0] || {});
                  const featureData = accountFeature.featureData;

                  return <td className="account-feature" key={feature.featureId}>
                    {feature.featureType === 'String' ? 
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Checkbox
                            checked={isChecked}
                            onChange={(e) => this.props.setFeatureOnAccount(account, feature, e.target.checked)}/>
                        </InputGroup.Prepend>
                        <FormControl
                          disabled={isFeatureDataDisabled}
                          value={featureData}
                          onChange={(e) => this.props.updateFeatureOnAccount(account, feature, {...accountFeature, featureData: e.target.value})}/>
                      </InputGroup> :
                      <Form.Check 
                        type='checkbox'
                        checked={isChecked}
                        onChange={(e) => this.props.setFeatureOnAccount(account, feature, e.target.checked)}/>
                    }
                  </td>;
                });
              })}
            </tr>
        })}
        </tbody>
      </Table>
    </div>
  }
}

export default AccountList
