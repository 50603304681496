import React, { Component } from "react";

export default class MainLoader extends Component {
  render() {
    const spinner = <div className="full-screen-spinner" />;
    if (this.props.fullScreen === true) {
      return (
        <div className={`flex-center flex-align-center h-100 ${this.props.className ? this.props.className : ""}`}>
          {spinner}
        </div>
      );
    }

    return spinner;
  }
}
