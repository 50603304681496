import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import Dropzone from "react-dropzone";
import SwitchButton from "./controls/switchButton";

class ConnectorManager extends Component {
  constructor(props, context) {
    super(props, context);

    this.addResource = this.addResource.bind(this);
    this.toggleSelectedResourceAction = this.toggleSelectedResourceAction.bind(this);
    this.toggleSelectedDefaultPermission = this.toggleSelectedDefaultPermission.bind(this);
    this.handleCloseAddLedgerHook = this.handleCloseAddLedgerHook.bind(this);
    this.handleCloseEditLedgerHook = this.handleCloseEditLedgerHook.bind(this);
    this.handleCloseAddMapping = this.handleCloseAddMapping.bind(this);
    this.handleCloseAddResource = this.handleCloseAddResource.bind(this);
    this.handleCloseAddActionLink = this.handleCloseAddActionLink.bind(this);
    this.addLedgerHook = this.addLedgerHook.bind(this);
    this.updateLedgerHook = this.updateLedgerHook.bind(this);
    this.deleteLedgerHook = this.deleteLedgerHook.bind(this);
    this.addMapping = this.addMapping.bind(this);
    this.updateMapping = this.updateMapping.bind(this);

    const connectorId = props.match.params.id;
    const connector = (props.connectors || []).find(c => c.connectorId === connectorId) || {};
    const connectorVersions = connector.connectorVersions.sort((a, b) => {
      return a.createdDate > b.createdDate ? 1 : -1;
    });

    this.state = {
      companyId: connector.companyId || "",
      connectorId: connector.connectorId || "",
      connectorName: connector.connectorName || "",
      iconUrl: connector.iconUrl || "",
      description: connector.description || "",
      connectButtonUrl: connector.connectButtonUrl || "",
      connectorVersions: connectorVersions || [],
      mappings: connector.mappings || [],
      selectedConnectorVersion: connectorVersions.length > 0 ? connectorVersions[0] : {},
      selectedConfigurationSchema: {
        sectionName: "",
        schema: []
      },
      selectedResource: {},
      resource: {
        actions: []
      },
      ledgerHook: {
        endpointUrl: ""
      },
      mapping: {

      }
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  updateForm(state) {
    this.setState({ validated: true });
    this.setState(state);
  }

  updateConnector() {
    this.props.updateConnectorDescription(this.state.connectorId, this.state.description);
    this.props.updateConnectorVersion(this.state.selectedConnectorVersion);
  }

  handleCloseAddResource() {
    this.setState({ addingResource: false });
  }

  addResource() {
    this.props.addResource({ ...this.state.resource, connectorId: this.state.connectorId });
    this.setState({addingResource: false});
  }

  toggleResourceAction(actionId) {
    if (this.state.resource.actions.filter(a => a === actionId).length === 0) {
      this.setState({ resource: { ...this.state.resource, actions: [...this.state.resource.actions, actionId] } });
    } else {
      this.setState({ resource: { ...this.state.resource, actions: [...this.state.resource.actions.filter(a => a !== actionId)] } });
    }
  }

  toggleSelectedResourceAction(actions, resourceId, actionId) {
    this.props.toggleSelectedResourceAction(actions, resourceId, actionId);
    
    if (actions.filter(ra => ra.actionId === actionId).length !== 0) {
      this.setState({ selectedResource: { ...this.state.selectedResource, actions: [...this.state.selectedResource.actions.filter(a => a.actionId !== actionId)] }, selectedConnectorVersion: { ...this.state.selectedConnectorVersion, defaultPermissions: [...this.state.selectedConnectorVersion.defaultPermissions.filter(dp => dp.resourceId !== resourceId && dp.actionId !== actionId)] } });
    } else {
      this.setState({ selectedResource: { ...this.state.selectedResource, actions: [...this.state.selectedResource.actions, { actionId }] }, selectedConnectorVersion: { ...this.state.selectedConnectorVersion, defaultPermissions: [...this.state.selectedConnectorVersion.defaultPermissions, { resourceId, actionId }] } });
    }
  }

  toggleSelectedDefaultPermission(defaultPermissions, resourceId, actionId, connectorId) {
    this.props.toggleSelectedDefaultPermission(resourceId, actionId, connectorId);
    
    if (defaultPermissions.filter(dp => dp.resourceId === resourceId && dp.actionId === actionId).length !== 0) {
      this.setState({ selectedConnectorVersion: { ...this.state.selectedConnectorVersion, defaultPermissions: [...this.state.selectedConnectorVersion.defaultPermissions.filter(dp => dp.resourceId !== resourceId && dp.actionId !== actionId)] } });
    } else {
      this.setState({ selectedConnectorVersion: { ...this.state.selectedConnectorVersion, defaultPermissions: [...this.state.selectedConnectorVersion.defaultPermissions, { resourceId, actionId }] } });
    }
  }

  renderAddResourceModal(availableActions) {
    var perspectives = this.props.companies.filter(c => c.companyId === this.state.companyId).flatMap(c => c.perspectives);
    return <Modal show={this.state.addingResource} onHide={this.handleCloseAddResource}>
      <Modal.Header closeButton>
        <Modal.Title>Add Resource</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Resource Name</Form.Label>
          <Form.Control
            value={this.state.resource.resourceName}
            onChange={e => {
              this.setState({ resource: {...this.state.resource, resourceName: e.target.value }});
            }}
            placeholder="Resource Name"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            value={this.state.resource.description}
            onChange={e => {
              this.setState({ resource: {...this.state.resource, description: e.target.value }});
            }}
            placeholder="Description"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Perspective</Form.Label>
          <Form.Control
            as="select"
            value={this.state.resource.perspectiveId}
            onChange={e => {
              this.setState({ resource: {...this.state.resource, perspectiveId: e.target.value }});
            }}
          >
            <option value="">None</option>
            {perspectives.map(p => <option value={p.perspectiveId}>{p.perspectiveName.substring(0, 1).toUpperCase() + p.perspectiveName.substring(1)}</option>)}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          {availableActions.map(a =>
            <Form.Check type="checkbox" key={a.actionId} label={a.actionName} checked={this.state.resource.actions.filter(ra => ra == a.actionId).length == 1} onClick={() => this.toggleResourceAction(a.actionId)} />
          )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseAddResource}>
          Cancel
        </Button>
        <Button variant="primary" onClick={this.addResource}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  handleCloseAddLedgerHook() {
    this.setState({addingLedgerHook: false});
  }

  handleCloseEditLedgerHook() {
    this.setState({editingLedgerHook: false});
  }

  handleCloseAddMapping() {
    this.setState({addingMapping: false});
  }

  handleCloseEditMapping() {
    this.setState({editingMapping: false});
  }

  handleCloseAddActionLink() {
    this.setState({addingActionLink: false});
  }

  addLedgerHook() {
    this.props.addLedgerHook(this.state.ledgerHook);
    this.setState({addingLedgerHook: false, selectedResource: { ...this.state.selectedResource, ledgerHooks: [...this.state.selectedResource.ledgerHooks, this.state.ledgerHook]}});
  }

  updateLedgerHook() {
    this.props.updateLedgerHook(this.state.ledgerHook);
    this.setState({editingLedgerHook: false, selectedResource: { ...this.state.selectedResource, ledgerHooks: [...this.state.selectedResource.ledgerHooks.filter(lh => lh.resourceId !== this.state.ledgerHook.resourceId && lh.actionId !== this.state.ledgerHook.actionId), this.state.ledgerHook]}});
  }

  deleteLedgerHook() {
    this.props.deleteLedgerHook(this.state.ledgerHook);
    this.setState({editingLedgerHook: false, selectedResource: { ...this.state.selectedResource, ledgerHooks: [...this.state.selectedResource.ledgerHooks.filter(lh => lh.resourceId !== this.state.ledgerHook.resourceId && lh.actionId !== this.state.ledgerHook.actionId)]}});
  }

  addMapping() {
    this.props.addMapping(this.state.mapping);
    this.setState({addingMapping: false, mappings: [...this.state.mappings, this.state.mapping]})
  }

  updateMapping() {
    this.props.updateMapping(this.state.mapping);
    this.setState({editingMapping: false, mappings: [...this.state.mappings.filter(m => m.toResourceId !== this.state.mapping.toResourceId && m.resourceId !== this.state.mapping.resourceId), this.state.mapping]})
  }

  renderAddLedgerHookModal() {
    return <Modal show={this.state.addingLedgerHook} onHide={this.handleCloseAddLedgerHook} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Add Ledger Hook</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="primary">
          👋 The endpoint URL will be invoked when the corresponding action is made on the provided resource. You will receive the following POST request shape:<br/><br/>
          <pre>{"{\n" +
                "    \"companyId\" : \"guid-of-connected-company\",\n" +
                "    \"ledgerHash\" : \"the hash of the new ledger entry\"\n" +
                "}"}</pre>
        </Alert>
        <Form.Group>
          <Form.Label>Endpoint URL</Form.Label>
          <Form.Control
            value={this.state.ledgerHook.endpointUrl}
            onChange={e => {
              this.setState({ ledgerHook: {...this.state.ledgerHook, endpointUrl: e.target.value }});
            }}
            placeholder="Hook Endpoint URL"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseAddLedgerHook}>
          Cancel
        </Button>
        <Button variant="primary" onClick={this.addLedgerHook}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  renderEditLedgerHookModal() {
    return <Modal show={this.state.editingLedgerHook} onHide={this.handleCloseEditLedgerHook} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Edit Ledger Hook</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="primary">
          👋 The endpoint URL will be invoked when the corresponding action is made on the provided resource. You will receive the following POST request shape:<br/><br/>
          <pre>{"{\n" +
                "    \"companyId\" : \"guid-of-connected-company\",\n" +
                "    \"ledgerHash\" : \"the hash of the new ledger entry\"\n" +
                "}"}</pre>
        </Alert>
        <Form.Group>
          <Form.Label>Endpoint URL</Form.Label>
          <Form.Control
            value={this.state.ledgerHook.endpointUrl}
            onChange={e => {
              this.setState({ ledgerHook: {...this.state.ledgerHook, endpointUrl: e.target.value }});
            }}
            placeholder="Hook Endpoint URL"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseEditLedgerHook}>
          Cancel
        </Button>
        <Button variant="danger" onClick={this.deleteLedgerHook}>
          Delete
        </Button>
        <Button variant="primary" onClick={this.updateLedgerHook}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  renderAddMappingModal() {
    var ublResources = this.props.resources.filter(r => r.connectorId === null);
    return <Modal show={this.state.addingMapping} onHide={this.handleCloseAddMapping} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Add Mapping</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.mapping.direction === "from" ? 
          <Form.Group>
            <Form.Label>From Resource</Form.Label>
            <Form.Control
              as="select"
              value={this.state.mapping.resourceId}
              onChange={e => {
                this.setState({ mapping: {...this.state.mapping, resourceId: e.target.value }});
              }}
            >
              {ublResources.map(r => <option key={r.resourceId} value={r.resourceId}>{r.resourceName}</option>)}
            </Form.Control>
          </Form.Group>
        : <Form.Group>
            <Form.Label>To Resource</Form.Label>
            <Form.Control
              as="select"
              value={this.state.mapping.toResourceId}
              onChange={e => {
                this.setState({ mapping: {...this.state.mapping, toResourceId: e.target.value }});
              }}
            >
              {ublResources.map(r => <option key={r.resourceId} value={r.resourceId}>{r.resourceName}</option>)}
            </Form.Control>
          </Form.Group>
        }
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            value={this.state.mapping.versionDescription}
            onChange={e => {
              this.setState({ mapping: {...this.state.mapping, versionDescription: e.target.value }});
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Navigation Path</Form.Label>
          <Form.Control
            value={this.state.mapping.idNavigationPath}
            onChange={e => {
              this.setState({ mapping: {...this.state.mapping, idNavigationPath: e.target.value }});
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Mapping</Form.Label>
          <Form.Control
            as="textarea"
            value={this.state.mapping.mapping}
            onChange={e => {
              this.setState({ mapping: {...this.state.mapping, mapping: e.target.value }});
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseAddMapping}>
          Cancel
        </Button>
        <Button variant="primary" onClick={this.addMapping}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  renderEditMappingModal() {
    var ublResources = this.props.resources.filter(r => r.connectorId === null);
    return <Modal show={this.state.editingMapping} onHide={this.handleCloseEditMapping} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Edit Mapping</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.mapping.direction === "from" ? 
          <Form.Group>
            <Form.Label>From Resource</Form.Label>
            <Form.Control
              as="select"
              disabled
              value={this.state.mapping.resourceId}
              onChange={e => {
                this.setState({ mapping: {...this.state.mapping, resourceId: e.target.value }});
              }}
            >
              {ublResources.map(r => <option key={r.resourceId} value={r.resourceId}>{r.resourceName}</option>)}
            </Form.Control>
          </Form.Group>
        : <Form.Group>
            <Form.Label>To Resource</Form.Label>
            <Form.Control
              as="select"
              disabled
              value={this.state.mapping.toResourceId}
              onChange={e => {
                this.setState({ mapping: {...this.state.mapping, toResourceId: e.target.value }});
              }}
            >
              {ublResources.map(r => <option key={r.resourceId} value={r.resourceId}>{r.resourceName}</option>)}
            </Form.Control>
          </Form.Group>
        }
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            value={this.state.mapping.versionDescription}
            onChange={e => {
              this.setState({ mapping: {...this.state.mapping, versionDescription: e.target.value }});
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Navigation Path</Form.Label>
          <Form.Control
            value={this.state.mapping.idNavigationPath}
            onChange={e => {
              this.setState({ mapping: {...this.state.mapping, idNavigationPath: e.target.value }});
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Mapping</Form.Label>
          <Form.Control
            as="textarea"
            value={this.state.mapping.mapping}
            onChange={e => {
              this.setState({ mapping: {...this.state.mapping, mapping: e.target.value }});
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseEditMapping}>
          Cancel
        </Button>
        <Button variant="primary" onClick={this.updateMapping}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  renderAddActionLinkModal() {
    return <Modal show={this.state.addingActionLink} onHide={this.handleCloseAddActionLink} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Add Action Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            value={this.state.mapping.versionDescription}
            onChange={e => {
              this.setState({ mapping: {...this.state.mapping, versionDescription: e.target.value }});
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseAddActionLink}>
          Cancel
        </Button>
        <Button variant="primary" onClick={this.addActionLink}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>;
  }

  render() {
    // ConnectorConfigs has the CRUD actions we need.
    const availableActions = this.props.resources.find(
      r => r.resourceName === "ConnectorConfigs" && r.connectorId === null
    ).actions;

    return (
      <div>
        <div className="list-panel-title">
          <NavLink to="/connectors">
            <span className="list-panel-button fas fa-arrow-left" />
          </NavLink>
          {this.state.connectorName}
          <a className="list-add" onClick={() => this.updateConnector()}>
            <span class="fas fa-save"/> Save Changes
          </a>
        </div>
        {this.renderAddResourceModal(availableActions)}
        {this.renderAddLedgerHookModal()}
        {this.renderEditLedgerHookModal()}
        {this.renderAddMappingModal()}
        {this.renderEditMappingModal()}
        {this.renderAddActionLinkModal()}
        <Form onChange={e => e.currentTarget.checkValidity()} validated={this.validated}>
          <Row className="list-panel-item" noGutters>
            <Col xs={12} sm={8}>
              <Row noGutters>
                <Col xs={12} sm={6}>
                  <div className="panel-item-heading">Connector Name</div>
                  <div>{this.state.connectorName}</div>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="panel-item-input">
                    <div className="panel-item-label">Display Name</div>
                    <Form.Control
                      type="text"
                      placeholder="Display Name"
                      value={this.state.description}
                      onChange={e => this.updateForm({ description: e.target.value })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="panel-item-heading">Company Id</div>
                  <pre className="panel-item-id">{this.state.companyId}</pre>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={2}>
              <Dropzone
                multiple={false}
                onDrop={acceptedFiles => this.props.uploadIcon(this.state.connectorId, acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => {
                  return (
                    <section>
                      <div {...getRootProps()} className="connector-icon-upload">
                        {this.state.iconUrl ? (
                          <img alt="icon" src={this.state.iconUrl} />
                        ) : (
                          <p className="centered">
                            <br />
                            <span className="big-icon fas fa-paperclip inactive" />
                            <br />
                            <br />
                            <span className="inactive">
                              Drag a connector icon
                              <br />
                              into here to upload.
                            </span>
                          </p>
                        )}
                        <input {...getInputProps()} />
                      </div>
                    </section>
                  );
                }}
              </Dropzone>
            </Col>
            <Col xs={12} sm={2}>
              <Dropzone
                multiple={false}
                onDrop={acceptedFiles => this.props.uploadButton(this.state.connectorId, acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => {
                  return (
                    <section>
                      <div {...getRootProps()} className="connector-button-upload">
                        {this.state.connectButtonUrl ? (
                          <img alt="connect button" src={this.state.connectButtonUrl} />
                        ) : (
                          <p className="centered">
                            <br />
                            <span className="big-icon fas fa-paperclip inactive" />
                            <br />
                            <br />
                            <span className="inactive">Drag a connect button into here to upload.</span>
                          </p>
                        )}
                        <input {...getInputProps()} />
                      </div>
                    </section>
                  );
                }}
              </Dropzone>
            </Col>
          </Row>
          <Row className="list-panel-title" noGutters>
            <Col xs={12}>Connector Versions</Col>
          </Row>
          <Row className="list-panel-item-thin" noGutters>
            <Col xs={12} sm={12} md={3}>
              {this.state.connectorVersions.map(cv => (
                <Row
                  key={cv.connectorVersionId}
                  className={
                    "list-panel-item-condensed" +
                    (cv.connectorVersionId === this.state.selectedConnectorVersion.connectorVersionId
                      ? " list-panel-item-condensed-selected"
                      : "")
                  }
                  noGutters
                >
                  <Col xs={12}>{cv.description}</Col>
                </Row>
              ))}
            </Col>
            <Col xs={12} sm={12} md={9}>
              <Row className="list-panel-section" noGutters>
                <Col xs={12} sm={4}>
                  <div className="panel-item-heading">Connector Version ID</div>
                  <pre className="panel-item-id">{this.state.selectedConnectorVersion.connectorVersionId}</pre>
                </Col>
                <Col xs={12} sm={8}>
                  <Form.Group className="panel-item-input">
                    <div className="panel-item-label panel-item-input-right">Version Description</div>
                    <Form.Control
                      required
                      type="text"
                      className="panel-item-input-right"
                      placeholder="Version Description"
                      value={this.state.selectedConnectorVersion.description}
                      onChange={e =>
                        this.updateForm({
                          selectedConnectorVersion: {
                            ...this.state.selectedConnectorVersion,
                            description: e.target.value
                          }
                        })
                      }
                      isValid={this.state.selectedConnectorVersion.description.length > 0}
                      isInvalid={this.state.selectedConnectorVersion.description.length === 0}
                    />
                    <Form.Control.Feedback type="invalid" className="panel-item-input-right">
                      Please specify a name for the description with no spaces, alphanumeric characters only.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={11}>
                  <Form.Group className="panel-item-input">
                    <div className="panel-item-label">Connector Version Regex Match</div>
                    <Form.Control
                      required
                      type="text"
                      className="connector-company-id-input"
                      placeholder="Enter a Regular Expression Here"
                      value={this.state.selectedConnectorVersion.connectorVersionMatch}
                      onChange={e =>
                        this.updateForm({
                          selectedConnectorVersion: {
                            ...this.state.selectedConnectorVersion,
                            connectorVersionMatch: e.target.value
                          }
                        })
                      }
                      isValid={this.state.selectedConnectorVersion.connectorVersionMatch.length > 0}
                      isInvalid={this.state.selectedConnectorVersion.connectorVersionMatch.length === 0}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please specify a regular expression for the version.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={1}>
                  <div className="panel-item-heading">OAuth</div>
                  <SwitchButton
                    value={this.state.selectedConnectorVersion.configurationType === "Automatic"}
                    onClick={() =>
                      this.updateForm({
                        selectedConnectorVersion: {
                          ...this.state.selectedConnectorVersion,
                          configurationType:
                            this.state.selectedConnectorVersion.configurationType === "Automatic"
                              ? "Manual"
                              : "Automatic"
                        }
                      })
                    }
                  />
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      {this.state.selectedConnectorVersion.configurationType === "Automatic" ? (
                        <Form.Group className="panel-item-input">
                          <div className="panel-item-label panel-item-label-with-gutters">
                            OAuth Authorization Endpoint
                          </div>
                          <Form.Control
                            required
                            type="text"
                            className="connector-fullwidth-input"
                            placeholder="https://..."
                            value={this.state.selectedConnectorVersion.integrationEndpointUrl}
                            onChange={e =>
                              this.updateForm({
                                selectedConnectorVersion: {
                                  ...this.state.selectedConnectorVersion,
                                  integrationEndpointUrl: e.target.value
                                }
                              })
                            }
                            isValid={this.state.selectedConnectorVersion.integrationEndpointUrl.length > 0}
                            isInvalid={this.state.selectedConnectorVersion.integrationEndpointUrl.length === 0}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please specify a URL for the OAuth endpoint.
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : (
                        <>
                          <Row className="list-panel-title">
                            <Col xs={12}>Configuration Schema</Col>
                          </Row>
                          <Row className="list-panel-item-thin">
                            <Col xs={12} sm={3}>
                              {Object.keys(this.state.selectedConnectorVersion.configurationSchema).map(sectionName => (
                                <Row
                                  key={sectionName}
                                  className={
                                    "list-panel-item-condensed" +
                                    (this.state.selectedConfigurationSchema.sectionName === sectionName
                                      ? " list-panel-item-condensed-selected"
                                      : "")
                                  }
                                >
                                  <Col
                                    xs={12}
                                    onClick={() =>
                                      this.setState({
                                        selectedConfigurationSchema: {
                                          sectionName: sectionName,
                                          schema: this.state.selectedConnectorVersion.configurationSchema[sectionName]
                                        }
                                      })
                                    }
                                  >
                                    {sectionName}
                                  </Col>
                                </Row>
                              ))}
                            </Col>
                            <Col xs={12} sm={9}>
                              <Row className="list-panel-section-thin">
                                <Col xs={12}>
                                  {this.state.selectedConfigurationSchema.sectionName !== "" ? (
                                    this.state.selectedConfigurationSchema.schema.map(s => (
                                      <Row className="list-panel-item" noGutters>
                                        <Col xs={12} sm={3}>
                                          <Form.Group className="panel-item-input">
                                            <div className="panel-item-label">Key</div>
                                            <Form.Control
                                              required
                                              type="text"
                                              placeholder="Key"
                                              value={s.key}
                                              onChange={e =>
                                                this.updateForm({
                                                  selectedConfigurationSchema: {
                                                    ...this.state.selectedConfigurationSchema,
                                                    schema: [
                                                      ...this.state.selectedConfigurationSchema.schema.map(i =>
                                                        i.key === s.key ? { ...i, key: e.target.value } : i
                                                      )
                                                    ]
                                                  }
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                          <Form.Group className="panel-item-input">
                                            <div className="panel-item-label">Description</div>
                                            <Form.Control
                                              required
                                              type="text"
                                              placeholder="Description"
                                              value={s.description}
                                              onChange={e =>
                                                this.updateForm({
                                                  selectedConfigurationSchema: {
                                                    ...this.state.selectedConfigurationSchema,
                                                    schema: [
                                                      ...this.state.selectedConfigurationSchema.schema.map(i =>
                                                        i.key === s.key ? { ...i, description: e.target.value } : i
                                                      )
                                                    ]
                                                  }
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                          <Form.Group className="panel-item-input">
                                            <div className="panel-item-label">Navigation Path</div>
                                            <Form.Control
                                              required
                                              type="text"
                                              placeholder="Navigation.Path"
                                              value={s.navigationPath}
                                              onChange={e =>
                                                this.updateForm({
                                                  selectedConfigurationSchema: {
                                                    ...this.state.selectedConfigurationSchema,
                                                    schema: [
                                                      ...this.state.selectedConfigurationSchema.schema.map(i =>
                                                        i.key === s.key ? { ...i, navigationPath: e.target.value } : i
                                                      )
                                                    ]
                                                  }
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                          <Form.Group className="panel-item-input">
                                            <div className="panel-item-label">Type</div>
                                            <Form.Control
                                              required
                                              type="text"
                                              placeholder="Type"
                                              value={s.type}
                                              onChange={e =>
                                                this.updateForm({
                                                  selectedConfigurationSchema: {
                                                    ...this.state.selectedConfigurationSchema,
                                                    schema: [
                                                      ...this.state.selectedConfigurationSchema.schema.map(i =>
                                                        i.key === s.key ? { ...i, type: e.target.value } : i
                                                      )
                                                    ]
                                                  }
                                                })
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    ))
                                  ) : (
                                    <Row className="list-panel-item" noGutters>
                                      <Col xs={12}>
                                        <center>
                                          <h1>
                                            <span className="fas fa-frown-open" />
                                          </h1>
                                          <p>You do not currently have a schema selected. Pick one from the left.</p>
                                        </center>
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="list-panel-title" noGutters>
            <Col xs={12}>
              Resources and Actions
              <a className="list-add" onClick={() => this.setState({ addingResource: true })}>
                <span class="fas fa-plus"/> Add Resource
              </a>
            </Col>
          </Row>
          <Row className="list-panel-item-thin" noGutters>
            <Col xs={12} sm={3}>
              {this.props.resources
                .filter(
                  r =>
                    r.connectorId === this.state.connectorId ||
                    (r.connectorId === null && r.resourceName === "ConnectorConfigs")
                )
                .map(r => (
                  <Row
                    key={r.resourceId}
                    className={
                      "list-panel-item-condensed" +
                      (this.state.selectedResource.resourceId === r.resourceId
                        ? " list-panel-item-condensed-selected"
                        : "")
                    }
                    noGutters
                    onClick={() => this.setState({ selectedResource: r })}
                  >
                    <Col xs={12}>{r.resourceName}</Col>
                  </Row>
                ))}
            </Col>
            <Col xs={12} sm={9}>
              <Row className="list-panel-section-thin" noGutters>
                {this.state.selectedResource.resourceId && this.state.selectedConnectorVersion.connectorVersionId ? (
                  availableActions.map(a => (
                    <Col key={a.actionId} xs={12} md={3} className="list-panel-section-thin">
                      <div className="list-panel-center-title">{a.actionName}</div>
                      <Row noGutters>
                        <Col xs={2} />
                        <Col xs={3}>
                          <div className="panel-item-heading">Enabled</div>
                        </Col>
                        <Col xs={1} />
                        <Col xs={5}>
                          <div className="panel-item-heading">Auto Grant</div>
                        </Col>
                        <Col xs={2} />
                      </Row>
                      <Row className="list-panel-item-bottom">
                        <Col xs={2} />
                        <Col xs={3}>
                          <SwitchButton
                            value={this.state.selectedResource.actions.filter(ra => ra.actionId === a.actionId).length !== 0}
                            onClick={() => this.toggleSelectedResourceAction(this.state.selectedResource.actions, this.state.selectedResource.resourceId, a.actionId)}
                          />
                        </Col>
                        <Col xs={1} />
                        <Col xs={3}>
                          <SwitchButton
                            value={
                              this.state.selectedConnectorVersion.defaultPermissions.filter(
                                dp =>
                                  dp.actionId === a.actionId && dp.resourceId === this.state.selectedResource.resourceId
                              ).length !== 0
                            }
                            onClick={() => this.toggleSelectedDefaultPermission(this.state.selectedConnectorVersion.defaultPermissions, this.state.selectedResource.resourceId, a.actionId, this.state.connectorId)}
                          />
                        </Col>
                        <Col xs={3} />
                      </Row>
                      <Row className="list-panel-item-thin" noGutters>
                        <Col xs={12} className="resource-mappings">
                          <div className="panel-item-heading">Mappings</div>
                          <div>
                          {(() => {
                            var fromResource = this.state.mappings
                              .filter(m => m.toResourceId === this.state.selectedResource.resourceId)
                              .map(m =>
                                this.props.resources
                                  .filter(r => r.resourceId === m.resourceId)
                                  .map(r => <OverlayTrigger overlay={<Tooltip>Click to edit the input mapping.</Tooltip>}>
                                    <span className="connector-resource" onClick={() => this.setState({editingMapping: true, mapping: {...m, connectorVersionId: this.state.selectedConnectorVersion.connectorVersionId, direction: "from"}})}>UBL:{r.resourceName}</span>
                                  </OverlayTrigger>)
                              );
                            
                            if (fromResource.length > 0)
                            {
                              return fromResource;
                            }
                            else
                            {
                              return <OverlayTrigger overlay={<Tooltip>Click to add a mapped input UBL resource.</Tooltip>}>
                                  <span className="connector-resource-not-selected fas fa-question" onClick={() => this.setState({addingMapping: true, mapping: {connectorVersionId: this.state.selectedConnectorVersion.connectorVersionId, direction: "from", toResourceId: this.state.selectedResource.resourceId}})}/>
                              </OverlayTrigger>;
                            }
                          })()}
                          </div>
                          <div><span className="fas fa-caret-down"></span></div>
                          <div>
                            {(() => {
                              var hook = this.state.selectedResource.ledgerHooks.filter(lh => lh.actionId == a.actionId).map(lh => 
                                <OverlayTrigger overlay={<Tooltip>This resource action has a ledger hook. Click to edit.</Tooltip>}>
                                  <span className="connector-resource-target" onClick={() => this.setState({editingLedgerHook: true, ledgerHook: { ...lh, connectorVersionId: this.state.selectedConnectorVersion.connectorVersionId, resourceId: this.state.selectedResource.resourceId, actionId: a.actionId } })}>
                                    {this.state.selectedResource.resourceName + " "} <span className="fas fa-link"/>
                                  </span>
                                </OverlayTrigger>);

                              if (hook.length > 0) {
                                return hook;
                              } else {
                                return <OverlayTrigger overlay={<Tooltip>This resource action does not have a ledger hook. Click to add one.</Tooltip>}>
                                  <span className="connector-resource-target" onClick={() => this.setState({addingLedgerHook: true, ledgerHook: { connectorVersionId: this.state.selectedConnectorVersion.connectorVersionId, resourceId: this.state.selectedResource.resourceId, actionId: a.actionId}})}>
                                    {this.state.selectedResource.resourceName + " "} <span className="fas fa-unlink"/>
                                  </span>
                                </OverlayTrigger>;
                              }
                            })()}
                          </div>
                          <div><span className="fas fa-caret-down"></span></div>
                          <div>
                          {(() => {
                            var toResource = this.state.mappings
                            .filter(m => m.resourceId === this.state.selectedResource.resourceId)
                            .map(m =>
                              this.props.resources
                                .filter(r => r.resourceId === m.toResourceId)
                                .map(r => <OverlayTrigger overlay={<Tooltip>Click to edit the output mapping.</Tooltip>}>
                                  <span className="connector-resource" onClick={() => this.setState({editingMapping: true, mapping: {...m, connectorVersionId: this.state.selectedConnectorVersion.connectorVersionId, direction: "to"}})}>UBL:{r.resourceName}</span>
                                </OverlayTrigger>)
                            );
                            
                            if (toResource.length > 0)
                            {
                              return toResource;
                            }
                            else
                            {
                              return <OverlayTrigger overlay={<Tooltip>Click to add a mapped output UBL resource.</Tooltip>}>
                                  <span className="connector-resource-not-selected fas fa-question" onClick={() => this.setState({addingMapping: true, mapping: {direction: "to", connectorVersionId: this.state.selectedConnectorVersion.connectorVersionId, resourceId: this.state.selectedResource.resourceId}})}/>
                              </OverlayTrigger>;
                            }
                          })()}
                          </div>
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col xs={12} className="resource-mappings">
                          <div className="panel-item-heading">Action Links</div>
                          {this.state.selectedConnectorVersion.connectorActions
                            .filter(ca => 
                              (ca.actionId === a.actionId || (ca.actionId === null && a.actionName === "Read")) &&
                              (ca.resourceId === this.state.selectedResource.resourceId ||
                              this.state.mappings
                                .filter(m => m.toResourceId === ca.resourceId && m.resourceId === this.state.selectedResource.resourceId).length !== 0 ||
                              this.state.mappings
                                .filter(m => m.resourceId === ca.resourceId && m.toResourceId === this.state.selectedResource.resourceId).length !== 0))
                              .map(ca => <span key={ca.connectorActionId} className="connector-action">{ca.actionTitle}</span>)}
                          
                          <a className="list-add" onClick={() => this.setState({ addingActionLink: true, actionLink: { connectorVersionId: this.state.selectedConnectorVersion.connectorVersionId } })}>
                            <span class="fas fa-plus"/> Add Action Link
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  ))
                ) : (
                  <Col xs={12}>
                    <center>
                      <h1>
                        <span className="fas fa-frown-open" />
                      </h1>
                      <p>You do not currently have a resource selected. Pick one from the left.</p>
                    </center>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default ConnectorManager;
