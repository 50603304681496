import { actions } from './paymentMethods-actions'

const defaultPaymentMethodsState = {
  fetchingPaymentMethods: false,
  fetchedPaymentMethods: false,
  fetchPaymentMethodsFailed: false,
  oAuthIntegrationLoading: false,
  oAuthIntegrationUrl: "about:blank",
  oAuthInformation: {},
  paymentMethods: []
}

function paymentMethodsReducer(state = defaultPaymentMethodsState, action) {
  switch (action.type) {
    case actions.FETCHED_PAYMENT_METHODS:
      return { ...state, ...action, fetchingPaymentMethods: true, fetchedPaymentMethods: false, fetchPaymentMethodsFailed: false }
    case actions.FETCH_PAYMENT_METHODS_FAILED:
      return { ...state, ...action, fetchingPaymentMethods: false, fetchedPaymentMethods: true, fetchPaymentMethodsFailed: true, paymentMethods: [] }
    case actions.FETCHING_PAYMENT_METHODS:
      return { ...state, ...action, fetchingPaymentMethods: false, fetchedPaymentMethods: true, fetchPaymentMethodsFailed: false, paymentMethods: [] }
    case actions.UPDATED_PAYMENT_METHODS:
      return { ...state, ...action, fetchingPaymentMethods: true, fetchedPaymentMethods: false, fetchPaymentMethodsFailed: false }
    case actions.UPDATE_PAYMENT_METHODS_FAILED:
      return { ...state, ...action, fetchingPaymentMethods: false, fetchedPaymentMethods: true, fetchPaymentMethodsFailed: true, paymentMethods: [] }
    case actions.UPDATING_PAYMENT_METHODS:
      return { ...state, ...action, fetchingPaymentMethods: false, fetchedPaymentMethods: true, fetchPaymentMethodsFailed: false }
    case actions.BEGINNING_OAUTH_INTEGRATION:
    case actions.BEGINNING_OAUTH_INTEGRATION_FAILED:
    case actions.BEGAN_OAUTH_INTEGRATION:
    case actions.FETCHING_OAUTH_INFORMATION:
    case actions.FETCHED_OAUTH_INFORMATION:
    case actions.FETCH_OAUTH_INFORMATION_FAILED:
      return { ...state, ...action }
    default:
      return state
  }
}

export default paymentMethodsReducer
