import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Tab, Nav, Overlay, Popover } from "react-bootstrap";

import AceEditor from "react-ace";
import Dropzone from "react-dropzone";

import "brace/mode/handlebars";
import "brace/theme/monokai";
import "brace/snippets/handlebars";
import "brace/ext/language_tools";

import moment from "moment";

import Resources from "../lib/resources";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as scpDP } from "../store/script-actions"; //next up write this and all the fetches for the apis

const dispatchToProps = (dispatch) => ({
  ...uaDP(dispatch),
  ...scpDP(dispatch),
});

class ScriptManager extends Component {
  constructor(props, context) {
    super(props, context);

    this.switchTemplate = this.switchTemplate.bind(this);
    this.handleCloseConfirmUnsaved = this.handleCloseConfirmUnsaved.bind(this);
    this.dropAndSwitchTemplates = this.dropAndSwitchTemplates.bind(this);
    this.renderConfirmDeleteAttachment = this.renderConfirmDeleteAttachment.bind(this);
    this.handleCloseConfirmDeleteAttachment = this.handleCloseConfirmDeleteAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);

    this.state = {
      showUnsavedWarning: false,
      showAttachments: false,
      selectedAccount: "",
      selectedScript: { accountKey: "", scriptName: "" },
      selectedScriptName: "",
    };
  }

  componentDidMount() {
    this.ensureServiceEmailTemplates(true);
  }

  componentDidUpdate() {
    this.ensureServiceEmailTemplates(false);
  }

  ensureServiceEmailTemplates(force) {
    if (
      force === true ||
      (this.props.scripts.fetchingScripts === false &&
        this.props.scripts.fetchedScripts !== true &&
        this.props.scripts.failedFetchScripts !== true)
    ) {
      this.props.fetchScripts();
    }
  }

  switchTemplate(template) {
    if ((this.state.selectedAccount || {}).isUnsaved) {
      this.setState({ pendingTemplate: template, showUnsavedWarning: true });
    } else if (template === this.state.selectedAccount) {
      this.setState({
        selectedAccount: "",
      });
    } else {
      this.setState({ selectedAccount: template });
    }
  }

  handleCloseConfirmUnsaved() {
    this.setState({ pendingTemplate: {}, showUnsavedWarning: false });
  }

  dropAndSwitchTemplates() {
    this.setState({ selectedAccount: this.state.pendingTemplate, pendingTemplate: {}, showUnsavedWarning: false });
  }

  renderConfirmUnsaved() {
    return (
      <Modal show={this.state.showUnsavedWarning} onHide={this.handleCloseConfirmUnsaved}>
        <Modal.Header closeButton>
          <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have unsaved changes to {(this.state.selectedAccount || {}).serviceEmailTemplateName}. Are you sure you
          want to switch templates?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleCloseConfirmUnsaved}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.dropAndSwitchTemplates}>
            Drop Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleCloseConfirmDeleteAttachment() {
    this.setState({ showDeleteAttachment: null });
  }

  deleteAttachment() {
    const attachmentId = this.state.showDeleteAttachment.serviceEmailTemplateAttachmentId;
    const templateId = this.state.showDeleteAttachment.serviceEmailTemplateId;
    this.props.deleteServiceEmailTemplateAttachment(templateId, attachmentId);
    this.setState({ showDeleteAttachment: null });
  }

  renderConfirmDeleteAttachment() {
    return (
      <Modal show={this.state.showDeleteAttachment} onHide={this.handleCloseConfirmDeleteAttachment}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Attachment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {(this.state.showDeleteAttachment || {}).attachmentName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleCloseConfirmDeleteAttachment}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.deleteAttachment}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  filterScripts(scriptsArray) {
    let namesMap = {};
    scriptsArray
      .sort((a, b) => {
        if (a.createdDate > b.createdDate) {
          return -1;
        } else if (b.createdDate < a.createdDate) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((script) => {
        if (namesMap[script.scriptName] === undefined) {
          namesMap[script.scriptName] = [script];
        } else {
          namesMap[script.scriptName].push(script);
        }
      });
    return Object.values(namesMap);
  }

  render() {
    const allScripts = this.props.scripts.scripts;

    let accountsMap = {};
    let accounts = [];

    allScripts.map((script) => {
      if (accountsMap[script.accountKey] === undefined) {
        accountsMap[script.accountKey] = [script];
        accounts.push(script.accountKey);
      } else {
        accountsMap[script.accountKey].push(script);
      }
    });

    let template = this.state.selectedScript;

    return (
      <div>
        <div className="panel-title">
          <h3>{Resources.ScriptManagement}</h3>
        </div>
        {this.renderConfirmUnsaved()}
        {this.renderConfirmDeleteAttachment()}
        <Row noGutters>
          <Col xs={12} sm={12}>
            <div className="list-panel">
              <div className="list-panel-title scripts-title">Accounts</div>
              <Row noGutters>
                <Col xs={12} sm={3}>
                  {this.props.scripts.fetchedScripts ? (
                    <div className="accounts-script-explorer">
                      {accounts.map((set) => {
                        return (
                          <div>
                            <div
                              className={
                                "list-panel-item-condensed" +
                                (this.state.selectedAccount && this.state.selectedAccount === set
                                  ? " list-panel-item-condensed-selected"
                                  : "")
                              }
                              key={set}
                              onClick={() => this.switchTemplate(set)}
                            >
                              <div className="account-dropdown-button">
                                {this.state.selectedAccount && this.state.selectedAccount === set ? (
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                ) : (
                                  <i class="fa fa-caret-right" aria-hidden="true"></i>
                                )}
                                {set}
                              </div>
                            </div>
                            <div>
                              {this.state.selectedAccount && this.state.selectedAccount === set ? (
                                <div className="account-scripts-menu">
                                  {this.filterScripts(accountsMap[this.state.selectedAccount]).map((scripts) => {
                                    return (
                                      <div>
                                        <div
                                          onClick={() => {
                                            if (this.state.selectedScriptName === scripts[0].scriptName) {
                                              this.setState({ selectedScriptName: "" });
                                            } else {
                                              this.setState({ selectedScriptName: scripts[0].scriptName });
                                            }
                                          }}
                                          className={
                                            "list-panel-item-condensed" +
                                            (this.state.selectedScriptName === scripts[0].scriptName
                                              ? " list-panel-item-condensed-selected"
                                              : "")
                                          }
                                        >
                                          <div className="account-dropdown-button">
                                            {this.state.selectedScriptName &&
                                            this.state.selectedScriptName === scripts[0].scriptName ? (
                                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            ) : (
                                              <i class="fa fa-caret-right" aria-hidden="true"></i>
                                            )}
                                            {scripts[0].scriptName}
                                          </div>
                                        </div>
                                        <div className="script-archive-menu">
                                          {this.state.selectedScriptName &&
                                          this.state.selectedScriptName === scripts[0].scriptName
                                            ? scripts.map((script) => {
                                                return (
                                                  <div
                                                    onClick={() => {
                                                      this.setState({ selectedScript: script });
                                                    }}
                                                    className={
                                                      "list-panel-item-condensed" +
                                                      (this.state.selectedScript.accountPortalScriptKey ===
                                                      script.accountPortalScriptKey
                                                        ? " list-panel-item-condensed-selected"
                                                        : "")
                                                    }
                                                  >
                                                    {moment(script.createdDate).format("MMMM Do YYYY, h:mm:ss a")}
                                                    {/* <i
                                                    onClick={() => {
                                                      this.props.deleteScript(script.accountPortalScriptKey);
                                                    }}
                                                    class="fa fa-trash"
                                                    aria-hidden="true"
                                                  ></i> */}
                                                  </div>
                                                );
                                              })
                                            : null}
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <div
                                    className={
                                      "list-panel-item-condensed " +
                                      (this.state.selectedScript.scriptName === "" &&
                                      this.state.selectedScript.accountKey === this.state.selectedAccount
                                        ? "list-panel-item-condensed-selected"
                                        : null)
                                    }
                                  >
                                    <div
                                      onClick={() =>
                                        this.setState({
                                          selectedScript: {
                                            accountKey: this.state.selectedAccount,
                                            scriptName: "",
                                          },
                                          selectedScriptName: "",
                                        })
                                      }
                                      className="account-dropdown-button new-button"
                                    >
                                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                      <i>New Script</i>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                      {this.state.selectedAccount === "" ? (
                        <div
                          className={
                            "list-panel-item-condensed " +
                            (this.state.selectedScript.scriptName === "" &&
                            this.state.selectedScript.accountKey === this.state.selectedAccount
                              ? "list-panel-item-condensed-selected"
                              : null)
                          }
                        >
                          <div
                            onClick={() =>
                              this.setState({
                                selectedScript: {
                                  accountKey: "",
                                  scriptName: "",
                                },
                                selectedScriptName: "",
                              })
                            }
                            className="account-dropdown-button new-button"
                          >
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            <i>New Script</i>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="script-loader-container">
                      <div className="logo-spinner">
                        <img src="/img/lslogo.png" />
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={9}>
                  <Tab.Container defaultActiveKey="html" transition={false} unmountOnExit={true}>
                    <Row noGutters className="list-panel-item-toolbar">
                      <Col xs={12} sm={6}>
                        <span
                          title="Save"
                          className={"fas fa-save" + (template.isUnsaved ? "" : " inactive")}
                          onClick={() => {
                            this.props.saveScript(template);
                            this.setState({
                              selectedScript: {
                                ...template,
                                isUnsaved: false,
                              },
                            });
                          }}
                        />
                        <input
                          className="editor-name"
                          value={template.scriptName}
                          onChange={(e) =>
                            this.setState({
                              selectedScript: {
                                ...template,
                                isUnsaved: true,
                                scriptName: e.target.value,
                              },
                            })
                          }
                          placeholder="Script Name"
                        />
                        <input
                          className="editor-name"
                          value={template.accountKey}
                          onChange={(e) =>
                            this.setState({
                              selectedScript: {
                                ...template,
                                isUnsaved: true,
                                accountKey: e.target.value,
                              },
                            })
                          }
                          placeholder="Account Key"
                        />
                      </Col>
                    </Row>
                    <Tab.Content>
                      <Tab.Pane eventKey="html">
                        <AceEditor
                          placeholder=""
                          mode="handlebars"
                          theme="monokai"
                          fontSize={12}
                          onChange={(e) =>
                            this.setState({
                              selectedScript: {
                                ...template,
                                isUnsaved: true,
                                scriptText: e,
                              },
                            })
                          }
                          showPrintMargin={false}
                          showGutter={true}
                          highlightActiveLine={true}
                          width="100%"
                          height="750px"
                          value={(template || {}).scriptText}
                          setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2,
                          }}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const storeToProps = (store) => {
  return {
    user: store.user,
    scripts: store.scripts,
  };
};

export default connect(storeToProps, dispatchToProps)(ScriptManager);
