import { actions } from "./user-actions"
import jwt_decode from 'jwt-decode'
import { isEmpty, compareStrings } from '../lib/utils'

const defaultUserState = {
  isLoggedIn: false,
  isLoggingIn: false, isLoggingOut: false,
  gettingRoles: false, fetchedRoles: false, roles: [], isUser: false, isAdmin: false, isConnector: false,

  fetchingUsers: false, usersFilter: null, users: [], userLookup: {}
}

const userReducer = (state = defaultUserState, action) => {
  switch (action.type) {
    case actions.LOGGING_IN:
      return { ...state, ...{ isLoggingIn: true } }
    case actions.LOGIN_FAILED:
      return { ...state, ...{ isLoggingIn: false } }
    case actions.LOGGED_IN:
      var decoded = null
      if (action.token) decoded = jwt_decode(action.token)
      return { ...state, ...{ isLoggingIn: false, isLoggedIn: true, token: action.token, decoded: decoded } }

    case actions.LOGGING_OUT:
      return { ...state, ...{ isLoggingOut: true, isLoggedIn: false, token: null, decoded: null } }
    case actions.LOGOUT_FAILED:
      return { ...state, ...{ isLoggingOut: false } }
    case actions.LOGGED_OUT:
      return { ...state, ...{ isLoggingOut: false, isLoggedIn: false, token: null, decoded: null } }

    case actions.GETTING_ROLES:
      return { ...state, gettingRoles: true, fetchedRoles: false }
    case actions.GET_ROLES_FAILED:
      return { ...state, gettingRoles: false }
    case actions.GOT_ROLES:
      let roles = action.roles || []
      let isUser = !isEmpty(roles.find(role => { return role === 0 }))
      let isAdmin = !isEmpty(roles.find(role => { return role === 1 }))
      let isConnector = !isEmpty(roles.find(role => { return role === 2 }))
      return { ...state, ...{ gettingRoles: false, fetchedRoles: true, roles, isUser, isAdmin, isConnector } }

    case actions.FETCHING_USERS:
      let fetchingUsers = { ...state }
      if (state.usersFilter !== action.usersFilter) {
        fetchingUsers.users = []
        fetchingUsers.userLookup = {}
      }
      return { ...fetchingUsers, ...{ fetchingUsers: true, usersFilter: action.usersFilter } }
    case actions.FETCH_USERS_FAILED:
      if (state.usersFilter !== action.usersFilter) return state
      return { ...state, ...{ fetchingUsers: false } }
    case actions.FETCHED_USERS:
      if (state.usersFilter !== action.usersFilter) return state
      let users = [...action.users]
      users.sort((a, b) => {
        return compareStrings(a.fullName, b.fullName)
      })
      let userLookup = {}
      users.forEach(u => { userLookup[u.userId] = u })
      return { ...state, ...{ fetchingUsers: false, users, userLookup } }

    case actions.CLEAR_DATA:
      return defaultUserState
    default:
      return state;
  }
}

export default userReducer
