import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

export const actions = {
  ...GeneralActions,
  ...{
    FETCHING_ACCOUNTS: "FETCHING_ACCOUNTS",
    FETCHED_ACCOUNTS: "FETCHED_ACCOUNTS",
    FETCH_ACCOUNTS_FAILED: "FETCH_ACCOUNTS_FAILED",

    FETCHING_UNPROCESSED_ENTITIES: "FETCHING_UNPROCESSED_ENTITIES",
    FETCHED_UNPROCESSED_ENTITIES: "FETCHING_UNPROCESSED_ENTITIES",
    FAILED_FETCH_UNPROCESSED_ENTITIES: "FAILED_FETCH_UNPROCESSED_ENITITIES",
  },
};

const fetchUnprocessedEntities = (accountKey) => (dispatch) => {
  dispatch({ type: actions.FETCHING_UNPROCESSED_ENTITIES });
  return data
    .get(`v3/api/global/account/${accountKey}/unprocessedentities`)
    .then((response) => {
      dispatch({ type: actions.FETCHED_UNPROCESSED_ENTITIES, unprocessedList: response.data });
      return response.data;
    })
    .catch((rejection) => {
      dispatch({ type: actions.FAILED_FETCH_UNPROCESSED_ENTITIES, unprocessedList: [] });
      handlePromiseError(rejection);
    });
};

const fetchAccounts = () => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_ACCOUNTS });
  data
    .get(`v2/api/admin/accounts`)
    .then((response) => {
      dispatch({ type: actions.FETCHED_ACCOUNTS, accounts: response.data });
    })
    .catch((rejection) => {
      dispatch({ type: actions.FETCH_ACCOUNTS_FAILED, ...state, rejection });
      handlePromiseError(rejection);
    });
};

export const dispatchToProps = (dispatch) => ({
  fetchAccounts: () => {
    dispatch(fetchAccounts());
  },
  fetchUnprocessedEntities: (accountKey) => {
    return dispatch(fetchUnprocessedEntities(accountKey));
  },
});
