import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Resources from '../lib/resources'

class NavItem extends Component {
  render() {
    let isActive = (this.props.active) ? "isActiveLink" : ""

    return <NavLink onClick={this.props.onClick} to={this.props.to} className={isActive + " " + this.props.className + " sidebar-link"} >
      <span className={this.props.icon + " " + this.props.iconClassName} style={{ minWidth: "23px" }} /> {this.props.resource}
      {this.props.showSpinner && <span className={Resources.IconSpinner} />}
    </NavLink>
  }
}

export default NavItem;