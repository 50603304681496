import Axios from 'axios'

import { getConfig, getRootPath } from './utils'

export const authAxios = Axios.create({ headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '-1' } })

function defaultOptions() {
  var config = getConfig()
  var headers = {}
  if (config.AccessToken) { headers["Authorization"] = `Bearer ${config.AccessToken}` }
  return { headers: headers }
}

var returnUrl = `/connect/authorize?client_id=appid&redirect_uri=${getRootPath()}&response_type=code+id_token&nonce=${generateUUID()}&scope=openid+profile+api+offline_access`

function login(userName, password) {
  var data = {
    userName,
    password,
    returnUrl,
    rememberLogin: true
  }
  var ok = new Promise((resolve, reject) => {
    authAxios.defaults.withCredentials = true
    var promise = authAxios.post(`${getConfig().authEndpoint}/v1/user/login/authorize`, data)
    promise
      .then(result => {
        if (result && result.status !== 200) {
          reject(result)
        } else {
          resolve(result.data)
        }
      })
      .catch(result => {
        reject(result)
      })
  })
  return ok
}

function logout() {
  var ok = new Promise((resolve, reject) => {
    var promise = authAxios.post(`${getConfig().authEndpoint}/v1/user/logout`, {})
    promise
      .then(result => {
        if (result && result.status !== 200) {
          reject(result)
        } else {
          resolve(result)
        }
      })
      .catch(result => {
        reject(result)
      })
  })
  return ok
}

function createNewUser(payload) {
  var ok = new Promise((resolve, reject) => {
    var promise = authAxios.post(`${getConfig().authEndpoint}/v1/user/create`, payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    promise
      .then(result => {
        if (result && result.status !== 200) {
          reject(result)
        } else {
          resolve(result)
        }
      }).catch(result => {
        reject(result)
      })
  })
  return ok
}

function mergeOptions(options) {
  var def = defaultOptions()
  Object.keys(options || {}).forEach(key => {
    def[key] = { ...(def[key] || {}), ...options[key] }
  })
  return def
}

function get(url, options) {
  authAxios.defaults.withCredentials = true
  return authAxios.get(getConfig().authEndpoint + "/" + url, mergeOptions(options))
}

function deleteItem(url, options) {
  authAxios.defaults.withCredentials = true
  return authAxios.delete(getConfig().authEndpoint + "/" + url, mergeOptions(options))
}

function createItem(url, data, options) {
  authAxios.defaults.withCredentials = true
  return authAxios.post(getConfig().authEndpoint + "/" + url, data, mergeOptions(options))
}

function editItem(url, data, options) {
  authAxios.defaults.withCredentials = true
  return authAxios.put(getConfig().authEndpoint + "/" + url, data, mergeOptions(options))
}

function generateUUID() {
  var d = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
  return uuid;
}

export default {
  get,
  put: editItem,
  post: createItem,
  delete: deleteItem,
  login,
  logout,
  generateUUID,
  createNewUser
}
