import data from "../lib/data";
import { actions as GeneralActions } from './general-actions'
import { handlePromiseError } from './error-actions'

export const actions = {
  ...GeneralActions, ...{
    FETCHING_RESOURCES: "FETCHING_RESOURCES",
    FETCHED_RESOURCES: "FETCHED_RESOURCES",
    FETCH_RESOURCES_FAILED: "FETCH_RESOURCES_FAILED"
  }
};

const fetchResources = () => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_RESOURCES });
  data.get(`v1/api/resource/all`).then((response) => {
    var resources = response.data;
    resources.sort((a, b) => {
      var x = a.resourceName.toLowerCase();
      var y = b.resourceName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
    dispatch({ type: actions.FETCHED_RESOURCES, resources });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_RESOURCES_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

export const dispatchToProps = (dispatch) => ({
    fetchResources: () => { dispatch(fetchResources()); }
});
