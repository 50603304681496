import React, { Component } from "react";

class SwitchButton extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    render() {
        return <div className={"switch-button-container" + (this.props.value ? " switch-button-container-on" : " switch-button-container-off")} onClick={this.props.onClick} style={{ marginLeft: this.props.marginLeft ? this.props.marginLeft : '' }}>
            <div className={"switch-button" + (this.props.value ? " switch-button-on" : " switch-button-off")} />
        </div>;
    }
}

export default SwitchButton;