import util from 'util'
//import { handlePromiseError } from './error-actions'
import data from '../lib/data'
import auth from '../lib/auth'
import { getConfig, updateConfig, getRootPath } from '../lib/utils'
import { actions as GeneralActions } from './general-actions'

export const actions = {
  ...GeneralActions, ...{
    LOGGED_IN: 'LOGGED_IN',
    LOGGING_IN: 'LOGGING_IN',
    LOGIN_FAILED: 'LOGIN_FAILED',

    LOGGED_OUT: 'LOGGED_OUT',
    LOGGING_OUT: 'LOGGING_OUT',
    LOGOUT_FAILED: 'LOGOUT_FAILED',

    GETTING_ROLES: "GETTING_ROLES",
    GOT_ROLES: "GOT_ROLES",
    GET_ROLES_FAILED: "GET_ROLES_FAILED",

    FETCHING_USERS: 'FETCHING_USERS',
    FETCHED_USERS: 'FETCHED_USERS',
    FETCH_USERS_FAILED: 'FETCH_USERS_FAILED'
  }
}

const fetchRoles = () => ((dispatch) => {
  dispatch({ type: actions.GETTING_ROLES })
  data.get("v1/api/role")
    .then((response) => {
      dispatch({ type: actions.GOT_ROLES, roles: response.data })
    }).catch((response) => {
      dispatch({ type: actions.GET_ROLES_FAILED })
    })
})

const getRoles = () => ((dispatch, getState) => {
  var user = getState().user
  return user.roles
})

let LOGIN_PROMISE = null
const login = (userName, password) => ((dispatch, getState) => {
  var user = getState().user
  if (user.isLoggedIn || user.isLoggingIn) return LOGIN_PROMISE
  dispatch({ type: actions.LOGGING_IN })
  LOGIN_PROMISE = auth.login(userName, password).then((response) => {
    dispatch(loggedIn(response.identityToken))
    dispatch({ type: actions.LOGGED_IN, token: response.identityToken })
  }).catch((response) => {
    dispatch({ type: actions.LOGIN_FAILED })
    //handlePromiseError(response, "login failed")
  })
  return LOGIN_PROMISE
})

const loggedIn = (token) => ((dispatch, getState) => {
  window.sessionStorage.setItem("logged_in", "true")
  window.sessionStorage.setItem("id_token", token)
  updateConfig({ AccessToken: token })
  dispatch({ type: actions.LOGGED_IN, token: token })
})

const clearSession = () => {
  window.sessionStorage.removeItem("id_token")
  window.sessionStorage.removeItem("logged_in")
  updateConfig({ AccessToken: undefined })
}

let LOGOUT_PROMISE = null
const logout = () => ((dispatch, getState) => {
  var user = getState().user
  if (user.isLoggedOut || user.isLoggingOut) return LOGOUT_PROMISE

  clearSession()
  dispatch({ type: actions.CLEAR_DATA })

  dispatch({ type: actions.LOGGING_OUT })
  LOGOUT_PROMISE = auth.logout().then((response) => {
    dispatch({ type: actions.LOGGED_OUT })
  }).catch((response) => {
    dispatch({ type: actions.LOGOUT_FAILED })
    //handlePromiseError(response, "login failed")
  })

  window.location = `${getRootPath()}#/login`

  return LOGOUT_PROMISE
})

const fetchUsers = (filter) => ((dispatch, getState) => {
  var user = getState().user
  if (user.fetchingUsers) return
  dispatch({ type: actions.FETCHING_USERS, usersFilter: filter })
  auth.get("v1/user/list/all")
    .then((response) => {
      if (util.isString(response.data))
        dispatch({ type: actions.FETCH_USERS_FAILED, usersFilter: filter })
      else
        dispatch({ type: actions.FETCHED_USERS, users: response.data, usersFilter: filter })
    })
    .catch((response) => {
      dispatch({ type: actions.FETCH_USERS_FAILED, usersFilter: filter })
    })
})

const impersonateUser = (userId) => ((dispatch, getState) => {
  return auth.post("v1/user/impersonate", `"${userId}"`,
    { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      window.location = `${getConfig().authEndpoint.replace("auth", "app")}#id_token=${response.data.identityToken}`
      return null
    })
    .catch((response) => {
      //doh!
      return null
    })
})

export const dispatchToProps = (dispatch) => ({
  login: (userName, password) => { return dispatch(login(userName, password)) },
  logout: () => { return dispatch(logout()) },
  clearSession: () => { clearSession() },
  loggedIn: (token) => { dispatch(loggedIn(token)) },

  fetchRoles: () => { return dispatch(fetchRoles()) },
  getRoles: () => { return dispatch(getRoles()) },

  fetchUsers: (filter) => { dispatch(fetchUsers(filter)) },
  impersonateUser: (userId) => { return dispatch(impersonateUser(userId)) }
})
