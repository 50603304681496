import { actions } from './featureGroups-actions'

const defaultFeatureGroupsState = {
  fetchingFeatureGroups: false,
  fetchedFeatureGroups: false,
  fetchFeatureGroupsFailed: false,
  featureGroups: [],
  subscriptionTypes: [],
  accountFeatures: []
}

function featureGroupsReducer(state = defaultFeatureGroupsState, action) {
  switch (action.type) {
    case actions.FETCHING_FEATURE_GROUPS:
      return { ...state, ...action, fetchingFeatureGroups: true, fetchedFeatureGroups: false, fetchFeatureGroupsFailed: false }
    case actions.FETCH_FEATURE_GROUPS_FAILED:
      return { ...state, ...action, fetchingFeatureGroups: false, fetchedFeatureGroups: true, fetchFeatureGroupsFailed: true }
    case actions.FETCHED_FEATURE_GROUPS:
      return { ...state, ...action, fetchingFeatureGroups: false, fetchedFeatureGroups: true, fetchFeatureGroupsFailed: false }
    case actions.UPDATING_FEATURE_GROUP:
      return { ...state, ...action, updatingFeatureGroup: true, updatedFeatureGroup: false, updateFeatureGroupFailed: false }
    case actions.UPDATED_FEATURE_GROUP:
      return { ...state, ...action, updatingFeatureGroup: false, updatedFeatureGroup: true, updateFeatureGroupFailed: false }
    case actions.UPDATE_FEATURE_GROUP_FAILED:
      return { ...state, ...action, updatingFeatureGroup: false, updatedFeatureGroup: true, updateFeatureGroupFailed: true }
    default:
      return state
  }
}

export default featureGroupsReducer
