import { isEmpty } from './utils'
import Resources from './resources'

export const Perspectives = {
  AccountsPayable: "accounts_payable",
  AccountsReceivable: "accounts_receivable"
}

export const getPerspective = (type) => {
  let lcType = (isEmpty(type) ? "" : type).toLocaleLowerCase()
  switch (lcType) {
    case "ar":
      return Perspectives.AccountsReceivable
    case "ap":
    default:
      return Perspectives.AccountsPayable
  }
}

export const getDisplayPerspective = (perspective) => {
  switch (perspective) {
    case Perspectives.AccountsPayable:
    case "ap":
      return Resources.AccountsPayable
    case Perspectives.AccountsReceivable:
    case "ar":
      return Resources.AccountsReceivable
    default:
      return perspective
  }
}