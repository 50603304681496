import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Tab, Nav, Overlay, Popover } from "react-bootstrap";

import AceEditor from "react-ace";
import Dropzone from "react-dropzone";

import "brace/mode/handlebars";
import "brace/theme/monokai";
import "brace/snippets/handlebars";
import "brace/ext/language_tools";

import Resources from "../lib/resources";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as setDP } from "../store/serviceEmailTemplates-actions";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...setDP(dispatch)
});

class ServiceEmailTemplates extends Component {
  constructor(props, context) {
    super(props, context);

    this.switchTemplate = this.switchTemplate.bind(this);
    this.handleCloseConfirmUnsaved = this.handleCloseConfirmUnsaved.bind(this);
    this.dropAndSwitchTemplates = this.dropAndSwitchTemplates.bind(this);
    this.renderConfirmDeleteAttachment = this.renderConfirmDeleteAttachment.bind(this);
    this.handleCloseConfirmDeleteAttachment = this.handleCloseConfirmDeleteAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);

    this.state = {
      showUnsavedWarning: false,
      showAttachments: false,
      selectedTemplate: {
        serviceEmailTemplateName: "",
        htmlBody: "",
        textBody: "",
        isUnsaved: false
      }
    };
  }

  componentDidMount() {
    this.ensureServiceEmailTemplates(true);
  }

  componentDidUpdate() {
    this.ensureServiceEmailTemplates(false);
  }

  ensureServiceEmailTemplates(force) {
    if (
      force === true ||
      (this.props.serviceEmailTemplates.fetchingServiceEmailTemplates === false &&
        this.props.serviceEmailTemplates.fetchedServiceEmailTemplates !== true &&
        this.props.serviceEmailTemplates.fetchServiceEmailTemplatesFailed !== true)
    ) {
      this.props.fetchServiceEmailTemplates();
    }
  }

  switchTemplate(template) {
    if ((this.state.selectedTemplate || {}).isUnsaved) {
      this.setState({ pendingTemplate: template, showUnsavedWarning: true });
    } else {
      this.setState({ selectedTemplate: template });
    }
  }

  handleCloseConfirmUnsaved() {
    this.setState({ pendingTemplate: {}, showUnsavedWarning: false });
  }

  dropAndSwitchTemplates() {
    this.setState({ selectedTemplate: this.state.pendingTemplate, pendingTemplate: {}, showUnsavedWarning: false });
  }

  renderConfirmUnsaved() {
    return (
      <Modal show={this.state.showUnsavedWarning} onHide={this.handleCloseConfirmUnsaved}>
        <Modal.Header closeButton>
          <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have unsaved changes to {(this.state.selectedTemplate || {}).serviceEmailTemplateName}. Are you sure you
          want to switch templates?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleCloseConfirmUnsaved}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.dropAndSwitchTemplates}>
            Drop Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleCloseConfirmDeleteAttachment() {
    this.setState({ showDeleteAttachment: null });
  }

  deleteAttachment() {
    const attachmentId = this.state.showDeleteAttachment.serviceEmailTemplateAttachmentId;
    const templateId = this.state.showDeleteAttachment.serviceEmailTemplateId;
    this.props.deleteServiceEmailTemplateAttachment(templateId, attachmentId);
    this.setState({ showDeleteAttachment: null });
  }

  renderConfirmDeleteAttachment() {
    return (
      <Modal show={this.state.showDeleteAttachment} onHide={this.handleCloseConfirmDeleteAttachment}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Attachment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {(this.state.showDeleteAttachment || {}).attachmentName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.handleCloseConfirmDeleteAttachment}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.deleteAttachment}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    let template = this.state.selectedTemplate || {};
    let renderHtmlBody = template.htmlBody;
    
    if (template.htmlBody && template.htmlBody.indexOf("cid:") > 0 && template.serviceEmailTemplateId) {
      ((this.props.serviceEmailTemplates.serviceEmailTemplates || []).find(
          t => t.serviceEmailTemplateId === (template.serviceEmailTemplateId || "")
        ).attachments || []).forEach(a => {
        renderHtmlBody = renderHtmlBody.replace(new RegExp(`cid:\\{\\{${a.attachmentName}ContentId\\}\\}`, 'g'), a.attachmentURI);
      });
    }
    
    return (
      <div>
        <div className="panel-title">
          <h3>{Resources.ServiceEmailTemplates}</h3>
        </div>
        {this.renderConfirmUnsaved()}
        {this.renderConfirmDeleteAttachment()}
        <Row noGutters>
          <Col xs={12} sm={12}>
            <div className="list-panel">
              <div className="list-panel-title">Template Editor</div>
              <Row noGutters>
                <Col xs={12} sm={3}>
                  {this.props.serviceEmailTemplates.serviceEmailTemplates.map(set => {
                    return (
                      <div
                        className={
                          "list-panel-item-condensed" +
                          (this.state.selectedTemplate &&
                          this.state.selectedTemplate.serviceEmailTemplateId === set.serviceEmailTemplateId
                            ? " list-panel-item-condensed-selected"
                            : "")
                        }
                        key={set.serviceEmailTemplateId}
                        onClick={() => this.switchTemplate(set)}
                      >
                        <div>{set.serviceEmailTemplateName}</div>
                      </div>
                    );
                  })}
                  {!template.serviceEmailTemplateId ? (
                    <div className="list-panel-item-condensed list-panel-item-condensed-selected">
                      <div>{template.serviceEmailTemplateName || <i>Untitled Template</i>}</div>
                    </div>
                  ) : (
                    <div
                      className="list-panel-item-condensed"
                      onClick={() =>
                        this.switchTemplate({
                          serviceEmailTemplateName: "",
                          htmlBody: "",
                          textBody: "",
                          isUnsaved: false,
                          subject: ""
                        })
                      }
                    >
                      <div>
                        <i>New Template</i>
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={9}>
                  <Tab.Container defaultActiveKey="html" transition={false} unmountOnExit={true}>
                    <Row noGutters className="list-panel-item-toolbar">
                      <Col xs={12} sm={6}>
                        <span
                          title="Save"
                          className={"fas fa-save" + (template.isUnsaved ? "" : " inactive")}
                          onClick={() => {
                            this.props.saveServiceEmailTemplate(template);
                            this.setState({
                              selectedTemplate: {
                                ...template,
                                isUnsaved: false
                              }
                            });
                          }}
                        />
                        <input
                          className="editor-name"
                          value={template.serviceEmailTemplateName}
                          onChange={e =>
                            this.setState({
                              selectedTemplate: {
                                ...template,
                                isUnsaved: true,
                                serviceEmailTemplateName: e.target.value
                              }
                            })
                          }
                          placeholder="Template Name"
                        />
                        <span
                          title="Add Attachments"
                          className={
                            "editor-add-attachment fas fa-paperclip" +
                            (template.serviceEmailTemplateId ? "" : " inactive")
                          }
                          onClick={e =>
                            this.setState({ target: e.target, showAttachments: !this.state.showAttachments })
                          }
                        />
                        <Overlay
                          show={this.state.showAttachments}
                          target={this.state.target}
                          placement="bottom"
                          container={this}
                        >
                          <Popover title="Attachments">
                            {template.serviceEmailTemplateId ? (
                              <Dropzone
                                onDrop={acceptedFiles =>
                                  this.props.uploadServiceEmailTemplateAttachment(
                                    template.serviceEmailTemplateId,
                                    acceptedFiles
                                  )
                                }
                              >
                                {({ getRootProps, getInputProps }) => {
                                  const attachments =
                                    this.props.serviceEmailTemplates.serviceEmailTemplates.find(
                                      t => t.serviceEmailTemplateId === template.serviceEmailTemplateId
                                    ).attachments || [];
                                  return (
                                    <section>
                                      <div {...getRootProps()}>
                                        {attachments.length ? (
                                          <>
                                            {attachments.map(a => {
                                              return (
                                                <div
                                                  key={a.serviceEmailTemplateAttachmentId}
                                                  className="editor-attachment-image"
                                                  onClick={e => {
                                                    this.setState({
                                                      selectedTemplate: {
                                                        ...template,
                                                        htmlBody:
                                                          template.htmlBody +
                                                          `<img src="cid:{{${a.attachmentName}ContentId}}"/>`
                                                      },
                                                      showAttachments: false
                                                    });
                                                    e.stopPropagation();
                                                  }}
                                                >
                                                  <img
                                                    src={a.attachmentURI}
                                                    title={a.attachmentName}
                                                    alt={a.attachmentName}
                                                  />
                                                  <span
                                                    onClick={e => {
                                                      this.setState({ showDeleteAttachment: a });
                                                      e.stopPropagation();
                                                    }}
                                                    className="fas fa-trash invalid"
                                                  />
                                                </div>
                                              );
                                            })}
                                            <p className="centered">
                                              <br />
                                              <span className="inactive">
                                                Drag files into here to upload attachments. Click one to append an img
                                                tag in the HTML template.
                                              </span>
                                            </p>
                                          </>
                                        ) : (
                                          <p className="centered">
                                            <br />
                                            <span className="big-icon fas fa-paperclip inactive" />
                                            <br />
                                            <br />
                                            <span className="inactive">
                                              Drag files into here to upload attachments.
                                            </span>
                                          </p>
                                        )}
                                        <input {...getInputProps()} />
                                      </div>
                                    </section>
                                  );
                                }}
                              </Dropzone>
                            ) : (
                              <p className="centered">
                                <br />
                                <span className="big-icon fas fa-paperclip inactive" />
                                <br />
                                <br />
                                <span className="inactive">
                                  You cannot add attachments until you save the template.
                                </span>
                              </p>
                            )}
                          </Popover>
                        </Overlay>
                      </Col>
                      <Col xs={12} sm={6} className="list-panel-tabs">
                        <Nav className="editor-tabs" variant="tabs">
                          <Nav.Link eventKey="htmlPreview">HTML Preview</Nav.Link>
                          <Nav.Link eventKey="textPreview">Text Preview</Nav.Link>
                          <Nav.Link eventKey="subject">Subject</Nav.Link>
                          <Nav.Link eventKey="html">HTML</Nav.Link>
                          <Nav.Link eventKey="text">Text</Nav.Link>
                        </Nav>
                      </Col>
                    </Row>
                    <Tab.Content>
                      <Tab.Pane eventKey="htmlPreview" dangerouslySetInnerHTML={{ __html: renderHtmlBody }} />
                      <Tab.Pane eventKey="textPreview">
                        <pre>{template.textBody}</pre>
                      </Tab.Pane>
                      <Tab.Pane eventKey="subject">
                        <AceEditor
                          placeholder=""
                          mode="handlebars"
                          theme="monokai"
                          fontSize={12}
                          onChange={e =>
                            this.setState({
                              selectedTemplate: {
                                ...template,
                                isUnsaved: true,
                                subject: e
                              }
                            })
                          }
                          showPrintMargin={false}
                          showGutter={true}
                          highlightActiveLine={true}
                          width="100%"
                          value={template.subject}
                          setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2
                          }}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="html">
                        <AceEditor
                          placeholder=""
                          mode="handlebars"
                          theme="monokai"
                          fontSize={12}
                          onChange={e =>
                            this.setState({
                              selectedTemplate: {
                                ...template,
                                isUnsaved: true,
                                htmlBody: e
                              }
                            })
                          }
                          showPrintMargin={false}
                          showGutter={true}
                          highlightActiveLine={true}
                          width="100%"
                          value={template.htmlBody}
                          setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2
                          }}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="text">
                        <AceEditor
                          placeholder=""
                          mode="handlebars"
                          theme="monokai"
                          fontSize={12}
                          onChange={e =>
                            this.setState({
                              selectedTemplate: {
                                ...template,
                                isUnsaved: true,
                                textBody: e
                              }
                            })
                          }
                          showPrintMargin={false}
                          showGutter={true}
                          highlightActiveLine={true}
                          width="100%"
                          value={template.textBody}
                          setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2
                          }}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    serviceEmailTemplates: store.serviceEmailTemplates
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ServiceEmailTemplates);
