import data from "../lib/data";
import { actions as GeneralActions } from './general-actions'
import { handlePromiseError } from './error-actions'

export const actions = {
  ...GeneralActions, ...{
    FETCHING_BULK_SENDERS: "FETCHING_BULK_SENDERS",
    FETCHED_BULK_SENDERS: "FETCHED_BULK_SENDERS",
    FETCH_BULK_SENDERS_FAILED: "FETCH_BULK_SENDERS_FAILED"
  }
};

const fetchBulkSenders = () => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_BULK_SENDERS });
  data.get(`v1/api/bulksender`).then((response) => {
    var bulkSenders = response.data;
    bulkSenders.domains.sort((a, b) => {
      var x = a.bulkSenderDomainName.toLowerCase();
      var y = b.bulkSenderDomainName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    bulkSenders.emails.sort((a, b) => {
        var x = a.bulkSenderEmailAddress.toLowerCase();
        var y = b.bulkSenderEmailAddress.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });

    dispatch({ type: actions.FETCHED_BULK_SENDERS, ...bulkSenders });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_BULK_SENDERS_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const addBulkSender = (sender) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_BULK_SENDERS });
  data.put(`v1/api/bulksender`, JSON.stringify(sender), { headers: { "Content-Type": "application/json" } }).then((response) => {
    var bulkSenders = response.data;
    bulkSenders.domains.sort((a, b) => {
      var x = a.bulkSenderDomainName.toLowerCase();
      var y = b.bulkSenderDomainName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    bulkSenders.emails.sort((a, b) => {
        var x = a.bulkSenderEmailAddress.toLowerCase();
        var y = b.bulkSenderEmailAddress.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });

    dispatch({ type: actions.FETCHED_BULK_SENDERS, ...bulkSenders });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_BULK_SENDERS_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const deleteBulkSender = (sender) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_BULK_SENDERS });
  data.delete(`v1/api/bulksender/${encodeURIComponent(sender)}`).then((response) => {
    var bulkSenders = response.data;
    bulkSenders.domains.sort((a, b) => {
      var x = a.bulkSenderDomainName.toLowerCase();
      var y = b.bulkSenderDomainName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    bulkSenders.emails.sort((a, b) => {
        var x = a.bulkSenderEmailAddress.toLowerCase();
        var y = b.bulkSenderEmailAddress.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });

    dispatch({ type: actions.FETCHED_BULK_SENDERS, ...bulkSenders });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_BULK_SENDERS_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

export const dispatchToProps = (dispatch) => ({
  fetchBulkSenders: () => { dispatch(fetchBulkSenders()); },
  addBulkSender: (sender) => { dispatch(addBulkSender(sender)); },
  deleteBulkSender: (sender) => { dispatch(deleteBulkSender(sender)); }
});
