import React, { Component } from 'react'
import '../css/scrollbox.css'

class DefaultComponent extends Component {
  componentDidMount() {
    if (this.props.isRead) this.setState({ isRead: this.props.isRead })
    if (this.props.redirectUrl)
      this.setState({ redirectUrl: this.props.redirectUrl, isClickable: true })
    if (this.props.click) this.setState({ isClickable: true })
    if (this.props.isClickable) this.setState({ isClickable: true })
  }

  state = {
    isRead: false,
    isClickable: false
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{ cursor: this.state.isClickable ? "pointer" : "default" }}
      >
        <span className="m-0">
          {this.state.isRead ? <b>{this.props.item}</b> : this.props.item}
        </span>
        {this.props.isHRHidden === false && <hr className="mx-0 my-2 w-90" />}
      </div>
    )
  }
}

class TabularData extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pageData: [],
      bodyHeight: 500
    }
  }

  isArraysEqual = (a, b) => {
    return (
      a.length === b.length &&
      a.every(function (item, i) {
        return item === b[i]
      })
    )
  }

  setPageData = pageData => {
    if (!this.isArraysEqual(pageData, this.state.pageData)) {
      this.setState({ pageData: pageData })
    }
  }

  updateBodyHeight() {
    const bodyHeight =
      window.innerHeight - this.tableBody.getBoundingClientRect().top - 20
    if (this.state.bodyHeight !== bodyHeight) this.setState({ bodyHeight })
  }

  componentDidMount() {
    this.updateBodyHeight()
  }

  componentDidUpdate() {
    this.updateBodyHeight()
  }

  render() {
    let rangedData = this.props.data || []

    return (
      <div className="w-100 mx-auto">
        <div
          id="tableHeader"
          className="pb-3"
          style={{
            borderBottomColor: 'lightgrey',
            borderBottomWidth: '3px',
            borderBottomStyle: 'solid'
          }}
        >
          {this.props.format.mapping.map((item, i) => {
            return (
              <div
                key={i}
                className="d-inline-block p-0 m-0"
                style={{ width: item.width, borderBottom: { width: '2px' } }}
              >
                <b>{item.header}</b>
              </div>
            )
          })}
        </div>
        <div
          id="tableBody"
          className="scroll-box-wrap"
          ref={tableBody => (this.tableBody = tableBody)}
          style={{ height: `${this.state.bodyHeight}px` }}
        >
          <div
            className="scroll-box"
            style={{ height: `${this.state.bodyHeight}px` }}
          >
            {rangedData.map((row, i) => {
              /* row */
              return (
                <div
                  key={i}
                  className=""
                  onClick={() => this.props.format.callBack(row)}
                  style={{
                    borderBottomColor: 'lightgrey',
                    borderBottomWidth: '1px',
                    borderBottomStyle: 'solid',
                    fontWeight: `${row.unread === true ? 'bold' : 'normal'}`
                  }}
                >
                  {this.props.format.mapping.map((col, j) => {
                    /* column */
                    return (
                      <div
                        key={j}
                        className={'d-inline-block p-0 m-0 '}
                        style={{
                          width: col.width,
                          verticalAlign: '-webkit-baseline-middle'
                        }}
                      >
                        {col.comp ? (
                          React.cloneElement(col.comp, row[col.source])
                        ) : (
                            <DefaultComponent
                              item={row[col.source]}
                              {...col}
                            />
                          )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          <div className="cover-bar" />
        </div>
      </div>
    )
  }
}

export default TabularData
