import Axios from 'axios'

import { getConfig } from './utils'

export const dataAxios = Axios.create({ headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '-1' } })

function defaultOptions() {
  var config = getConfig()
  var headers = {}
  if (config.AccessToken) { headers["Authorization"] = `Bearer ${config.AccessToken}` }
  return { headers: headers }
}

function mergeOptions(options) {
  var def = defaultOptions()
  Object.keys(options || {}).forEach(key => {
    def[key] = { ...(def[key] || {}), ...options[key] }
  })
  return def
}

function get(url, options) {
  return dataAxios.get(getConfig().apiEndpoint + "/" + url, mergeOptions(options))
}

function deleteItem(url, options) {
  return dataAxios.delete(getConfig().apiEndpoint + "/" + url, mergeOptions(options))
}

function createItem(url, data, options) {
  return dataAxios.post(getConfig().apiEndpoint + "/" + url, data, mergeOptions(options))
}

function editItem(url, data, options) {
  return dataAxios.put(getConfig().apiEndpoint + "/" + url, data, mergeOptions(options))
}

export default {
  get,
  put: editItem,
  post: createItem,
  delete: deleteItem
}
