import { actions } from './error-actions'

function errorReducer (state = {
	errors: [], // { severity: "error", message: "error message", type: "error-type", field: "fieldname" }
  hasError: false,
  hasFieldError: false
  }, action) {
  let newErrorArray
	switch (action.type) {
    case actions.RAISE_ERROR:
      newErrorArray = state.errors.concat([action.error].filter((error) => state.errors.findIndex(e => JSON.stringify(e) === JSON.stringify(error)) < 0)) // de-duplication of errors.
      return {...state, errors: newErrorArray, hasError: newErrorArray.length > 0, hasFieldError: newErrorArray.find((err) => !!err.field) }
    case actions.CLEAR_ERROR:
      newErrorArray = state.errors.filter((error) => error.type !== action.errorType || (!!action.field && error.field !== action.field))
      return {...state, errors: newErrorArray, hasError: newErrorArray.length > 0, hasFieldError: newErrorArray.find((err) => !!err.field) }
    case actions.CLEAR_ERRORS:
			return {...state, errors: [], hasError: false, hasFieldError: false}
		default:
			return state
	}
}

export default errorReducer