import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isArray } from 'util';
import moment from 'moment'

import TabularData from './tabularData'

import Resources from '../lib/resources'
import { compareStrings } from '../lib/utils'

import { dispatchToProps as statDP } from '../store/stats-actions'
import { dispatchToProps as erDP } from '../store/error-actions'


const dispatchToProps = dispatch => ({
  ...statDP(dispatch),
  ...erDP(dispatch)
})

const timespans = [
  { timeString: "P1H", displayString: "1 hour" },
  { timeString: "P3H", displayString: "3 hours" },
  { timeString: "P6H", displayString: "6 hours" },
  { timeString: "P12H", displayString: "12 hours" },
  { timeString: "P1D", displayString: "1 day" },
]

const stattypes = [
  { statsType: "users", displayString: "Users" },
  { statsType: "companies", displayString: "Companies" },
  { statsType: "resources/all", displayString: "Resources" },
  { statsType: "emails/both", displayString: "Emails" },
  { statsType: "unprocessed", displayString: "Unprocessed Mail" },
  { statsType: "sessions", displayString: "Sessions" }
]

class UsageStatistics extends Component {

  componentDidMount() {
    this.tryUpdate()
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps)
  }

  tryUpdate(prevProps = { statsStore: {} }) {
    if (this.props.statsStore.timeString !== prevProps.statsStore.timeString ||
      this.props.statsStore.statsType !== prevProps.statsStore.statsType) {
      this.props.fetchStats()
    }
  }

  getDisplayStatsType(statsType) {
    return stattypes.find(st => { return st.statsType === statsType }).displayString
  }

  getDisplayTimeString(timeString) {
    return timespans.find(ts => { return ts.timeString === timeString }).displayString
  }

  render() {
    let { statsStore, statsStore: { timeString, statsType, stats } } = this.props
    let result = null
    let data = stats[statsType]
    let title = "These are not the droids you are looking for...";
    let mapping = []
    if (data) {
      switch (statsType) {
        case "users":
          title = `${data.length} Users visited the site in the past ${this.getDisplayTimeString(timeString)}`
          let arr = data.map(id => { return { id } })
          data = arr
          data.sort((a, b) => { return compareStrings(a.id, b.id) })
          mapping = [
            {
              header: "User Id",
              width: '40%',
              source: 'id'
            }
          ]
          break;
        case "companies":
          title = `${Object.keys(data).length} Companies used the site in the past ${this.getDisplayTimeString(timeString)}`
          data.sort((a, b) => { return compareStrings(a.companyName, b.companyName) })
          mapping = [
            {
              header: "Company Name",
              width: '35%',
              source: 'companyName'
            },
            {
              header: "Id",
              width: '40%',
              source: 'companyId'
            }
          ]
          break;
        case "resources/all":
          title = `Resources created or updated in the past ${this.getDisplayTimeString(timeString)}`
          result = <div>
            {Object.keys(data).sort().map(key => {
              let value = data[key]
              return <div key={key} className="row"><span className="col-2">{key}</span><span>{value}</span></div>
            })}
          </div>
          break;
        case "emails/both":
          title = `Emails coming in or going out in the past ${this.getDisplayTimeString(timeString)}`
          result = <div>
            {Object.keys(data).sort().map(key => {
              let value = data[key]
              return <div key={key} className="row"><span className="col-1">{key}</span><span>{value}</span></div>
            })}
          </div>
          break;
        case "sessions":
          title = `${data} sessions logged in the past ${this.getDisplayTimeString(timeString)}`
          break;
        case "unprocessed":
          title = `${Object.keys(data).length} unprocessed mail entries in the past ${this.getDisplayTimeString(timeString)}`
          data.forEach(datum => { datum.formattedDate = moment(datum.date).format('YYYY/MM/DD HH:mm:SS') })
          mapping = [
            {
              header: "S3Key",
              width: '40%',
              source: 'id'
            },
            {
              header: Resources.Date,
              width: '20%',
              source: 'formattedDate'
            }
          ]
          break;
        default:
          result = <div className="container-fluid">{JSON.stringify(data)}</div>
          break;
      }
    } else if (statsStore.fetchingStats) {
      result = <div className="container-fluid">Fetching your data...<span className="fas fa-spinner" /></div>
    }

    if (isArray(data)) {
      result = <TabularData
        data={data}
        format={{
          callBack(row) { },
          mapping: mapping
        }}
      />
    }

    result = <div>
      <h3>{title}</h3>
      {result}
    </div>

    return <div className="container-fluid">
      <hr />
      <div>
        <div className="col-1"></div><div className="col-11">
          <h3>{Resources.Statistics}</h3>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-2" style={{ borderRightStyle: "solid", borderRightWidth: "thin", borderRightColor: "gray" }}>
          {stattypes.map(st => {
            return <div key={st.statsType} className={`btn btn-${st.statsType === statsType ? "gray" : "light"} col-12`} style={{ cursor: "default" }} onClick={(e) => { this.props.setStatsType(st.statsType) }}>{st.displayString}</div>
          })}
          <hr />
          {timespans.map(ts => {
            return <div key={ts.timeString} className={`btn btn-${ts.timeString === timeString ? "gray" : "light"} col-12`} style={{ cursor: "default" }} onClick={(e) => { this.props.setTimestring(ts.timeString) }}>{ts.displayString}</div>
          })}
          <hr />
          <div className="btn btn-default center fas fa-redo-alt col-12" onClick={this.props.fetchStats} />
        </div>
        <div className="col-10">{result}</div>
      </div>
    </div>
  }
}

const storeToProps = (store) => {
  return {
    statsStore: store.stats,
    errorStore: store.error
  }
}

export default connect(storeToProps, dispatchToProps)(UsageStatistics)
