import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import qs from 'qs'

import App from './app'
import Login from './login'

import { getRootPath } from '../lib/utils'

import store from '../store/store'
import { dispatchToProps as UserDispatch } from '../store/user-actions'
var userDispatch = UserDispatch(store.dispatch)

export default class Layout extends Component {
  componentWillMount() {
    if (window.location.hash && window.location.hash !== "") {
      let res = qs.parse(window.location.hash.replace(/^#\/?/, ''))
      if (res.id_token) {
        window.sessionStorage.setItem("id_token", res.id_token)
        userDispatch.loggedIn(res.id_token)
        window.location = `${getRootPath()}`
      }
    }

    if (window.sessionStorage.getItem('logged_in')) {
      let idToken = window.sessionStorage.getItem('id_token')
      var dateNow = new Date()
      if (idToken) {
        let jwt = jwt_decode(idToken)
        if (jwt.exp >= dateNow.getTime() / 1000) {
          userDispatch.loggedIn(idToken)
        } else {
          userDispatch.logout()
        }
      }
    }
  }

  render() {
    return <Switch>
      <Redirect exact from="/" to="/login" component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/" component={App} />
    </Switch>
  }
}
