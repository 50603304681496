import { getString, formatString } from "./strings";

class Resources {
  /// CRUD
  get Add() {
    return getString("ADD", "Add");
  }
  get Edit() {
    return getString("EDIT", "Edit");
  }
  get Delete() {
    return getString("DELETE", "Delete");
  }

  /// Action\Responses
  get Ok() {
    return getString("OK", "Ok");
  }
  get Cancel() {
    return getString("CANCEL", "Cancel");
  }
  get Close() {
    return getString("CLOSE", "Close");
  }

  get Yes() {
    return getString("YES", "Yes");
  }
  get No() {
    return getString("NO", "No");
  }

  get Save() {
    return getString("SAVE", "Save");
  }
  get Submit() {
    return getString("SUBMIT", "Submit");
  }
  get Send() {
    return getString("SEND", "Send");
  }

  get Preview() {
    return getString("PREVIEW", "Preview");
  }
  get View() {
    return getString("VIEW", "View");
  }

  get Select() {
    return getString("SELECT", "Select");
  }
  get Clear() {
    return getString("CLEAR", "Clear");
  }

  get Upload() {
    return getString("UPLOAD", "Upload");
  }
  get Search() {
    return getString("SEARCH", "Search");
  }
  get Resend() {
    return getString("RESEND", "Resend");
  }
  get Resending() {
    return getString("RESENDING", "Resending");
  }
  get Resent() {
    return getString("RESENT", "Resent");
  }
  get Retry() {
    return getString("RETRY", "Retry");
  }
  get Refresh() {
    return getString("REFRESH", "Refresh");
  }

  get Hide() {
    return getString("HIDE", "Hide");
  }
  get Show() {
    return getString("SHOW", "Show");
  }

  /// areas
  get Reports() {
    return getString("REPORTS", "Reports");
  }
  get Manage() {
    return getString("MANAGE", "Manage");
  }
  get Accounts() {
    return getString("ACCOUNTS", "Accounts");
  }
  get Company() {
    return getString("COMPANY", "Company");
  }

  get Pending() {
    return getString("PENDING", "Pending");
  }
  get Accepted() {
    return getString("ACCEPTED", "Accepted");
  }
  get Refreshing() {
    return getString("REFRESHING", "Refreshing");
  }

  get Dates() {
    return getString("DATES", "Dates");
  }
  get Property() {
    return getString("PROPERTY", "Property");
  }
  get Value() {
    return getString("VALUE", "Value");
  }
  get All() {
    return getString("ALL", "All");
  }

  get Help() {
    return getString("HELP", "Help");
  }
  get SignIn() {
    return getString("SIGN_IN", "Sign In");
  }
  get SigningIn() {
    return getString("SIGNING_IN", "Signing In");
  }
  get Logout() {
    return getString("LOGOUT", "Logout");
  }
  get Settings() {
    return getString("SETTINGS", "Settings");
  }
  get Home() {
    return getString("HOME", "Home");
  }
  get HomeTitle() {
    return getString("HOME_TITLE", "Welcome to Lockstep!");
  }
  get LoginTitle() {
    return getString("LOGIN_TITLE", "Login");
  }
  get Login() {
    return getString("LOGIN", "Login");
  }

  get UnknownSources() {
    return getString("UKNOWN_SOURCES", "Unknown Sources");
  }
  get SortBy() {
    return getString("SORT_BY", "Sort By");
  }
  get Name() {
    return getString("NAME", "Name");
  }
  get MostRecent() {
    return getString("MOST_RECENT", "Most Recent");
  }
  get Filter() {
    return getString("FILTER", "Filter");
  }

  get UserLoginFailure() {
    return getString("USER_LOGIN_FAILURE", "Login invalid, Please try again.");
  }

  get AccountInformation() {
    return getString("ACCOUNT_INFORMATION", "Account Information");
  }

  get CompanyInformation() {
    return getString("COMPANY_INFORMATION", "Company Information");
  }
  get CompanyName() {
    return getString("COMPANY_NAME", "Company Name");
  }

  /// invite company
  get FirstName() {
    return getString("FIRST_NAME", "First Name:");
  }
  get LastName() {
    return getString("LAST_NAME", "Last Name:");
  }
  get Email() {
    return getString("EMAIL", "E-mail:");
  }

  get EmailInvitationHeader() {
    return getString("EMAIL_INVITATION_HEADER", "E-mail Invitation");
  }
  get Message() {
    return getString("MESSAGE", "Message");
  }

  get APEmail() {
    return getString("AP_EMAIL", "AP E-mail:");
  }
  get AREmail() {
    return getString("AR_EMAIL", "AR E-mail:");
  }
  get APPhone() {
    return getString("AP_PHONE", "AP Phone:");
  }
  get ARPhone() {
    return getString("AR_PHONE", "AR Phone:");
  }
  get EntityType() {
    return getString("ENTITY_TYPE", "Entity Type:");
  }

  get CompanyInfoAddress() {
    return getString("COMPANY_INFO_ADDRESS", "Address:");
  }
  get CompanyInfoCity() {
    return getString("COMPANY_INFO_CITY", "City:");
  }
  get CompanyInfoState() {
    return getString("COMPANY_INFO_STATE", "State/Province:");
  }
  get CompanyInfoZip() {
    return getString("COMPANY_INFO_ZIP", "Postal Code:");
  }
  get CompanyInfoCountry() {
    return getString("COMPANY_INFO_COUNTRY", "Country:");
  }
  get CompanyInfoUrl() {
    return getString("COMPANY_INFO_URL", "URL:");
  }
  get CompanyInfoTIN() {
    return getString("COMPANY_INFO_TIN", "TIN:");
  }
  get CompanyInfoW9() {
    return getString("COMPANY_INFO_W9", "W9 File:");
  }
  get CompanyInfoW8() {
    return getString("COMPANY_INFO_W8", "W8 BEN File:");
  }

  get EmailRe() {
    return getString("EMAIL_RE", "RE:");
  }
  get EmailFw() {
    return getString("EMAIL_FW", "FW:");
  }

  get Lockstep() {
    return getString("LOCKSTEP", "Lockstep");
  }

  get Previous() {
    return getString("PREVIOUS", "Previous");
  }
  get Next() {
    return getString("NEXT", "Next");
  }

  get IconSpinner() {
    return getString("ICON_SPINNER", "fas fa-spinner");
  }

  get Subject() {
    return getString("SUBJECT", "Subject");
  }
  get NoSubject() {
    return getString("NO_SUBJECT", "(No subject)");
  }

  get Date() {
    return getString("DATE", "Date");
  }
  get Status() {
    return getString("STATUS", "Status");
  }
  get Action() {
    return getString("ACTION", "Action");
  }

  get Password() {
    return getString("PASSWORD", "Password");
  }

  get HealthDashboard() {
    return getString("HEALTH_DASHBOARD", "Health Dashboard");
  }
  get InviteCompany() {
    return getString("INVITE_COMPANY", "Invite Company");
  }
  get InvitationHistory() {
    return getString("INVITATION_HISTORY", "Invitation History");
  }
  get DefaultInvitationSubject() {
    return getString("DEFAULT_INVITATION_SUBJECT", "Early Access Invitation from Lockstep ");
  }
  get DefaultInvitationMessage() {
    return getString(
      "DEFAULT_INVITATION_MESSAGE",
      `Are your AP and AR teams at <b>[company name]</b> ready to spend less time in email?  If so, accept this private invitation to use Lockstep.  Lockstep integrates your central email inbox to your accounting system to improve workflow, automate responses, and access information effortlessly.  Lockstep will save time every day for your team.  Get started now.`
    );
  }
  InvitationSentConfirmation(companyName, emailDomain) {
    return formatString(
      getString("INVITATION_SENT_CONFIRMATION", "E-mail invitation sent to {companyName} from help@{emailDomain}"),
      { companyName, emailDomain }
    );
  }

  get AccountsPayable() {
    return getString("ACCOUNTS_PAYABLE", "Accounts Payable");
  }
  get AccountsReceivable() {
    return getString("ACCOUNTS_RECEIVABLE", "Accounts Receivable");
  }
  get DefaultPerspective() {
    return getString("DEFAULT_PERSPECTIVE", "Perspective:");
  }

  get ManageUsers() {
    return getString("MANAGE_USERS", "Manage Users");
  }
  get Username() {
    return getString("USERNAME", "Username");
  }
  get LoginAs() {
    return getString("LOGIN_AS", "Login As");
  }

  get Statistics() {
    return getString("STATISTICS", "Statistics");
  }
  get FeatureGroups() {
    return getString("FEATUREGROUPS", "Feature Groups");
  }
  get Features() {
    return getString("FEATURES", "Features");
  }
  get SubscriptionTypes() {
    return getString("SUBSCRIPTIONTYPES", "Subscription Types");
  }

  get BulkSenders() {
    return getString("BULK_SENDERS", "Bulk Senders");
  }
  get ServiceEmailTemplates() {
    return getString("SERVICE_EMAIL_TEMPLATES", "Service Email Templates");
  }
  get ConnectorManagement() {
    return getString("CONNECTOR_MANAGEMENT", "Connector Management");
  }

  get PaymentMethods() {
    return getString("PAYMENT_METHODS", "Payment Methods");
  }

  get Internationalization() {
    return getString("INTERNATIONALIZATION", "Internationalization");
  }

  get UnprocessedEntities() {
    return getString("UNPROCESSED_ENTITIES", "Unprocessed Entities");
  }

  get AccountKey() {
    return getString("ACCOUNT_KEY", "Account Key");
  }

  get EntityName() {
    return getString("ENTITY_NAME", "Entity name");
  }

  get TableName() {
    return getString("TABLE_NAME", "Table name");
  }

  get LastUpdated() {
    return getString("LAST_UPDATED", "Last updated");
  }

  get DateInserted() {
    return getString("DATE_INSERTED", "Date inserted");
  }

  get EntityKeyInt() {
    return getString("ENTITY_KEY_INT", "Entity key int");
  }

  get SignupControlPanel() {
    return getString("NEW_USER_CONTROL_PANEL", "Signup Control Panel");
  }

  get SignupControl() {
    return getString("CONTROL_PANEL", "Signup Control");
  }

  get Enabled() {
    return getString("ENABLED", "Enabled");
  }

  get Disabled() {
    return getString("DISABLED", "Disabled");
  }

  get Claim() {
    return getString("CLAIM", "Claim");
  }

  get Create() {
    return getString("CREATE", "Create");
  }

  get Migration() {
    return getString("MIGRATION", "Migration");
  }

  get ScriptManagement() {
    return getString("SCRIPT_MANAGEMENT", "Script Management");
  }

  get New() {
    return getString("NEW", "New");
  }

  get Invite() {
    return getString("INVITE", "Invite");
  }

  get SecondaryUrl() {
    return getString("SECONDARY_URL", "Secondary Url");
  }

  get OnboardingUrl() {
    return getString("ONBOARDING_URL", "On-boarding Url");
  }

  get PaymentMethod() {
    return getString("PAYMENT_METHOD", "Payment Method");
  }

  getString(name) {
    return getString(name.toUpperCase(), `[${name}]`);
  }

  get IsSurchargeApplicable() {
    return getString("IS_SURCHARGE_APPLICABLE", "Is Surcharge Applicable");
  }
  get ATC() {
    return getString("ATC", "ATC");
  }
  get NoAccountFound() {
    return getString("NO_ACCOUNT_FOUND", "No Account Found");
  }
  get LoadingAccountsPleaseWait() {
    return getString("LOADING_ACCOUNTS_PLEASE_WAIT", "Loading accounts please wait");
  }
  get PaymentMethodNameIsRequired (){
    return getString("PAYMENT_METHOD_NAME_IS_REQUIRED","Payment method name is required")
  }
  get PaymentMethodName (){
    return getString("PAYMENT_METHOD_NAME","Payment Method Name")
  }
  MinAndMaxCharAllowed (min,max){
    return getString("PAYMENT_METHOD_NAME",`Minimum ${min} and Maximum ${max} characters are allowed`)
  }
  get Description (){
    return getString("DESCRIPTION","Description")
  }
  get CustClassID (){
    return getString("CUST_CLASS_ID","Cust Class ID")
  }
   get CurrencyID (){
    return getString("CURRENCY_ID","Currency ID")
  }
  get MaximumPaymentAmount (){
    return getString("MAXIMUM_PAYMENT_AMOUNT","Maximum Payment Amount")
  }
  get Provider (){
    return getString("PROVIDER","Provider")
  }
  get InterpaymentUri (){
    return getString("INTERPAYMENT_URI","InterPayment Uri")
  }
  get InterpaymentAccessToken (){
    return getString("INTERPAYMENT_ACCESS_TOKEN","InterPayment AccessToken")
  }
   get InterfaceUrl1 (){
    return getString("INTERFACE_URL_1","Interface URL 1")
  }
  get InterfaceUrl2 (){
    return getString("INTERFACE_URL_2","Interface URL 2")
  }
  get Version (){
    return getString("VERSION","Version")
  }
  get InterfaceUrlACHOrCredit (){
    return getString("INTERFACE_URL_ACH_OR_CREDIT","Interface URL ACH/Credit")
  }
   get SandboxProdUrl (){
    return getString("SANDBOX_OR_PROD_URL","Sandbox/Prod Url" )
  }
   get BaseUrl (){
    return getString("BASE_URL","Base Url")
  }
   get ProductTransIdACH (){
    return getString("PRODUCT_TRANS_ID_ACH","Product Trans ID (ACH)")
  }
   get AcceptorID (){
    return getString("ACCEPTOR_ID","Acceptor ID")
  }
  get LocationID (){
    return getString("LOCATION_ID","Location ID")
  }
   get MerchantAppID (){
    return getString("MERCHANT_APP_ID","Merchant App ID")
  }
  get AccountSelection (){
    return getString("ACCOUNT_SELECTION","Account Selection")
  }
   get CompanyID (){
    return getString("COMPANY_ID","Company ID")
  }
   get ApiKey (){
    return getString("API_KEY","API Key")
  }
  get AccountID (){
    return getString("Account_ID","Account ID")
  }
   get TransactionKey (){
    return getString("TRANSACTION_KEY","Transaction Key")
  }
   get MerchantLoginId (){
    return getString("MERCHANT_LOGIN_ID","Merchant Login ID")
  }
   get UserID (){
    return getString("USER_ID","User ID")
  }
   get ApiId (){
    return getString("API_ID","API ID")
  }
   get AccountToken (){
    return getString("ACCOUNT_TOKEN","Account Token")
  }
  get TerminalID (){
    return getString("TERMINAL_ID","Terminal ID")
  }
  get SummaryCommodityCode (){
    return getString("SUMMARY_COMMODITY_CODE","Summary Commodity Code")
  }
   get ItemCommodityCode (){
    return getString("ITEM_COMMODITY_CODE","Item Commodity Code")
  }
   get IFieldsKey (){
    return getString("IFIELDS_KEY","iFields Key" )
  }
  get UserApiKey (){
    return getString("USER_API_KEY","User API Key")
  }
  get MerchantLoginKey (){
    return getString("MERCHANT_LOGIN_KEY","Merchant Login Key")
  }
   get EncryptionKey (){
    return getString("ENCRYPTION_KEY","Encryption Key")
  }
   get SaveChanges (){
    return getString("SAVE_CHANGES","Save Changes")
  }
  get SearchYourAccountName (){
    return getString("SEARCH_YOUR_ACCOUNT_NAME","Search your account name")
  }
  get PaymentConfiguration (){
    return getString("PAYMENT_CONFIGURATION","Payment Configuration")
  }
  get CustomerClass (){
    return getString("CUSTOMER_CLASS","Customer Class")
  } 
  get Currency (){
    return getString("CURRENCY","Currency")
  } 
  get MaxAmt (){
    return getString("MAX_AMT","Max Amt")
  }
  get Active (){
    return getString("ACTIVE","Active")
  }
  get Actions (){
    return getString("ACTIONS","Actions")
  }
  get ShowPaymentMethods (){
    return getString("SHOW_PAYMENT_METHODS","Show Payment Methods")
  }
  get SimpleMethodNameNoSpaces (){
    return getString("SIMPLE_METHOD_NAME_NO_SPACES","Simple Method Name No Spaces")
  }
  get HostedPageIdCC (){
    return getString("HOSTED_PAGE_ID_CC","Hosted Page ID (CC)")
  }
  get Success (){
    return getString("SUCCESS","Success")
  }
  get Failed (){
    return getString("FAILED","Failed")
  }
  get PaymentMerchantAdded (){
    return getString("PAYMENT_MERCHANT_ADDED","Payment Merchant Added.")
  }
  get AddingMerchantFailed (){
    return getString("ADDING_MERCHANT_FAILED","Adding Merchant Failed.")
  }
  get InvitationMailSent (){
    return getString("INVITATION_MAIL_SENT","Invitation Mail Sent.")
  }
  get InvitationMailSendingFailed (){
    return getString("INVITATION_MAIL_SENDING_FAILED","Invitation Mail Sending Failed.")
  }
// Payment Providers
get SoluPay() {
  return getString("SOLU_PAY", "SoluPay");
}
get SagePayment() {
  return getString("SAGE_PAYMENT", "SagePayment");
}
get Repay() {
    return getString("REPAY", "REPAY");
}
  get AffiniPay() {
    return getString("AFFINI_PAY", "Affinipay");
  }
  get WorldPay() {
    return getString("WORLD_PAY", "Worldpay");
  }
  get CardConnect() {
    return getString("CARD_CONNECT", "CardConnect");
  }
  get CardKnox() {
    return getString("CARD_KNOX", "cardknox");
  }
  get FortisPay() {
    return getString("FORTIS_PAY", "Fortispay");
  }
  get AuthorizeDotNet() {
    return getString("AUTHORIZE_DOT_NET", "Authorize.net");
  }
  get AuthorizeNet() {
    return getString("AUTHORIZE_NET", "AuthorizeNet");
  }
  get MerchAcctIdIsRequired(){
    return getString("MERCH_ACCT_ID_IS_REQUIRED","Merch Acct ID is required.");
  }
  get MerchAcctDescIsRequired(){
    return getString("MERCH_ACCT_DESC_IS_REQUIRED","Merch Acct Desc is required.");
  }
  get MaxPaymentAmtIsRequired(){
    return getString("MAX_PAYMENT_AMT_IS_REQUIRED","MaxPaymentAmt is Required.");
  }
  get InterfaceUrl1IsRequired(){
    return getString("INTERFACE_URL_1_IS_REQUIRED","Interface URL 1 is Required.");
  }
  get InterfaceUrl2IsRequired(){
    return getString("INTERFACE_URL_2_IS_REQUIRED","Interface URL 2 is Required.");
  }
  get InterfaceUrlAchOrCreditIsRequired(){
    return getString("INTERFACE_URL_ACH_CREDIT_IS_REQUIRED","Interface URL ACH/Credit is Required.");
  }
  get SandboxProdUrlIsRequired(){
    return getString("SANDBOX_PROD_URL_IS_REQUIRED","Sandbox/Prod Url is Required.");
  }
  get BaseUrlIsRequired(){
    return getString("Base_URL_IS_REQUIRED","Base Url is Required.");
  }
  get SecondaryUrlIsRequired(){
    return getString("SECONDARY_URL_IS_REQUIRED","Secondary Url is Required.");
  }
  get OnBoardingUrlIsRequired(){
    return getString("ON_BOARDING_URL_IS_REQUIRED","On-Boarding Url is Required.");
  }
  get VersionIsRequired(){
    return getString("VERSION_IS_REQUIRED","Version is Required.");
  }
  get AcceptorIDIsRequired(){
    return getString("ACCEPTER_ID_IS_REQUIRED","Acceptor ID is Required.");
  }
  get LocationIdIsRequired(){
    return getString("LOCATION_ID_IS_REQUIRED","Location ID is Required.");
  }
  get MerchantAppIdIsRequired(){
    return getString("MERCHANT_APP_ID_IS_REQUIRED","Merchant APP ID is Required.");
  }
  get ApiKeyIsRequired(){
    return getString("API_KEY_IS_REQUIRED","API Key is Required.");
  }
  get AccountIdIsRequired(){
    return getString("ACCOUNT_ID_IS_REQUIRED","Account ID is Required.");
  }
  get TransactionKeyIsRequired(){
    return getString("TRANSACTION_KEY_IS_REQUIRED","Transaction key is Required.");
  }
  get ApiIdIsRequired(){
    return getString("API_ID_IS_REQUIRED","API ID is Required.");
  }
  get UserIdIsRequired(){
    return getString("USER_ID_IS_REQUIRED","User ID is Required.");
  }
  get MerchLoginIdIsRequired(){
    return getString("MERCH_LOGIN_ID_IS_REQUIRED","Merch Login ID is Required.");
  } 
  get IFieldsKeyIsRequired (){
    return getString("IFIELDS_KEY_IS_REQUIRED","iFields Key is Required." )
  }
  get UserApiKeyIsRequired (){
    return getString("USER_API_KEY_IS_REQUIRED","User API Key is Required.")
  }
  get MerchantLoginKeyIsRequired (){
    return getString("MERCHANT_LOGIN_KEY_IS_REQUIRED","Merchant Login Key is Required.")
  }
  get accountTokenIsRequired (){
    return getString("ACCOUNT_TOKEN_IS_REQUIRED","Account Token is Required.")
  }
  get terminalIdIsRequired (){
    return getString("TERMINAL_ID_IS_REQUIRED","Terminal ID is Required.")
  }
}

const resources = new Resources();

export default resources;
