import data from "../lib/data";
import { actions as GeneralActions } from './general-actions'
import { handlePromiseError } from './error-actions'

export const actions = {
  ...GeneralActions, ...{
    FETCHING_SERVICE_EMAIL_TEMPLATES: "FETCHING_SERVICE_EMAIL_TEMPLATES",
    FETCHED_SERVICE_EMAIL_TEMPLATES: "FETCHED_SERVICE_EMAIL_TEMPLATES",
    FETCH_SERVICE_EMAIL_TEMPLATES_FAILED: "FETCH_SERVICE_EMAIL_TEMPLATES_FAILED"
  }
};

const fetchServiceEmailTemplates = () => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_SERVICE_EMAIL_TEMPLATES });
  data.get(`v2/api/serviceemails`).then((response) => {
    var serviceEmailTemplates = response.data;
    serviceEmailTemplates.sort((a, b) => {
      var x = a.serviceEmailTemplateName.toLowerCase();
      var y = b.serviceEmailTemplateName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    dispatch({ type: actions.FETCHED_SERVICE_EMAIL_TEMPLATES, serviceEmailTemplates });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_SERVICE_EMAIL_TEMPLATES_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const uploadServiceEmailTemplateAttachment = (serviceEmailTemplateId, acceptedFiles) => ((dispatch, getState) => {
  const state = getState();
  const formData = new FormData();
  acceptedFiles.forEach(file => {
    formData.append("files", file);
  });

  dispatch({ type: actions.FETCHING_SERVICE_EMAIL_TEMPLATES });
  data.put(`v2/api/serviceemails/${serviceEmailTemplateId}/attachments`, formData).then((response) => {
    var serviceEmailTemplates = response.data;
    serviceEmailTemplates.sort((a, b) => {
      var x = a.serviceEmailTemplateName.toLowerCase();
      var y = b.serviceEmailTemplateName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    dispatch({ type: actions.FETCHED_SERVICE_EMAIL_TEMPLATES, serviceEmailTemplates });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_SERVICE_EMAIL_TEMPLATES_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const deleteServiceEmailTemplateAttachment = (serviceEmailTemplateId, serviceEmailTemplateAttachmentId) => ((dispatch, getState) => {
  const state = getState();

  dispatch({ type: actions.FETCHING_SERVICE_EMAIL_TEMPLATES });
  data.delete(`v2/api/serviceemails/${serviceEmailTemplateId}/attachments/${serviceEmailTemplateAttachmentId}`).then((response) => {
    var serviceEmailTemplates = response.data;
    serviceEmailTemplates.sort((a, b) => {
      var x = a.serviceEmailTemplateName.toLowerCase();
      var y = b.serviceEmailTemplateName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    dispatch({ type: actions.FETCHED_SERVICE_EMAIL_TEMPLATES, serviceEmailTemplates });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_SERVICE_EMAIL_TEMPLATES_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const saveServiceEmailTemplate = (template) => ((dispatch, getState) => {
  const state = getState();

  dispatch({ type: actions.FETCHING_SERVICE_EMAIL_TEMPLATES });
  data.post(`v2/api/serviceemails`, template).then((response) => {
    var serviceEmailTemplates = response.data;
    serviceEmailTemplates.sort((a, b) => {
      var x = a.serviceEmailTemplateName.toLowerCase();
      var y = b.serviceEmailTemplateName.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });

    dispatch({ type: actions.FETCHED_SERVICE_EMAIL_TEMPLATES, serviceEmailTemplates });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_SERVICE_EMAIL_TEMPLATES_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

export const dispatchToProps = (dispatch) => ({
  fetchServiceEmailTemplates: () => { dispatch(fetchServiceEmailTemplates()); },
  uploadServiceEmailTemplateAttachment: (serviceEmailTemplateId, acceptedFiles) => { dispatch(uploadServiceEmailTemplateAttachment(serviceEmailTemplateId, acceptedFiles)); },
  deleteServiceEmailTemplateAttachment: (serviceEmailTemplateId, serviceEmailTemplateAttachmentId) => { dispatch(deleteServiceEmailTemplateAttachment(serviceEmailTemplateId, serviceEmailTemplateAttachmentId)); },
  saveServiceEmailTemplate: (template) => { dispatch(saveServiceEmailTemplate(template)); }
});
