import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";

import Resources from "../lib/resources";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as bsDP } from "../store/bulkSenders-actions";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...bsDP(dispatch)
});

class BulkSenders extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleCloseAddBulkSender = this.handleCloseAddBulkSender.bind(this);
    this.handleCloseDeleteBulkSender = this.handleCloseDeleteBulkSender.bind(this);
    this.addBulkSender = this.addBulkSender.bind(this);
    this.deleteBulkSender = this.deleteBulkSender.bind(this);
    this.renderAddBulkSenderModal = this.renderAddBulkSenderModal.bind(this);
    this.renderDeleteBulkSenderModal = this.renderDeleteBulkSenderModal.bind(this);

    this.state = {
      filterDomain: "",
      filterEmail: "",
      deletingSender: "",
      bulkSenderValue: ""
    };
  }

  componentDidMount() {
    this.ensureBulkSenders(true);
  }

  componentDidUpdate() {
    this.ensureBulkSenders(false);
  }

  ensureBulkSenders(force) {
    if (
      force === true ||
      (this.props.bulkSenders.fetchingBulkSenders === false &&
        this.props.bulkSenders.fetchedBulkSenders !== true &&
        this.props.bulkSenders.fetchBulkSendersFailed !== true)
    ) {
      this.props.fetchBulkSenders();
    }
  }

  handleCloseAddBulkSender() {
    this.setState({ addingDomain: false, addingEmail: false });
  }

  handleCloseDeleteBulkSender() {
    this.setState({ deletingSender: "" });
  }

  addBulkSender() {
    this.props.addBulkSender(this.state.bulkSenderValue);
    this.setState({ addingDomain: false, addingEmail: false });
  }

  deleteBulkSender() {
    this.props.deleteBulkSender(this.state.deletingSender);
    this.setState({ deletingSender: "" });
  }

  renderAddBulkSenderModal() {
    return (
      <Modal show={this.state.addingDomain || this.state.addingEmail} onHide={this.handleCloseAddBulkSender}>
        <Modal.Header closeButton>
          <Modal.Title>Add Bulk Sender</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Bulk Sender</Form.Label>
            <Form.Control
              value={this.state.bulkSenderValue}
              onChange={e => {
                this.setState({ bulkSenderValue: e.target.value });
              }}
              placeholder={this.state.addingDomain ? "Domain Name" : "Email Address"}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseAddBulkSender}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.addBulkSender}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderDeleteBulkSenderModal() {
    return (
      <Modal show={this.state.deletingSender !== ""} onHide={this.handleCloseDeleteBulkSender}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Bulk Sender</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete the bulk sender {this.state.deletingSender}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseDeleteBulkSender}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.deleteBulkSender}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <div className="panel-title">
          <h3>{Resources.BulkSenders}</h3>
        </div>
        {this.renderAddBulkSenderModal()}
        {this.renderDeleteBulkSenderModal()}
        <Row noGutters>
          <Col xs={12} sm={6}>
            <div className="list-panel">
              <div className="list-panel-title">
                Domains
                <a className="list-add" onClick={() => this.setState({ addingDomain: true })}>
                  + Add Domain
                </a>
              </div>
              <div className="list-panel-search">
                <Form.Control
                  className="list-search"
                  value={this.state.filterDomain}
                  onChange={e => {
                    this.setState({ filterDomain: e.target.value });
                  }}
                  placeholder="🔍  Search"
                />
              </div>
              {this.props.bulkSenders.domains
                .filter(
                  d =>
                    this.state.filterDomain === "" ||
                    d.bulkSenderDomainName.toLowerCase().includes(this.state.filterDomain.toLowerCase())
                )
                .map(d => {
                  return (
                    <div className="list-panel-item" key={d.bulkSenderDomainId}>
                      <div>
                        {d.bulkSenderDomainName}
                        <a
                          className="list-delete"
                          onClick={() => this.setState({ deletingSender: d.bulkSenderDomainName })}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className="customers-panel">
              <div className="customers-panel-title">
                Email Addresses
                <a className="list-add" onClick={() => this.setState({ addingEmail: true })}>
                  + Add Email
                </a>
              </div>
              <div className="list-panel-search">
                <Form.Control
                  className="list-search"
                  value={this.state.filterEmail}
                  onChange={e => {
                    this.setState({ filterEmail: e.target.value });
                  }}
                  placeholder="🔍  Search"
                />
              </div>
              {this.props.bulkSenders.emails
                .filter(
                  d =>
                    this.state.filterEmail === "" ||
                    d.bulkSenderEmailAddress.toLowerCase().includes(this.state.filterEmail.toLowerCase())
                )
                .map(d => {
                  return (
                    <div className="customers-panel-collapsed" key={d.bulkSenderEmailId}>
                      <div>
                        {d.bulkSenderEmailAddress}
                        <a
                          className="list-delete"
                          onClick={() => this.setState({ deletingSender: d.bulkSenderEmailAddress })}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    bulkSenders: store.bulkSenders
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(BulkSenders);
