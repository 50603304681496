import data from "../lib/data";
import { actions as GeneralActions } from './general-actions'
import { handlePromiseError } from './error-actions'

export const actions = {
  ...GeneralActions, ...{
    FETCHING_INTERNATIONALIZATIONS: "FETCHING_INTERNATIONALIZATIONS",
    FETCHED_INTERNATIONALIZATIONS: "FETCHED_INTERNATIONALIZATIONS",
    FETCH_INTERNATIONALIZATIONS_FAILED: "FETCH_INTERNATIONALIZATIONS_FAILED"
  }
};

const fetchInternationalizations = () => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_INTERNATIONALIZATIONS });
  data.get(`v1/api/internationalization`).then((response) => {
    var internationalizations = response.data;
    dispatch({ type: actions.FETCHED_INTERNATIONALIZATIONS, internationalizations: internationalizations });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_INTERNATIONALIZATIONS_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const uploadInternationalizationResourceFile = (acceptedFiles) => ((dispatch, getState) => {
  const state = getState();
  const formData = new FormData();
  acceptedFiles.forEach(file => {
    formData.append("files", file);
  });

  dispatch({ type: actions.FETCHING_INTERNATIONALIZATIONS });
  data.put(`v1/api/internationalization`, formData).then((response) => {
    var internationalizations = response.data;
    dispatch({ type: actions.FETCHED_INTERNATIONALIZATIONS, internationalizations: internationalizations });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_INTERNATIONALIZATIONS_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const deleteInternationalizationName = (lang, name) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_INTERNATIONALIZATIONS });
  data.delete(`v1/api/internationalization/${lang}/${name}`).then((response) => {
    var internationalization = response.data;
    var internationalizations = state.internationalizations;
    internationalizations[lang] = internationalization;
    dispatch({ type: actions.FETCHED_INTERNATIONALIZATIONS, internationalizations: internationalizations });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_INTERNATIONALIZATIONS_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

const createOrUpdateInternationalizationName = (lang, name, value) => ((dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_INTERNATIONALIZATIONS });
  data.post(`v1/api/internationalization/${lang}`, { name: name, value: value }).then((response) => {
    var internationalization = response.data;
    var internationalizations = state.internationalizations;
    internationalizations[lang] = internationalization;
    dispatch({ type: actions.FETCHED_INTERNATIONALIZATIONS, internationalizations: internationalizations });
  }).catch((rejection) => {
    dispatch({ type: actions.FETCH_INTERNATIONALIZATIONS_FAILED, ...state, rejection });
    handlePromiseError(rejection);
  });
});

export const dispatchToProps = (dispatch) => ({
    fetchInternationalizations: () => { dispatch(fetchInternationalizations()); },
    uploadInternationalizationResourceFile: (acceptedFiles) => { dispatch(uploadInternationalizationResourceFile(acceptedFiles)); },
    deleteInternationalizationName: (lang, name) => { dispatch(deleteInternationalizationName(lang, name)); },
    createOrUpdateInternationalizationName: (lang, name, value) => { dispatch(createOrUpdateInternationalizationName(lang, name, value)); }
});
