import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import Resources from "../lib/resources";
import DashboardValue from "./dashboardValue";
import CustomerHealthHeading from "./customerHealthHeading";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as hDP } from "../store/health-actions";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...hDP(dispatch)
});

class HealthDashboard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  componentDidMount() {
    this.ensureHealth(true);
  }

  componentDidUpdate() {
    this.ensureHealth(false);
  }

  ensureHealth(force) {
    if (
      force === true ||
      (this.props.health.fetchingHealth === false &&
        this.props.health.fetchedHealth !== true &&
        this.props.health.fetchHealthFailed !== true)
    ) {
      this.props.fetchHealth();
    }
  }

  renderConnectorIcon(context, title, className) {
    let show = context !== undefined;
    let valid = show ? (context || []).length === 0 : false;
    
    if (show && !valid) {
      title += " - " + context.join(", ");
    }

    if (show)
    {
      return <span
              title={title}
              className={
                className +
                (valid ? " valid" : " invalid")
              }
              />;
    } else {
      return "";
    }
  }

  sumIfValid(context, resource) {
    return context ? context[resource] || 0 : 0;
  }

  render() {
    return (
      <div>
        <div className="panel-title">
          <h3>{Resources.HealthDashboard}</h3>
        </div>
        <div className="customers-panel">
          <div className="customers-panel-title">Customers</div>
          {(this.props.health || { health: [] }).health.map(h => {
            if (this.state.showCompany && this.state.showCompany === h.companyId) {
              return (
                <div className="customers-panel-expanded" key={h.companyId}>
                  <CustomerHealthHeading
                    customer={h}
                    toggleText="Show Less"
                    toggle={() => this.setState({ showCompany: null })}
                  />
                  <div className="customers-panel-expanded-rows">
                    {Object.keys(h.inboundLastWeek).map(i => {
                      let emailLastWeek = this.sumIfValid(h.lastWeek[i], "Email");
                      let emailLastTwoWeeks = this.sumIfValid(h.lastTwoWeeks[i], "Email");
                      let emailWeekPrior = emailLastTwoWeeks - emailLastWeek;
                      let approvalsLastWeek = ["Approval", "Rejection", "ApprovalRequest"].reduce((a,b) => a + this.sumIfValid(h.lastWeek[i], b), 0);
                      let approvalsLastTwoWeeks = ["Approval", "Rejection", "ApprovalRequest"].reduce((a,b) => a + this.sumIfValid(h.lastTwoWeeks[i], b), 0);
                      let approvalsWeekPrior = approvalsLastTwoWeeks - approvalsLastWeek;

                      return (
                        <Row className="customers-panel-expanded-row" key={i} noGutters>
                          <Col xs={12} sm={4}>
                            {i.substring(0, 1).toUpperCase() + i.substring(1)}
                          </Col>
                          <Col xs={0} sm={1}>
                            <div className="customers-panel-value">
                              {this.renderConnectorIcon(h.gmailPerspectives[i], "Gmail", "fab fa-google")}
                              {this.renderConnectorIcon(h.imapPerspectives[i], "IMAP", "fas fa-envelope")}
                              <span title="Forwarding" className="fas fa-arrow-right valid" />
                            </div>
                          </Col>
                          <Col xs={6} sm={1}>
                            <div className="customers-panel-value">
                              <DashboardValue
                                title="Replies"
                                iconClass="fas fa-arrow-right"
                                value={emailLastWeek}
                                comparisonValue={emailWeekPrior}
                              />
                            </div>
                          </Col>
                          <Col xs={6} sm={2}>
                            <div className="customers-panel-value">
                              <DashboardValue
                                title="Approvals and Requests"
                                iconClass="fas fa-thumbs-up"
                                value={approvalsLastWeek}
                                comparisonValue={approvalsWeekPrior}
                              />
                            </div>
                          </Col>
                          <Col xs={3} sm={1}>
                            <div className="customers-panel-value">
                              <DashboardValue
                                title="Inbound Email"
                                iconClass="fas fa-download"
                                value={h.inboundLastWeek[i]}
                                comparisonValue={h.inboundWeeklyAverage[i]}
                              />
                            </div>
                          </Col>
                          <Col xs={3} sm={1}>
                            <div className="customers-panel-value">
                              <DashboardValue
                                title="Outbound Email"
                                iconClass="fas fa-upload"
                                value={h.outboundLastWeek[i]}
                                comparisonValue={h.outboundWeeklyAverage[i]}
                              />
                            </div>
                          </Col>
                          <Col xs={3} sm={1}>
                            <div className="customers-panel-value">
                              <DashboardValue
                                title="Daily Active Users"
                                iconClass="fas fa-user"
                                value={h.dailyActiveUsers[i]}
                                comparisonValue={h.twoDayActiveUsers[i]}
                              />
                            </div>
                          </Col>
                          <Col xs={3} sm={1}>
                            <div className="customers-panel-value">
                              <DashboardValue
                                title="Monthly Active Users"
                                iconClass="fas fa-user-clock"
                                value={h.monthlyActiveUsers[i]}
                                comparisonValue={h.twoMonthActiveUsers[i]}
                              />
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              );
            } else {
              return (
                <div className="customers-panel-collapsed" key={h.companyId}>
                  <CustomerHealthHeading
                    customer={h}
                    toggleText="Show More"
                    toggle={() => this.setState({ showCompany: h.companyId })}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    health: store.health
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(HealthDashboard);
