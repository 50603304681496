import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { Route, withRouter, Switch, Redirect, Link } from "react-router-dom";
import { Row, Col, NavItem } from "react-bootstrap";

import HealthDashboard from "./healthDashboard";
import PaymentMethods from "./paymentMethods";
import BulkSenders from "./bulkSenders";
import InviteCompany from "./inviteCompany";
import InviteHistory from "./inviteHistory";
import ManageUsers from "./manageUsers";
import UsageStatistics from "./usageStatistics";
import FeatureGroups from "./featureGroups";
import ServiceEmailTemplates from "./serviceEmailTemplates";
import ConnectorManagement from "./connectorManagement";
import Internationalization from "./internationalization";
import UnprocessedEntities from "./unprocessedEntities";

import { isEmpty } from "../lib/utils";

import Checkbox from "./library/checkbox";

import { dispatchToProps } from "../store/invite-actions";

class SignupControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "self",
      claim: false,
      create: false,
    };
  }

  componentDidMount() {
    this.props.getSelfServiceStatus().then(() => {
      this.setState({
        claim: this.props.inviteStore.selfServiceStatus.claim,
        create: this.props.inviteStore.selfServiceStatus.create,
      });
    });
  }

  openSwitch(e) {
    let element = e.target;
    element.className = "switch-guard__active";
    setTimeout(() => {
      element.className = "switch-guard";
    }, 3000);
  }

  render() {
    return (
      <div className="control-panel-container">
        <div className="control-panel-card">
          {this.props.inviteStore.gettingSelfServiceStatus ? (
            <span className={Resources.IconSpinner} />
          ) : (
            <React.Fragment>
              <div className="switch-container">
                <div onClick={(e) => this.openSwitch(e)} className="switch-guard"></div>
                <button
                  onClick={() => {
                    this.props
                      .setSelfServiceStatus({ claim: !this.state.claim, create: this.state.create })
                      .then(() => {
                        this.props.getSelfServiceStatus().then(() => {
                          this.setState({
                            claim: this.props.inviteStore.selfServiceStatus.claim,
                            create: this.props.inviteStore.selfServiceStatus.create,
                          });
                        });
                      });
                  }}
                  className="switch"
                >
                  {Resources.Claim}
                </button>
                <p className={this.state.claim ? "enabled" : ""}>
                  {this.state.claim ? Resources.Enabled : Resources.Disabled}
                </p>
              </div>
              <div className="switch-container">
                <div onClick={(e) => this.openSwitch(e)} className="switch-guard"></div>
                <button
                  onClick={() => {
                    this.props
                      .setSelfServiceStatus({ claim: this.state.claim, create: !this.state.create })
                      .then(() => {
                        this.props.getSelfServiceStatus().then(() => {
                          this.setState({
                            claim: this.props.inviteStore.selfServiceStatus.claim,
                            create: this.props.inviteStore.selfServiceStatus.create,
                          });
                        });
                      });
                  }}
                  className="switch"
                >
                  {Resources.Create}
                </button>
                <p className={this.state.create ? "enabled" : ""}>
                  {this.state.create ? Resources.Enabled : Resources.Disabled}
                </p>
              </div>
              <div className="switch-container">
                <div onClick={(e) => this.openSwitch(e)} className="switch-guard"></div>
                <button
                  onClick={() => {
                    let body = {};
                    if (this.state.claim == this.state.create) {
                      console.log(!this.state.claim, !this.state.create);
                      body = {
                        claim: !this.state.claim,
                        create: !this.state.create,
                      };
                    } else if (!this.state.claim || !this.state.create) {
                      body = {
                        claim: true,
                        create: true,
                      };
                    } else {
                      body = {
                        claim: false,
                        create: false,
                      };
                    }
                    this.props.setSelfServiceStatus(body).then(() => {
                      this.props.getSelfServiceStatus().then(() => {
                        this.setState({
                          claim: this.props.inviteStore.selfServiceStatus.claim,
                          create: this.props.inviteStore.selfServiceStatus.create,
                        });
                      });
                    });
                  }}
                  className="switch"
                >
                  {Resources.All}
                </button>
                <p className={this.state.create || this.state.claim ? "enabled" : ""}>
                  {this.state.create || this.state.claim ? Resources.Enabled : Resources.Disabled}
                </p>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const storeToProps = (store) => {
  return {
    inviteStore: store.invite,
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(SignupControl));
