import { actions } from './connector-actions'

const defaultConnectorsState = {
  fetchingConnectors: false,
  fetchedConnectors: false,
  fetchConnectorsFailed: false,
  connectors: []
}

function connectorReducer(state = defaultConnectorsState, action) {
  switch (action.type) {
    case actions.FETCHING_CONNECTORS:
      return { ...state, ...action, fetchingConnectors: true, fetchedConnectors: false, fetchConnectorsFailed: false }
    case actions.FETCH_CONNECTORS_FAILED:
      return { ...state, ...action, fetchingConnectors: false, fetchedConnectors: true, fetchConnectorsFailed: true }
    case actions.FETCHED_CONNECTORS:
      return { ...state, ...action, fetchingConnectors: false, fetchedConnectors: true, fetchConnectorsFailed: false }
    default:
      return state
  }
}

export default connectorReducer
