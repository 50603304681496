import { combineReducers } from "redux";
import { reducer as toastrReducer } from 'react-redux-toastr';

import error from "./error-reducer";
import user from "./user-reducer";
import invite from "./invite-reducer";
import stats from "./stats-reducer";
import featureGroups from "./featureGroups-reducer";
import health from "./health-reducer";
import bulkSenders from "./bulkSenders-reducer";
import serviceEmailTemplates from "./serviceEmailTemplates-reducer";
import connectors from "./connector-reducer";
import resources from "./resource-reducer";
import paymentMethods from "./paymentMethods-reducer";
import accounts from "./accounts-reducer";
import internationalizations from "./internationalization-reducer";
import scripts from "./script-reducer";

const rootReducer = combineReducers({
  error,
  user,
  invite,
  stats,
  featureGroups,
  health,
  bulkSenders,
  serviceEmailTemplates,
  connectors,
  resources,
  paymentMethods,
  accounts,
  internationalizations,
  scripts,
  toastr: toastrReducer
});

export default rootReducer;
