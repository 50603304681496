import { actions } from "./script-actions";

const defaultScriptsState = {
  fetchingScripts: false,
  fetchedScripts: false,
  failedFetchScripts: false,
  scripts: [],

  savingScript: false,
  savedScript: false,
  failedSaveScript: false,

  deletingScript: false,
  deletedScript: false,
  failedDeleteScript: false,
};

function serviceEmailTemplatesReducer(state = defaultScriptsState, action) {
  switch (action.type) {
    case actions.FETCHING_SCRIPTS:
      return {
        ...state,
        ...action,
        fetchingScripts: true,
        fetchedScripts: false,
        failedFetchScripts: false,
      };
    case actions.FETCHED_SCRIPTS:
      return {
        ...state,
        ...action,
        fetchingScripts: false,
        fetchedScripts: true,
        failedFetchScripts: true,
        scripts: action.scripts,
      };
    case actions.FAILED_FETCH_SCRIPTS:
      return {
        ...state,
        ...action,
        fetchingScripts: false,
        fetchedScripts: true,
        failedFetchScripts: false,
      };
    case actions.SAVING_SCRIPT:
      return {
        ...state,
        ...action,
        savingScript: true,
        savedScript: false,
        failedSaveScript: false,
      };
    case actions.SAVED_SCRIPT:
      return {
        ...state,
        ...action,
        savingScript: false,
        savedScript: true,
        failedSaveScript: true,
        scripts: action.scripts,
      };
    case actions.FAILED_FETCH_SCRIPTS:
      return {
        ...state,
        ...action,
        savingScript: false,
        savedScript: true,
        failedSaveScript: false,
      };
    case actions.DELETING_SCRIPT:
      return {
        ...state,
        ...action,
        deletingScript: true,
        deletedScript: false,
        failedDeleteScript: false,
      };
    case actions.DELETED_SCRIPT:
      return {
        ...state,
        ...action,
        deletingScript: false,
        deletedScript: true,
        failedDeleteScript: true,
        scripts: action.scripts,
      };
    case actions.FAILED_DELETE_SCRIPT:
      return {
        ...state,
        ...action,
        deletingScript: false,
        deletedScript: true,
        failedDeleteScript: false,
      };
    default:
      return state;
  }
}

export default serviceEmailTemplatesReducer;
