import React, { Component } from "react";

class DashboardValue extends Component {
  render() {
    var value =
      typeof this.props.value === "object"
        ? Object.keys(this.props.value)
            .map(o => this.props.value[o])
            .reduce(this.props.reduction ? this.props.reduction : (a, o) => a + o)
        : this.props.value;
    var comparisonValue =
      typeof this.props.comparisonValue === "object"
        ? Object.keys(this.props.comparisonValue)
            .map(o => this.props.comparisonValue[o])
            .reduce(this.props.reduction ? this.props.reduction : (a, o) => a + o)
        : this.props.comparisonValue;

    return (
      <React.Fragment>
        <span title={this.props.title} className={this.props.iconClass} />
        <span>{value}</span>
        {(function() {
          if (value - comparisonValue > 0) {
            return (
              <span
                title={Math.floor(((value - comparisonValue) / comparisonValue) * 100) + "%"}
                className="valid fas fa-angle-up"
              />
            );
          }
          if (value - comparisonValue < 0) {
            return (
              <span
                title={Math.floor(((value - comparisonValue) / comparisonValue) * 100) + "%"}
                className="invalid fas fa-angle-down"
              />
            );
          }
          return <span className="invisible fas fa-angle-down" />;
        })()}
      </React.Fragment>
    );
  }
}

export default DashboardValue;
