import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

export const actions = {
  ...GeneralActions,
  ...{
    FETCHING_SCRIPTS: "FETCHING_SCRIPTS",
    FETCHED_SCRIPTS: "FETCHED_SCRIPTS",
    FAILED_FETCH_SCRIPTS: "FAILED_FETCH_SCRIPTS",

    SAVING_SCRIPT: "SAVING_SCRIPT",
    SAVED_SCRIPT: "SAVED_SCRIPT",
    FAILED_SAVE_SCRIPT: "FAILED_SAVE_SCRIPT",

    DELETING_SCRIPT: "DELETING_SCRIPT",
    DELETED_SCRIPT: "DELETED_SCRIPT",
    FAILED_DELETE_SCRIPT: "FAILED_DELETE_SCRIPT",
  },
};

const fetchScripts = () => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.FETCHING_SCRIPTS });
  data
    .get(`v3/api/whitelabel/portal/scripts`)
    .then((response) => {
      var scripts = response.data;
      dispatch({ type: actions.FETCHED_SCRIPTS, scripts });
    })
    .catch((rejection) => {
      dispatch({ type: actions.FAILED_FETCH_SCRIPTS, ...state, rejection });
      handlePromiseError(rejection);
    });
};

const saveScript = (script) => (dispatch, getState) => {
  const state = getState();

  dispatch({ type: actions.SAVING_SCRIPT });
  data
    .post(`v3/api/whitelabel/portal/scripts`, script)
    .then((response) => {
      var scripts = response.data;
      dispatch({ type: actions.SAVED_SCRIPT, scripts });
    })
    .catch((rejection) => {
      dispatch({ type: actions.FAILED_SAVE_SCRIPT, ...state, rejection });
      handlePromiseError(rejection);
    });
};

const deleteScript = (scriptKey) => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: actions.DELETING_SCRIPT });
  data
    .delete(`v3/api/whitelabel/portal/scripts/${scriptKey}`)
    .then((response) => {
      var scripts = response.data;
      dispatch({ type: actions.DELETED_SCRIPT, scripts });
    })
    .catch((rejection) => {
      dispatch({ type: actions.FAILED_DELETE_SCRIPT, ...state, rejection });
      handlePromiseError(rejection);
    });
};

export const dispatchToProps = (dispatch) => ({
  fetchScripts: () => {
    dispatch(fetchScripts());
  },
  saveScript: (script) => {
    dispatch(saveScript(script));
  },
  deleteScript: (scriptKey) => {
    dispatch(deleteScript(scriptKey));
  },
});
