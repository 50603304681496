import { actions } from './bulkSenders-actions'

const defaultBulkSendersState = {
  fetchingBulkSenders: false,
  fetchedBulkSenders: false,
  fetchBulkSendersFailed: false,
  domains: [],
  emails: []
}

function bulkSendersReducer(state = defaultBulkSendersState, action) {
  switch (action.type) {
    case actions.FETCHING_BULK_SENDERS:
      return { ...state, ...action, fetchingBulkSenders: true, fetchedBulkSenders: false, fetchBulkSendersFailed: false }
    case actions.FETCH_BULK_SENDERS_FAILED:
      return { ...state, ...action, fetchingBulkSenders: false, fetchedBulkSenders: true, fetchBulkSendersFailed: true }
    case actions.FETCHED_BULK_SENDERS:
      return { ...state, ...action, fetchingBulkSenders: false, fetchedBulkSenders: true, fetchBulkSendersFailed: false }
    default:
      return state
  }
}

export default bulkSendersReducer
