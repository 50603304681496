import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import HealthDashboard from "./healthDashboard";
import PaymentMethods from "./paymentMethods";
import BulkSenders from "./bulkSenders";
import InviteManagement from "./inviteManagement";
import InviteHistory from "./inviteHistory";
import ManageUsers from "./manageUsers";
import UsageStatistics from "./usageStatistics";
import FeatureGroups from "./featureGroups";
import ServiceEmailTemplates from "./serviceEmailTemplates";
import ConnectorManagement from "./connectorManagement";
import Internationalization from "./internationalization";
import UnprocessedEntities from "./unprocessedEntities";
import Migration from "./migration";
import ScriptManager from "./scriptManager";

import { isEmpty } from "../lib/utils";

import { dispatchToProps } from "../store/user-actions";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.ensureRole();
  }

  componentDidUpdate() {
    this.ensureRole();
  }

  ensureRole() {
    let {
      user: { gettingRoles, fetchedRoles, isAdmin, isLoggedIn },
    } = this.props;
    if (isLoggedIn) {
      if (gettingRoles === false && fetchedRoles !== true) {
        this.props.fetchRoles();
      } else if (fetchedRoles === true && isAdmin !== true) {
        this.props.logout();
      }
    }
  }

  getRoute(view) {
    var match = this.props.match || {};
    var params = match.params || {};
    if (view === params.view) return match.url;
    var url = match.url || window.location.href;
    var rootRoute = params.view ? url.substring(0, url.lastIndexOf("/")) : url;
    if (isEmpty(view) === false && rootRoute.endsWith("/")) rootRoute = rootRoute.substring(0, rootRoute.length - 1);
    return `${rootRoute}${view ? `/${view}` : ""}`;
  }

  render() {
    const { user } = this.props;

    if (user.isAdmin !== true)
      return (
        <div>
          Deciding on whether I can do that for you, Dave... <span className={Resources.IconSpinner} />
        </div>
      );

    return (
      <Row noGutters>
        <Col>
          <Route path="/health" render={() => <HealthDashboard />} />
          <Route path="/paymentMethods" render={() => <PaymentMethods />} />
          <Route path="/bulkSenders" render={() => <BulkSenders />} />
          <Route path="/invite" component={InviteManagement} />
          <Route path="/users" render={() => <ManageUsers />} />
          <Route path="/stats" render={() => <UsageStatistics />} />
          <Route path="/history" render={() => <InviteHistory />} />
          <Route path="/features" render={() => <FeatureGroups />} />
          <Route path="/templates" render={() => <ServiceEmailTemplates />} />
          <Route path="/connectors" render={() => <ConnectorManagement />} />
          <Route path="/internationalization" render={() => <Internationalization />} />
          <Route path="/unprocessedentities" render={() => <UnprocessedEntities />} />
          <Route path="/migration" render={() => <Migration />} />
          <Route path="/scripts" render={() => <ScriptManager />} />
        </Col>
      </Row>
    );
  }
}

const storeToProps = (store) => {
  return {
    user: store.user,
  };
};

export default connect(storeToProps, dispatchToProps)(Home);
