import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";

export const actions = {
  ...GeneralActions,
  ...{
    SEND_INVITE: "SEND_INVITE",
    SENT_INVITE: "SENT_INVITE",
    SEND_INVITE_FAILED: "SEND_INVITE_FAILED",

    RESEND_INVITE: "RESEND_INVITE",
    RESENT_INVITE: "RESENT_INVITE",
    RESEND_INVITE_FAILED: "RESEND_INVITE_FAILED",

    CLEAR_INVITE: "CLEAR_INVITE",

    GET_INVITES: "GET_INVITES",
    GOT_INVITES: "GOT_INVITES",
    GET_INVITES_FAILED: "GET_INVITES_FAILED",

    GETTING_SELF_SERVICE_STATUS: "GETTING_SELF_SERVICE_STATUS",
    GOT_SELF_SERVICE_STATUS: "GOT_SELF_SERVICE_STATUS",
    FAILED_GET_SELF_SERVICE_STATUS: "FAILED_GET_SELF_SERVICE_STATUS",

    UPDATING_SELF_SERVICE_STATUS: "UPDATING_SELF_SERVICE_STATUS",
    UPDATED_SELF_SERVICE_STATUS: "UPDATED_SELF_SERVICE_STATUS",
    FAILED_UPDATE_SELF_SERVICE_STATUS: "FAILED_UPDATE_SELF_SERVICE_STATUS",
  },
};

const sendInvite = (inviteInfo) => (dispatch) => {
  dispatch({ type: actions.SEND_INVITE });
  data
    .put("v1/api/invite/user", inviteInfo)
    .then((response) => {
      dispatch({ type: actions.SENT_INVITE, invite: response.data });
    })
    .catch((response) => {
      dispatch({ type: actions.SEND_INVITE_FAILED });
      handlePromiseError(response, "what now?");
    });
};

const fetchInvites = () => (dispatch) => {
  dispatch({ type: actions.GET_INVITES });
  data
    .get("v1/api/invite/user")
    .then((response) => {
      dispatch({ type: actions.GOT_INVITES, invites: response.data });
    })
    .catch((response) => {
      dispatch({ type: actions.GET_INVITES_FAILED });
      handlePromiseError(response, "what now?");
    });
};

const resendInvite = (inviteId) => (dispatch) => {
  dispatch({ type: actions.RESEND_INVITE, inviteId });
  data
    .post(`v1/api/invite/user/${inviteId}/resend`)
    .then((response) => {
      dispatch({ type: actions.RESENT_INVITE, invite: response.data, inviteId });
      dispatch(fetchInvites());
    })
    .catch((response) => {
      dispatch({ type: actions.RESEND_INVITE_FAILED, inviteId });
      handlePromiseError(response, "what now?");
    });
};

const getSelfServiceStatus = () => (dispatch) => {
  dispatch({ type: actions.GETTING_SELF_SERVICE_STATUS });
  return data
    .get(`v3/api/global/selfservice`)
    .then((response) => {
      dispatch({ type: actions.GOT_SELF_SERVICE_STATUS, selfServiceStatus: response.data });
      return response.data;
    })
    .catch((rejection) => {
      dispatch({ type: actions.FAILED_GET_SELF_SERVICE_STATUS, selfServiceStatus: {} });
      handlePromiseError(rejection);
    });
};

const setSelfServiceStatus = (body) => (dispatch) => {
  dispatch({ type: actions.UPDATING_SELF_SERVICE_STATUS });
  let endpoint = `v3/api/global/selfservice`;
  return data
    .post(endpoint, body)
    .then((response) => {
      dispatch({ type: actions.UPDATED_SELF_SERVICE_STATUS, selfServiceStatus: response.data });
    })
    .catch((error) => {
      handlePromiseError(error, "Failed to update the self service status", "customer contact data");
      dispatch({ type: actions.FAILED_UPDATE_SELF_SERVICE_STATUS });
      throw error;
    });
};

const getInvites = () => (dispatch, getState) => {
  var invite = getState().invite;
  return invite.invites;
};

export const dispatchToProps = (dispatch) => ({
  sendInvite: (inviteInfo) => {
    dispatch(sendInvite(inviteInfo));
  },
  resendInvite: (inviteId) => {
    dispatch(resendInvite(inviteId));
  },
  clearInvite: () => {
    dispatch({ type: actions.CLEAR_INVITE });
  },
  getSelfServiceStatus: () => {
    return dispatch(getSelfServiceStatus());
  },
  setSelfServiceStatus: (body) => {
    return dispatch(setSelfServiceStatus(body));
  },
  fetchInvites: () => {
    dispatch(fetchInvites());
  },
  getInvites: () => {
    return dispatch(getInvites());
  },
});
